import { useState, useEffect, useRef } from 'react'
import { NavLink } from 'react-router-dom'
import {  user_img } from '../../File'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
    faEye,
    faCheck,
    faTrash,
    faTimes,
    faEllipsis,
} from '@fortawesome/free-solid-svg-icons'



const Notification = ({ toggleNotification, isActive }) => {

    return (
        <div className={`notification-container ${isActive ? 'active' : ''}`}>
            <div onClick={() => toggleNotification(false)} className="notification-skin"></div>
            <div className="notification-frame">
                <div className="title-header">
                    <h3>Notifications</h3>
                    <FontAwesomeIcon onClick={() => toggleNotification(false)} className="icon" icon={faTimes} />
                </div>
                <div className="notification-inner-frame">
                    <div className="notification-control">
                        <span className="control active">All</span>
                        <span className="control">Unread</span>
                    </div>
                    <div className="notification-content">
                        <NotificationItems read={'unread'}/>
                        <NotificationItems read={''}/>
                        <NotificationItems read={''}/>
                        <NotificationItems read={'unread'}/>
                        <NotificationItems read={''}/>
                        <NotificationItems read={''}/>
                        <NotificationItems read={'unread'}/>
                        <NotificationItems read={''}/>
                        <NotificationItems read={''}/>
                        <NotificationItems read={''}/>
                        <NotificationItems read={'unread'}/>
                        <NotificationItems read={''}/>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default Notification





const NotificationItems = ({ read }) => {
    return (
            <div className="notification-item-parent">
                <div className={`notification-items ${read}`}>
                    {/* <div className="no-img" style={{ backgroundColor: 'green'}}><span>R</span></div> */}
                    <div className="img">
                        <img src={user_img('9.png')} alt="user-img"/>
                    </div>
                    <div className="not-content">
                        <div className="notification"> 
                            Demo user just registered on payizzy Demo user just registered on 
                            payizzy Demo user just registered on payizzy Demo user just registered on payizzy</div>
                        <div className="time">15h ago</div>
                    </div>
                </div>
                <NotificationOptions/>
            </div >
    )
}






const NotificationOptions = () => {
    let optionRef = useRef()
    const [state, setState] = useState (false)

    const toggleNotificationOptions = () => {
        setState(true)
    }

    const handler = (e) => {
        if(optionRef.current && !e.composedPath().includes(optionRef.current)){
            setState(false)
        }
    }

    useEffect(() => {
        document.addEventListener('click', handler)
        return () => {
            document.removeEventListener('click', handler) // clears document.addEventListener('click', handler) from browser
        }
    }, [])

    return (
        <div className="notification-options" ref={optionRef}>
            <FontAwesomeIcon  onClick={() => toggleNotificationOptions()} className="icon button" icon={faEllipsis} />
            <div className={`notification-options-items ${state ? 'active' : ''}`}>
                <ul>
                    <li>
                        <FontAwesomeIcon  className="icon" icon={faCheck}/>
                        <span>Mark as read</span>
                    </li>
                    <li>
                        <FontAwesomeIcon  className="icon" icon={faEye}/>
                        <span>view notification</span>
                    </li>
                    <li>
                        <FontAwesomeIcon  className="icon" icon={faTrash}/>
                        <span>Delete notification</span>
                    </li>
                </ul>
            </div>
        </div>
    )
}