
import { NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
    faCircleCheck
} from '@fortawesome/free-solid-svg-icons'
import {  user_img } from '../../File'
import SearchBar from './SearchBar'
import TotalTransaction from './TotalTransaction'
import TableBottom from './TableBottom'




const TransactionBody = ({toggleFloatProfile}) => {
    const fetchUserProfile = () => {
        console.log('yes')
        toggleFloatProfile(true)
    }
    
    return (
        <div className="general-table-body">
            <div className="inner-body">
                <TotalTransaction/>
                <SearchBar/>
                <TransactionTable fetchUserProfile={fetchUserProfile}/>
                <TableBottom/>
            </div>
        </div>
    )
}



export default TransactionBody










const TransactionTable = ({fetchUserProfile}) => {
    return (
        <div className="general-table table-responsive">
            <table className="table table-hover">
                <thead>
                    <tr>
                        <th scope="col">User <span>(222,000)</span></th>
                        <th scope="col">Description <span>(7)</span></th>
                        <th scope="col">Transaction</th>
                        <th scope="col">Amount</th>
                        <th scope="col">Time Stamp</th>
                        <th scope="col">Status</th>
                    </tr>
                </thead>
                <tbody>
                    <TabelContent fetchUserProfile={fetchUserProfile}/>
                    <TabelContent fetchUserProfile={fetchUserProfile}/>
                    <TabelContent fetchUserProfile={fetchUserProfile}/>
                    <TabelContent fetchUserProfile={fetchUserProfile}/>
                    <TabelContent fetchUserProfile={fetchUserProfile}/>
                    <TabelContent fetchUserProfile={fetchUserProfile}/>
                    <TabelContent fetchUserProfile={fetchUserProfile}/>
                    <TabelContent fetchUserProfile={fetchUserProfile}/>
                    <TabelContent fetchUserProfile={fetchUserProfile}/>
                    <TabelContent fetchUserProfile={fetchUserProfile}/>
                    <TabelContent fetchUserProfile={fetchUserProfile}/>
                </tbody>
            </table>
        </div>
    )
}

    


const TabelContent = ({fetchUserProfile}) => {
    return (
            <tr>
                <td>
                    <MobileViewImage fetchUserProfile={fetchUserProfile}/>
                    <DesktopViewImage fetchUserProfile={fetchUserProfile}/>
                </td>
                <td>Wallet Topup</td>
                <td>Credit</td>
                <td>NGN 200,000</td>
                <td>24th february 2025 | 5:30am</td>
                <td className="status">
                    <span><FontAwesomeIcon className="icon failed" icon={faCircleCheck} /></span> Success
                </td>
            </tr>
    )
}







const DesktopViewImage = ({fetchUserProfile}) => {
    return (
        <div className="user-detail desktop">
            <div onClick={() => fetchUserProfile()} className="left">
                {/* <div className="no-img" style={{ backgroundColor: 'green'}}><span>R</span></div> */}
                <div className="img">
                    <img src={user_img('9.png')} alt="user-img"/>
                </div>
            </div>
            <div onClick={() => fetchUserProfile()} className="right">
                <h3>John Doe</h3>
                <p>Acc No: 1100223344</p>
            </div>
        </div>
    )
}






const MobileViewImage = ({fetchUserProfile}) => {
    return (
        <div className="user-detail mobile">
            <div onClick={() => fetchUserProfile()} className="left">
                <NavLink to="/admin/profile">
                    {/* <div className="no-img" style={{ backgroundColor: 'green'}}><span>R</span></div> */}
                    <div className="img">
                        <img src={user_img('9.png')} alt="user-img"/>
                    </div>
                </NavLink>
            </div>
            <div className="right">
                <NavLink to="/admin/profile">
                    <h3>John Doe</h3>
                </NavLink>
                <p>Acc No: 1100223344</p>
            </div>
        </div>
    )
}


