import { useEffect } from 'react'
import TopBar from './TopBar'
import ReferalBody from './ReferalBody'
import MobileTable from './MobileTable'



const Referal = ({toggleFloatProfile}) => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className="general-table-container">
            <TopBar/>
            <ReferalBody toggleFloatProfile={toggleFloatProfile}/>
            <MobileTable toggleFloatProfile={toggleFloatProfile}/>
        </div>
    )
}

export default Referal