import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
    faEye,
    faTimes,
    faEyeSlash
} from '@fortawesome/free-solid-svg-icons'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';








const AddUser = ({toggleAddMember}) => {
    const [passwordVisibility, setPasswordVisibility] = useState(false)

    const toggleType = () => {
        setPasswordVisibility(!passwordVisibility)
    }

    


    return (
        <div className="add-admin-container">
            <div className="form">
                <TitleHeader toggleAddMember={toggleAddMember}/>
                <div className="form-body">
                    <div className="form-group">
                        <label>Name</label>
                        <input type="text" className="" placeholder="Enter Name"/>
                    </div>
                    <div className="form-group">
                        <label>Email</label>
                        <input type="email" className="" placeholder="Enter Email"/>
                    </div>
                    <div className="form-group">
                        <label>Password</label>
                        <div className="form-input-password">
                            <input type={passwordVisibility ? 'text' : 'password' } className="" placeholder="Enter Password"/>
                            <FontAwesomeIcon onClick={() => toggleType()} className="icon" icon={passwordVisibility ? faEye : faEyeSlash} />
                        </div>
                    </div>
                    <div className="form-group">
                        <label>Permissions</label>
                        <Row className="show-grid">
                            <Col xs={6} sm={6} md={4} lg={4} xl={4}><Permission/></Col>
                            <Col xs={6} sm={6} md={4} lg={4} xl={4}><Permission/></Col>
                            <Col xs={6} sm={6} md={4} lg={4} xl={4}><Permission/></Col>
                            <Col xs={6} sm={6} md={4} lg={4} xl={4}><Permission/></Col>
                            <Col xs={6} sm={6} md={4} lg={4} xl={4}><Permission/></Col>
                            <Col xs={6} sm={6} md={4} lg={4} xl={4}><Permission/></Col>
                        </Row>
                    </div>
                    <div className="form-button">
                        <button type="button">CREATE USER</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddUser







const TitleHeader = ({toggleAddMember}) => {
    return (
        <div className="title-header">
            <h3>Add New Member</h3>
            <div className="form-cancel">
                <FontAwesomeIcon onClick={() => toggleAddMember(false)} className="icon" icon={faTimes} />
            </div>
        </div>
    )
}




const Permission = () => {
    return (
        <div class="form-check">
            <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
            <label class="form-check-label" htmlFor="flexCheckDefault">
                Create Admin
            </label>
        </div>
    )
}