import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
    faTimes,
} from '@fortawesome/free-solid-svg-icons'
import Axios from 'axios'
import { url } from '../../File'
import FormInputAlert from '../alert/FormInputAlert'
import { useDispatch } from 'react-redux'
import { addPageCategories } from '../redux/pageCategorySlice'







const AddPageCategory = ({ toggleAddCategory, alertNotification}) => {

    const [alert, setAlert] = useState('')
    const [categoryName, setCategoryName] = useState('')

    // react hooks
    const dispatch = useDispatch()

    const addPageCategory = () => {
        const validate = validate_input(categoryName)
        if(validate === false) return 

        Axios.post(url('/api/admin/add-page-category'), {category: categoryName}).then((response) => {
            const data = response.data
            if(data.status === 'input-error'){
                return setAlert(data.validationError)
            }else if(data.status === 'ok'){
                dispatch(addPageCategories(data.newCategory))
                return toggleAddCategory(false)
               
            }else{
                return  alertNotification('error', data.error)
            }
        })
    }


    const validate_input = (input) => {
        let failed = false;
        if(input.length === 0){
            failed = true
            setAlert(`*Category field is required`)
        } else if(input.length < 3){
            failed = true
            setAlert(`*Must be minimum of 3 characters`)
        }else if(input.length > 30){
            failed = true
            setAlert(`*Must be minimum of 30 characters`)
        }
        if(failed === true){
            return false
        }else{
            return true
        }
    }

    return (
        <div className="add-admin-container">
            <div className="form">
                <TitleHeader toggleAddCategory={toggleAddCategory}/>
                <div className="form-body">
                    <div className="form-group">
                        <label>Category</label>
                        <input onChange={(e) => setCategoryName(e.target.value)} type="text" className="" placeholder="Enter Category"/>
                        <FormInputAlert alert={alert}/>
                    </div>
                    <div className="form-button">
                        <button onClick={() => addPageCategory()} type="button">CREATE CATEGORY</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddPageCategory







const TitleHeader = ({toggleAddCategory}) => {
    return (
        <div className="title-header">
            <h3>Add New Category</h3>
            <div className="form-cancel">
                <FontAwesomeIcon onClick={() => toggleAddCategory(false)} className="icon" icon={faTimes} />
            </div>
        </div>
    )
}


