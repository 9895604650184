import { Fragment } from 'react'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import {  user_img } from '../../File'
import { NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
    faPhone,
    faMobile,
    faCircleCheck
} from '@fortawesome/free-solid-svg-icons'
import ReactCountryFlag from "react-country-flag";




const ProfileTopLeft = ({toggleModal}) => {
    return (
        <div className="profile-top-left">
            <Top toggleModal={toggleModal}/>
        </div>
    )
}



export default ProfileTopLeft




const Top = ({toggleModal}) => {
    return (
        <Fragment>
            <TopSection toggleModal={toggleModal}/>
            <BottomSection/>
        </Fragment>
    )
}




const TopSection = ({toggleModal}) => {
    let image = 'dfg'
    let border = image.length ? '1px solid #d8d8d8' : ''
    let backgroundColor = image.length === 0 ? 'purple' : ''

    return  (
        <div className="top">
            <div style={{ backgroundColor: backgroundColor, border: border }} className="top-img">
                { image.length ? (<img src={user_img('6.jpg')} alt="top-img"/>) : (<span>K</span>) }
            </div>
            <div className="top-name">
                <ul>
                    <li className="name">Jane Ugodimma <ReactCountryFlag className="icon" countryCode="NG" svg /></li>
                    <li className="email">janeugodimma@gmail.com</li>
                    <li onClick={() => toggleModal(true, true)} className="deactivate">
                        Deactivate
                    </li>
                </ul>
            </div>
        </div>
    )
}







const BottomSection = () => {
    return (
        <div className="bottom-section">
            <Row className="show-grid">
                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                    <div className="phone">
                        <FontAwesomeIcon className="icon" icon={faMobile} /> 
                        <span>Ios 16.7 | Iphone 15 Promax</span>
                    </div>
                </Col>
                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                    <div className="account"><span>Acc Number:</span> 223311443366</div>
                </Col>
                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                    <div className="contact">
                        <FontAwesomeIcon className="icon" icon={faPhone} /> +2349034567865
                    </div>
                </Col>
                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                    <div className="verified">
                        <FontAwesomeIcon className="icon" icon={faCircleCheck} /> Verified
                    </div>
                </Col>
                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                    <div className="email-button">
                        <NavLink to="/admin/profile">Send Mail</NavLink>
                    </div>
                </Col>
            </Row>
        </div>
    )
}