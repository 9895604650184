import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { app_image } from '../../File'
import { NavLink } from 'react-router-dom'
import AOS from 'aos'
import { useEffect } from 'react'



const ContentLinks = () => {

    const contents = [
        {
            title: 'Send Money',
            image: 'send-money-icon.png',
            text: 'Send money effortlessly using secure QR odes'
        },
        {
            title: 'Receive Payments',
            image: 'receive-payments-icon.png',
            text: 'Receive money instantly with your own unique QR code'
        },
        {
            title: 'Request Payments',
            image: 'request-payments-icon.png',
            text: 'Request funds directly on your Payizzy app using QR codes'
        },
        {
            title: 'Pay in Chat',
            image: 'pay-in-chat-icon.png',
            text: 'Use and experience the power of  chat-powered transactions'
        },
        {
            title: '100% success',
            image: 'success-icon.png',
            text: 'Never ever complain of failed transactions with Payizzy'
        },
        {
            title: 'Use Izzie AI',
            image: 'izzy-ai-icon.png',
            text: 'Make AI powered transactions with your personal Izzie buddy'
        },
    ]
    
    useEffect(() => {
        AOS.init({ duration: 2000 })
    }, [])


    return (
        <div className="content-banner-container">
           <Row className="show-grid">
            {
                contents.map((content, index) => (<ContentItem key={index} number={index+1} content={content}/>))
            }
            </Row>
        </div>
    )
}



export default ContentLinks





const ContentItem = ({ number, content}) => {
    return (
        <Col data-aos={number % 2 === 0 ? 'zoom-out' : 'fade-left'} xs={12} sm={12} md={6} lg={6} xl={4}>
            <div   className="content-banner-item">
                <NavLink to="/">
                    <ul>
                    { 
                        content.image ? (
                            <li>
                                <img src={app_image(content.image)} alt={content.image}/>
                            </li>
                        ) : null
                    }
                        { content.title ? ( <li className="title">{content.title}</li>) : null }
                        { content.text ? ( <li className="text">{content.text}</li>) : null }
                    </ul>
                </NavLink>
            </div>
        </Col>
    )
}