import { preloaderImg } from '../../File'
import { Fragment } from 'react'



const ActionPreloader = ({actionloader}) => {
  return (
    <Fragment>
      {
        actionloader.state ? (
          <div className="content-preloader">
            <div className="loading">
                <img src={preloaderImg('1.gif')} alt="preloader"/>
                <h3>{ actionloader.text.length ? actionloader.text : '' }</h3>
            </div>
        </div>
        ) : null
      }
    </Fragment>
  )
}

export default ActionPreloader
