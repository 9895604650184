import { useState, useEffect, useRef } from 'react'
import Cookies from 'js-cookie'






const ThemeOption = ({theme, isThemeOption, setTheme, setIsThemeOption}) => {

    const setAuthomaticThemeEffect = useRef()
    const [themeOption, setThemeOption] = useState(theme)

     // toggle app theme to dark or light 
     const toggleTheme = (state) => {
        if(state === 'close'){
            return setIsThemeOption(false)
        }
        if(state === 'open'){
            return setIsThemeOption(true)
        }
        if(state === 'default'){
            let date = new Date();
            let hours = date.getHours();
            let newformat = hours >= 12 ? 'PM' : 'AM';
            if(newformat === 'PM' && hours > 18){
                setTheme('dark')
            }else{
                setTheme('light')
            }
            setThemeOption('default')
            Cookies.remove('pazzy-theme')
            return setIsThemeOption(false)
        }else{
            Cookies.set('pazzy-theme', state, { expires: 7 })
        }
        setIsThemeOption(false)
        return setTheme(state)
    }


    // set day and night theme automatically
    const setThemeAuto = () => {
        let date = new Date();
        let hours = date.getHours();
        let newformat = hours >= 12 ? 'PM' : 'AM';
        let pageTheme = Cookies.get('pazzy-theme')
        
        if(!pageTheme || pageTheme === 'default'){
            if(newformat === 'PM' && hours > 18){
                setTheme('dark')
            }else{
                setTheme('light')
            }
            setThemeOption('default')
        }
        setTimeout(setThemeAuto, 50000)
    }


    setAuthomaticThemeEffect.current = setThemeAuto

    useEffect(() => {
        setAuthomaticThemeEffect.current()
    }, [])


    return (
        <div className={`theme-option-container ${isThemeOption ? 'active' : ''}`}>
            <div className="dark-theme"></div>
            <div className="theme-option-body">
                <div className="theme-option">
                    <div className="title-header">
                        <h3>Select Theme</h3>
                    </div>
                    <div className="theme-body">
                        <div className="form-check">
                            <input onChange={(e) => setThemeOption('dark')} className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked={ themeOption === 'dark' ? true : ''}/>
                            <label className="form-check-label" htmlFor="flexRadioDefault1">
                                Dark 
                            </label>
                        </div>
                        <div className="form-check">
                            <input onChange={(e) => setThemeOption('light')} className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked={ themeOption === 'light' ? true : ''}/>
                            <label className="form-check-label" htmlFor="flexRadioDefault2">
                                Light
                            </label>
                        </div>
                        <div className="form-check">
                            <input onChange={(e) => setThemeOption('default')}  className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault3" checked={ themeOption === 'default' ? true : ''}/>
                            <label className="form-check-label" htmlFor="flexRadioDefault3">
                                System default
                            </label>
                        </div>
                        <div className="form-check-button">
                        <button onClick={() => toggleTheme('close')} type="button">Cancel</button>
                        <button onClick={() => toggleTheme(themeOption)} type="button" className="ok">Ok</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default ThemeOption