import { useEffect } from 'react'
import UserBody from './UserBody'
import TopBar from './TopBar'
import Metrics from './Metrics'
import MobileTable from './MobileTable'



const User = ({toggleFloatProfile}) => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className="general-table-container">
            <TopBar/>
            <Metrics/>
            <UserBody toggleFloatProfile={toggleFloatProfile}/>
            <MobileTable toggleFloatProfile={toggleFloatProfile}/>
        </div>
    )
}




export default User