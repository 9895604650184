import { NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
    faPlus,
    faCircleCheck
} from '@fortawesome/free-solid-svg-icons'
import {  user_img } from '../../File'
import TableBottom from './TableBottom'




const AdminUserBody = ({toggleAddMember, toggleFloatProfile}) => {
    const fetchUserProfile = () => {
        console.log('yes')
        toggleFloatProfile(true)
    }


    return (
        <div className="general-table-body">
            <div className="inner-body">
                <SearchBar toggleAddMember={toggleAddMember}/>
                <ContentTableParent fetchUserProfile={fetchUserProfile}/>
                <TableBottom/>
            </div>
        </div>
    )
}



export default AdminUserBody





const SearchBar = ({toggleAddMember}) => {
    return (
        <div className="search-bar">
            <div className="bar-left">
                <h3 className="title-header">Search Users</h3>
            </div>
            <div className="bar-right">
                <div className="form">
                    <input type="text" className="" placeholder="Search User"/>
                    <div className="add-member-btn">
                        <NavLink to="/admin/add-admin">
                            <FontAwesomeIcon className="icon" icon={faPlus} /> <span>Add Member</span>
                        </NavLink>
                    </div>
                    <button onClick={() => toggleAddMember(true)} type="button" className="admin-admin-btn">
                        <FontAwesomeIcon className="icon" icon={faPlus} /> <span>Add Member</span>
                    </button>
                </div>
            </div>
        </div>
    )
}






const ContentTableParent = ({fetchUserProfile}) => {
    return (
        <div className="general-table table-responsive">
            <table className="table table-hover">
                <thead>
                    <tr>
                        <th scope="col">User <span>(222,000)</span></th>
                        <th scope="col">Department</th>
                        <th scope="col">User Type</th>
                        <th scope="col">Registered Date | Time</th>
                        <th scope="col">Last Login</th>
                        <th scope="col">Verification</th>
                    </tr>
                </thead>
                <tbody>
                    <TabelContent fetchUserProfile={fetchUserProfile}/>
                    <TabelContent fetchUserProfile={fetchUserProfile}/>
                    <TabelContent fetchUserProfile={fetchUserProfile}/>
                    <TabelContent fetchUserProfile={fetchUserProfile}/>
                    <TabelContent fetchUserProfile={fetchUserProfile}/>
                    <TabelContent fetchUserProfile={fetchUserProfile}/>
                    <TabelContent fetchUserProfile={fetchUserProfile}/>
                    <TabelContent fetchUserProfile={fetchUserProfile}/>
                    <TabelContent fetchUserProfile={fetchUserProfile}/>
                    <TabelContent fetchUserProfile={fetchUserProfile}/>
                    <TabelContent fetchUserProfile={fetchUserProfile}/>
                </tbody>
            </table>
        </div>
    )
}

    


const TabelContent = ({fetchUserProfile}) => {
    return (
            <tr>
                <td>
                    <MobileViewImage fetchUserProfile={fetchUserProfile}/>
                    <DesktopViewImage fetchUserProfile={fetchUserProfile}/>
                </td>
                <td>Admin</td>
                <td>Helpdesk</td>
                <td>24th february 2025 | 5:30am</td>
                <td>24th february 2025 | 5:30am</td>
                <td className="status">
                    <span><FontAwesomeIcon className="icon failed" icon={faCircleCheck} /></span> Success
                </td>
            </tr>
    )
}












const DesktopViewImage = ({fetchUserProfile}) => {
    return (
        <div className="user-detail desktop">
            <div onClick={() => fetchUserProfile()} className="left">
                {/* <div className="no-img" style={{ backgroundColor: 'green'}}><span>R</span></div> */}
                <div className="img">
                    <img src={user_img('9.png')} alt="user-img"/>
                </div>
            </div>
            <div onClick={() => fetchUserProfile()} className="right">
                <h3>John Doe</h3>
                <p>Acc No: 1100223344</p>
            </div>
        </div>
    )
}






const MobileViewImage = ({fetchUserProfile}) => {
    return (
        <div className="user-detail mobile">
            <div onClick={() => fetchUserProfile()} className="left">
                <NavLink to="/admin/profile">
                    {/* <div className="no-img" style={{ backgroundColor: 'green'}}><span>R</span></div> */}
                    <div className="img">
                        <img src={user_img('9.png')} alt="user-img"/>
                    </div>
                </NavLink>
            </div>
            <div className="right">
                <NavLink to="/admin/profile">
                    <h3>John Doe</h3>
                </NavLink>
                <p>Acc No: 1100223344</p>
            </div>
        </div>
    )
}