import { useEffect } from 'react'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import BottomHelp from '../help/BottomHelp'
import RightSide from './RightSide'
import LeftSide from './LeftSide'
import ContactBanner from './ContactBanner'
import AwaitList from '../subscription/AwaitList'




const ContactUs = ({showAlert}) => {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant", // Optional if you want to skip the scrolling animation
        })
    }, [])

    return (
        <div className="contact-us-container">
            <ContactBanner/>
            <ContactUsBody showAlert={showAlert}/>
            <BottomHelp/>
            <AwaitList/>
        </div>
    )
}


export default ContactUs






const ContactUsBody = ({showAlert}) => {
    return (
        <div className="contact-us-body">
            <Row className="show-grid">
                <Col xs={12} sm={12} md={6} lg={6} xl={6}><LeftSide/></Col>
                <Col xs={12} sm={12} md={6} lg={6} xl={6}><RightSide showAlert={showAlert}/></Col>
            </Row>
        </div>
    )
}
















