import { useState, useEffect } from 'react'
import UserBody from './UserBody'
import TopBar from './TopBar'
import Metrics from './Metrics'
import SearchRange from './SearchRange'
import SessionsAndPageView from './SessionsAndPageView'
import Visits from './Visits'
import MobileTable from './MobileTable'





const DashBoard = ({pageDarkTheme, toggleFloatProfile, setPageDarkTheme}) => {
    const [byDate, setByDate] = useState('All')
    const [byType, setByType] = useState('All')
    const [byCountry, setByCountry] = useState('All')

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
  
    return (
        <div className="general-table-container">
            <TopBar/>
            <SearchRange pageDarkTheme={pageDarkTheme} setPageDarkTheme={setPageDarkTheme} byCountry={byCountry} setByCountry={setByCountry} byType={byType} setByType={setByType} byDate={byDate} setByDate={setByDate}/>
            <Metrics/>
            <SessionsAndPageView/>
            <Visits/>
            <UserBody toggleFloatProfile={toggleFloatProfile}/>
            <MobileTable toggleFloatProfile={toggleFloatProfile}/>
        </div>
    )
}




export default DashBoard