
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import {  logo } from '../../File'






const ProfileTopRight = () => {
    return (
        <div className="profile-top-right">
            <Title/>
            <CardBody/>
        </div>
    )
}



export default ProfileTopRight







const Title = () => {
    return (
        <div className="title-header">
            <ul>
                <li>Customer Card</li>
                <li>Total App Visits: <span>500</span></li>
            </ul>
        </div>
    )
}






const CardBody = () => {
    return (
        <div className="card-body">
            <Row className="show-grid">
                <CardItem type={{ color: '', image: 'litch-1.png' }}/>
                <CardItem type={{ color: 'gold', image: 'litch-2.png' }}/>
            </Row>
        </div>
    )
}




const CardItem = ({type}) => {
    return (
        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
            <div className={`card-item ${type.color}`}>
                <div className="card-top">
                    <h3 className="title">Total Credit Balance</h3>
                    <h3 className="amount">NGN 570,000</h3>
                </div>
                <div className="card-bottom">
                    <h3 className="title">Credit Due Date</h3>
                    <h3 className="date">28-03-2030</h3>
                </div>
                <div className="card-image">
                    <img src={logo(type.image)} alt="card-img"/>
                </div>
            </div>
        </Col>
    )
}