
const PageViews = () => {
    return (
        <div className="page-views-container">
            <div className="title-header">
                <h3>Page Views</h3>
            </div>
            <div className="page-review-body">
                <PageReviewItems title={'Homepage'} link={'payizzy.co/home/index.html'} amount={'110,230'} percentage={'45%'}/>
                <PageReviewItems title={'About Us'} link={'payizzy.co/home/about.html'} amount={'130,250'} percentage={'84%'}/>
                <PageReviewItems title={'Login'} link={'payizzy.co/app/login.html'} amount={'150,280'} percentage={'58%'}/>
                <PageReviewItems title={'Dashboard'} link={'payizzy.co/admin/dashboard.html'} amount={'190,220'} percentage={'24%'}/>
                <PageReviewItems title={'Settings'} link={'payizzy.co/app/settings.html'} amount={'160,210'} percentage={'63%'}/>
                <PageReviewItems title={'Users'} link={'payizzy.co/admin/users.html'} amount={'190,220'} percentage={'70%'}/>
                <PageReviewItems title={'Review'} link={'payizzy.co/app/review.html'} amount={'160,210'} percentage={'63%'}/>
            </div>
        </div>
    )
}



export default PageViews






const PageReviewItems = ({title, link, amount, percentage}) => {
    return (
        <div className="page-review-item">
            <div className="left">
                <h3 className="title">{title}</h3>
                <div className="link">{link}</div>
            </div>
            <div className="right">
                <h3 className="amount">{amount}<span>({percentage})</span></h3>
            </div>
        </div>
    )
}