import { useEffect } from 'react'
import AOS from 'aos'
import { NavLink } from 'react-router-dom'




const Banner2 = () => {
    const contentBanner = {
        _id: 1,
        content_id: 1,
        title: 'Have ease, stay soft',
        text: 'to create a new array, updating the item at the specified index with the new value to create a new array, updating the item at the specified index with the new value',
        link: '/',
        background: 'linear-gradient(to top, black, #333)',
        is_featured: 1,
        created_at: '',
        updated_at: ''
    }

    useEffect(() => {
        AOS.init({ duration: 2000 })
    })

    return (
        <div data-aos={'zoom-in'} className="Banner2-container" style={{ backgroundImage: contentBanner.background}}>
            <ul>
                <li>
                    <div className="title-header">
                        <h3>{contentBanner.title}</h3>
                    </div>
                </li>
                <li>
                    <p>{contentBanner.text}</p>
                </li>
                {
                    contentBanner.link ? (
                        <li>
                            <NavLink to={contentBanner.link} className="link">Create an Account</NavLink>
                        </li>
                    ) : null
                }
            </ul>
        </div>
    )
}


export default Banner2