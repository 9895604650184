import { useEffect } from 'react'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { app_image } from '../../File'
import { NavLink } from 'react-router-dom'
import AOS from 'aos'



const ContentLinks = () => {
    
    const contentLinks = [
        {
            title: "Receive",
            text: "Receive money instantly with your own unique QR code",
            icon: "send-money-icon.png",
        },
        {
            title: "Send",
            text: "Send money effortlessly using secure QR codes",
            icon: "receive-payments-icon.png",
        },
        {
            title: "Receive",
            text: "Request funds directly on your Payizzy app using QR codes",
            icon: "request-payments-icon.png",
        },
        {
            title: "Pay in Chat",
            text: "Use and experience the power of  chat-powered transactions",
            icon: "pay-in-chat-icon.png",
        },
        {
            title: "100% success",
            text: "Never ever complain of failed transactions with Payizzy",
            icon: "success-icon.png",
        },
        {
            title: "Use Izzie AI",
            text: "Make AI powered transactions with your personal Izzie buddy",
            icon: "izzy-ai-icon.png",
        },
    ]

    useEffect(() => {
        AOS.init({
            duration: 2000
        })

    }, [])

    return (
        <div className="content-banner-container">
           <Row className="show-grid">
                { contentLinks.map((content, index) => (<ContentItem key={index} number={index+1} content={content}/>)) }
            </Row>
        </div>
    )
}



export default ContentLinks





const ContentItem = ({ number, content }) => {
    return (
        <Col data-aos={'flip-up'} xs={12} sm={12} md={6} lg={6} xl={4}>
            <div className="content-banner-item">
                <NavLink to="/">
                    <ul>
                    { 
                        content.icon ? (
                            <li>
                                <img src={app_image(content.icon)} alt="content-banner-img"/>
                            </li>
                        ) : null
                    }
                        { content.title ? ( <li className="title">{content.title}</li>) : null }
                        { content.text ? ( <li className="text">{content.text}</li>) : null }
                    </ul>
                </NavLink>
            </div>
        </Col>
    )
}