import React from 'react'

const ContentPreloader = () => {
  return (
    <div className="content-preloader-container">
        <div className="content-preloader-inner">
            <div className="preloader-spinner"></div>
            {/* <div className="text">Loading...</div> */}
        </div>
    </div>
  )
}

export default ContentPreloader
