import React, { useState } from 'react';
import Axios from 'axios'
import { url } from '../../File'
import FormAlertError from '../alerts/FormAlertError'
import {AlertDanger} from '../alerts/SimpleAlert'




const RightSide = ({showAlert}) => {
    const [alert, setAlert] = useState('')
    const [name, setName] = useState('')
    const [phone, setPhone] = useState('')
    const [email, setEmail] = useState('')
    const [message, setMessage] = useState('')

    const [nameAlert, setNameAlert] = useState('')
    const [phoneAlert, setPhoneAlert] = useState('')
    const [emailAlert, setEmailAlert] = useState('')
    const [messageAlert, setMessageAlert] = useState('')

    const [buttonLock, setButtonLock] = useState(false)

    // send message
    const SendMessage = () => {
        setAlert('')
        const message_alert = { 
                active: 'active',
                type: 'popup',
                title: "Contact Success!", 
                message: "Thank you for contacting payizzy"
            }
       
        setButtonLock(true)

        const contact = { name, phone, email, message}

        // validate input fields
        const validate = validate_input(contact)
        if(validate === 'failed'){
            return setButtonLock(false)
        }

        Axios.post(url('/api/contact-us'), contact).then((response) => {
            const data = response.data
            console.log(data)
            if(data.validationError === true){
                setButtonLock(false)
                return validateFromBackend(data.validation)
            }
            if(data === true){
                initInput()
                setButtonLock(false)
                initAlertMessage()
                return showAlert(message_alert)
            }
            initInput()
            initAlertMessage()
            setButtonLock(false)
            return setAlert('Error, Try again later!')
        })
    }


    // initialise input fields
    const initInput = () => {
        setName('')
        setEmail('')
        setPhone('')
        setMessage('')
    }


    // validate input fields
    const validate_input = (input) => {
        let failed = false
        const words = 1500

        const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if(input.email.length === 0){
            failed = true
            setEmailAlert(`*Email field is required`)
        } else if(!input.email.match(validRegex)){
            failed = true
            setEmailAlert(`*Invalid email address`)
        }

        if(input.name.length === 0){
            failed = true
            setNameAlert(`*Name field is required`)
        }else if(input.name.length < 6){
            failed = true
            setNameAlert(`*Must be minimum of 6 characters`)
        }else if(input.name.length > 30){
            failed = true
            setNameAlert(`*Must be maximum of 30 characters`)
        }

        if(input.phone.length === 0){
            failed = true
            setPhoneAlert(`*Phone field is required`)
        }else if(input.phone.length < 11){
            failed = true
            setPhoneAlert(`*Must be minimum of 11 characters`)
        }else if(input.phone.length > 15){
            failed = true
            setPhoneAlert(`*Must be maximum of 15 characters`)
        }


        if(input.message.length === 0){
            failed = true
            setMessageAlert(`*Message field is required`)
        }else if(input.message.length < 10){
            failed = true
            setMessageAlert(`*Must be minimum of 10 characters`)
        }else if(input.message.split(" ").length > words){
            failed = true
            setMessageAlert(`*Maximum words of ${words}!`)
        }

        if(failed === true){
            return 'failed'
        }else{
            return 'success'
        }
    }

    //  validate from the server
    const validateFromBackend = (error) => {
        initAlertMessage()

        if(error.email){
            setEmailAlert(error.email)
        }
        if(error.name){
            setNameAlert(error.name)
        }
        if(error.phone){
            setPhoneAlert(error.phone)
        }
        if(error.message){
            setMessageAlert(error.message)
        }
    }


    //  initialise alert message 
    const initAlertMessage = () => {
        setNameAlert('')
        setEmailAlert('')
        setPhoneAlert('')
        setMessageAlert('')
    }



    // check message count
    const messageInput = (message) => {
        const words = 1500
        let wordCount =  message.split(" ").length;
        
        if(wordCount > words){
            setButtonLock(true)
            setMessageAlert(`You have exceeded  the maximum words of ${words}!`)
        }else{
            setButtonLock(false)
            setMessageAlert('')
        }
        setMessage(message)
    }

    return (
        <div className="right-side">
            <div className="form">
                <div className="form-group">
                    <label htmlFor="">Name</label>
                    <FormAlertError alert={nameAlert}/>
                    <input type="text" onChange={(e) => setName(e.target.value)} value={name} className="form-control" placeholder="Type Name"/>
                </div>
                <div className="form-group">
                    <label htmlFor="">Phone</label>
                    <FormAlertError alert={phoneAlert}/>
                    <input type="text" onChange={(e) => setPhone(e.target.value)} value={phone} className="form-control" placeholder="Type Phone Number"/>
                </div>
                <div className="form-group">
                    <label htmlFor="">Email</label>
                    <FormAlertError alert={emailAlert}/>
                    <input type="text" onChange={(e) => setEmail(e.target.value)} value={email} className="form-control" placeholder="Type Email Address"/>
                </div>
                <div className="form-group">
                    <label htmlFor="">How Can We Help You?</label>
                    <textarea rows={4} cols={40}  onChange={(e) => messageInput(e.target.value)} value={message}className="form-control" placeholder="Type Message"></textarea>
                    <FormAlertError alert={messageAlert}/>
                    <span className="max-word">Maximum words: 1500</span>
                </div>
                <div className="form-group  button">
                {
                    buttonLock === true ? (
                        <button type="button" disabled>Please wait...</button>
                    ) : (
                        <button onClick={() => SendMessage()} type="button">Send Message</button>
                    )
                }
                </div>
                {
                    alert.length > 0 ? (<AlertDanger alert={'Error, Try again later!'}/>) : null
                }
                
            </div>
        </div>
    )
}




export default RightSide