import { Fragment } from 'react'
import TitleHeader from './TitleHeader'
import SearchForm from './SearchForm'
import DownloadApp from '../extras/DownloadApp'
import FAQContentContainer from './FAQContentContainer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
    faMagnifyingGlass,
} from '@fortawesome/free-solid-svg-icons'









const Faq = () => {
    const title = {
        titles: [
            { title: 'Frequently asked questions', color: '#000000', line_break: false },
            { title: '(FAQ)', color: '#FDB813', line_break: false },
        ],
        text: "Find answers to the most frequently asked questions.",
        created_at: '',
        updated_at: '',
    }

    const faqItems = [
        {
            title: "How do I create an account on the Payizzy app?",
            text: "To create an account on Payizzy, download the app from the App Store or Google Play Store, then follow the on-screen instructions to sign up with your personal details, such as your name, email address, phone number and BVN. Once registered, you can start using the app right away",
            steps: [],
        },
        {
            title: "How can I send money using QR codes on Payizzy?",
            text: "To send money using QR codes on Payizzy, follow these steps:",
            steps: [
                'Open the Payizzy app and navigate to the "Send Money" section.',
                'Select the option to scan QR code.',
                'Scan the recipients QR code or enter their Payizzy ID.',
                ' Enter the amount you wish to send and confirm the transaction.',
                'The money will be sent instantly to the recipients Payizzy wallet'
            ]
        },
        {
            title: "How do I generate QR codes on Payizzy?",
            text: "Generating QR codes on Payizzy is easy. Simply go to the 'Generate QR Code' section in the app, enter the amount you want to receive and a unique QR code will be generated for you. You can then share this QR code with others to receive payments.",
            steps: [],
        },
        {
            title: "How do I receive money using QR codes on Payizzy?",
            text: "To receive money using QR codes on Payizzy, follow these steps:",
            steps: [
                'Open the Payizzy app and navigate to the "Receive Money" section.',
                'Show your QR code to the sender, who can scan it using their Payizzy app.',
                'Once scanned, the sender can enter the amount to send, and the money will be instantly transferred to your Payizzy wallet',
            ]
        },
        {
            title: "Can I request money using QR codes on Payizzy?",
            text: "Yes, you can request money using QR codes on Payizzy. Simply generate a QR code with the amount you want to request and share it with the payer. They can then scan the QR code and proceed to fulfil your request.",
            steps: [],
        },
        {
            title: "How can I top up my Payizzy wallet?",
            text: "You can top up your Payizzy wallet using various methods such as bank transfers, debit/credit cards, or by visiting any Payizzy authorized agent. Simply choose your preferred top-up method within the app and follow the instructions to complete the transaction",
            steps: [],
        },
        {
            title: "How do I pay for airtime bills on Payizzy?",
            text: "To pay for airtime bills on Payizzy, go to Payments, Pay Bills 'Airtime Top up' section in the app, select your mobile network provider, enter the phone number you wish to top up and choose the amount. Confirm the transaction and the airtime will be instantly credited to the specified number.",
            steps: [],
        },
        {
            title: "Can I pay data bills on Payizzy?",
            text: "Yes, you can pay for data bills on Payizzy. Navigate to Payments, Pay Bills 'Data' section in the app, select your internet service provider, enter your account details, choose the data bundle you want to purchase, and confirm the transaction.",
            steps: [],
        },
        {
            title: "How do I pay electricity bills on Payizzy?",
            text: "Paying electricity bills on Payizzy is simple. Navigate to Payments, Pay Bills 'Electricity Bill' section in the app, select your utility provider, enter your account number or meter number, and choose the amount you wish to pay. Confirm the transaction, and your electricity bill will be settled instantly.",
            steps: [],
        },
        {
            title: "Can I top up my sports betting wallet using Payizzy?",
            text: "Yes, you can top up your sports betting wallet using Payizzy. Go to Payments, Pay Bills, and the 'Sports Betting' section in the app, select your preferred betting platform, enter your account details, choose the amount you want to top up, and confirm the transaction. Your betting wallet will be credited instantly, allowing you to place bets seamlessly.",
            steps: [],
        },
        {
            title: "How can I pay my TV subscription bills using Payizzy?",
            text: "To pay your TV subscription bills on Payizzy, navigate to the 'TV Subscription' section in the app, select your TV service provider, enter your account details, choose the subscription package, and confirm the payment. Your TV subscription will be renewed instantly.",
            steps: [],
        },
        {
            title: "How do I become an Izzy agent?",
            text: " If you're interested in becoming an Izzy agent, please visit our website or contact our customer support for more information on the application process and requirements. We welcome individuals and businesses who are interested in joining our network of agents.",
            steps: [],
        },
        {
            title: "What should I do if I lose my phone?",
            text: " If you lose your phone, immediately contact our customer support team to report the incident. We will help you secure your account and provide guidance on how to recover or deactivate your Payizzy account to prevent unauthorized access and transactions",
            steps: [],
        },
        {
            title: "How can I withdraw funds from an Izzy agent?",
            text: "To withdraw funds from an Izzy agent, visit any authorized Izzy agent location nearest to you with your identification and account details. Request a withdrawal from the agent, and they will assist you in completing the transaction securely.",
            steps: [],
        },
        {
            title: "Is PayIzzy a registered bank?",
            text: "PayIzzy is not a registered bank. We are a financial technology company providing innovative digital payment solutions and services to individuals and businesses.",
            steps: [],
        },
        {
            title: "What sets PayIzzy apart from other banks?",
            text: "PayIzzy distinguishes itself through its user-friendly mobile app, swift and seamless payment experiences, innovative features such as QR code payments, AI features (coming soon) and a commitment to providing accessible financial services to underserved communities.",
            steps: [],
        },
        {
            title: "What should I do if I require assistance?",
            text: "If you need help or assistance with any aspect of the PayIzzy app or services, please reach out to our customer support team through the app or website. We're here to assist you with any questions or concerns you may have.",
            steps: [],
        },
        {
            title: "Where is your office located?",
            text: "Our main office is located in Lagos State, however, for the convenience of our users, we primarily operate digitally through our mobile app and online platforms. You can reach us anytime through our customer support channels",
            steps: [],
        },
    ]

  return (
    <Fragment>
        <div className="faq-page-container">
            <TitleHeader title={title}/>
            {/* <SearchForm/> */}
            { faqItems.length ? (<FAQContentContainer faqItems={faqItems}/>) : (<EmptyContent/>) }
        </div>
        <DownloadApp/>
    </Fragment>
  )
}

export default Faq





const EmptyContent = () => {
  return (
    <div className="empty-page-content">
        <div className="icon-item">
            <FontAwesomeIcon className="icon" icon={faMagnifyingGlass} />
        </div>
      <div className="text">
        There are no FAQ contents...
      </div>
    </div>
  )
}

