import { useEffect } from 'react'
import Banner from './Banner'
import Banner2 from './Banner2'
import ContentLinks from './ContentLinks'
import HowItWorks from './HowItWorks'
import Testimonial from '../home/Testimonial'
import Content from './Content'
import Banner3 from './Banner3'
import Content2 from './Content2'
import DownloadApp from '../extras/DownloadApp'




const Home = ({Contentloader}) => {
    useEffect(() => {
        window.scrollTo(0, 0) // page scroll to top
      }, [])

    return (
        <div className="personal-container">
            <Banner />
            <ContentLinks/>
            <HowItWorks/>
            <Content/>
            <Banner2 />
            <Testimonial/>
            <Content2/>
            <Banner3/>
            <DownloadApp/>
        </div>
    )
}




export default Home