import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
    faUpload,
} from '@fortawesome/free-solid-svg-icons'




const SearchBar = () => {
    return (
        <div className="search-bar">
            <div className="bar-left">
                <h3 className="title-header">Transactions</h3>
            </div>
            <div className="bar-right">
                <div className="form">
                    <input type="text" className="" placeholder="Search User"/>
                    <button type="button"><FontAwesomeIcon className="icon" icon={faUpload} /> Export</button>
                </div>
            </div>
        </div>
    )
}



export default SearchBar