
import { useEffect } from 'react'
// import TitleHeader from './TitleHeader'
import BlogBanner from './BlogBanner'
import FeaturedPost from './FeaturedPost'
import TrendingBlog from './TrendingBlog'
import HowTo from './HowTo'
import AwaitList from '../../subscription/AwaitList'







const Blog = ({featuredPost}) => {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant", // Optional if you want to skip the scrolling animation
        })
    }, [])

    return (
        <div className="blog-container">
            {/* <TitleHeader/> */}
            <BlogBanner/>
            <FeaturedPost featuredPost={featuredPost}/>
            <TrendingBlog/>
            <HowTo/>
            <AwaitList/>
        </div>
    )
}


export default Blog






