




const AlertDanger = ({alert}) => {
    return (
        <div className="simple-alert danger">{alert}</div>
    )
}




export{
    AlertDanger
}