import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import VisitByCountry from './VisitByCountry'
import VisitorMap from './VisitorMap'






const Visits = () => {
    return (
        <div className="visits-container">
            <Row className="show-grid">
                <Col xs={12} sm={12} md={12} lg={12} xl={7}>
                    <VisitByCountry/>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={5}>
                    <VisitorMap/>
                </Col>
            </Row>
        </div>
    )
}


export default Visits