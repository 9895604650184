
const VisitorMap = () => {
    return (
        <div className="visitor-map-container">
            <div className="title-header">
                <h3>Visited by country</h3>
            </div>
            <div className="world-map">
                World Map Loading...
            </div>
        </div>
    )
}




export default VisitorMap