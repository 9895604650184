
import { app_image } from '../../File'
import { NavLink } from 'react-router-dom'
import HTMLReactParser from 'html-react-parser'
import { useEffect } from 'react'
import AOS from 'aos'





const Banner = () => {
        const banner = {
            image: "banner-img-5.png",
            titles: [
                { title: 'How', color: '#000000', line_break: false },
                { title: 'Payizzy', color: '#FDB813', line_break: false },
                { title: 'Came to Life', color: '#000000', line_break: false },
            ],
            text: "The Easiest and Safest Way to Make Money as an Izzy Agent",
            apple_btn_image: "",
            playstore_btn_image: "",
            apple_download_link: "",
            playstore_download_link: "",
            button: '',
            button_link: '',
            created_at: '',
            updated_at: '',
        }

        useEffect(() => {
            AOS.init({duration: 2000 })
    
        }, [])




    return (
        <div className="page-banner-container">
            {
                banner ? (
                   <div className="page-banner-inner">
                        <ContentLeft banner={banner}/>
                        <ContentRight banner={banner}/>
                   </div>
                ) : null
            }
        </div>
    )
}



export default Banner





const ContentLeft = ({banner}) => {
    return (
        <div data-aos={'slide-right'} className="content-left">
            <div className="title-header">
                {
                    banner.titles.map((title, index) => (
                        <span key={index} style={{ color: title.color}}>{title.title} {title.line_break ? (<br/>) : null }</span>
                    ))
                }
            </div>
            <div className="paragraph">{HTMLReactParser(banner.text)}</div>
            { 
                banner.apple_download_link && banner.playstore_download_link ? (
                    <Downloadbuttons banner={banner}/>
                ) : null
            }
            {
                banner.button ? (<Button banner={banner}/>) : null
            }
        </div>
    )
}





const Downloadbuttons = ({banner}) => {
    return (
        <div className="download-left-buttons">
            <div className="title-header">
            </div>
            <div className="buttons">
                {
                    banner.apple_download_link ? (
                        <NavLink to={banner.apple_download_link}>
                            <img src={app_image(banner.apple_btn_image)} alt={banner.apple_btn_image}/>
                        </NavLink>
                    ) : null
                }
                {
                    banner.playstore_download_link ? (
                        <NavLink to={banner.playstore_download_link}>
                            <img src={app_image(banner.playstore_btn_image)} alt={banner.playstore_btn_image}/>
                        </NavLink>
                    ) : null
                }
            </div>
        </div>
    )
}





const ContentRight = ({banner}) => {
    return (
        <div data-aos={'zoom-in'} className="content-right">
           <div className="image">
                <img src={app_image(banner.image)} alt={banner.image}/>
           </div>
        </div>
    )
}





const Button = ({banner}) => {
    return (
        <div className="banner-button">
            <NavLink to={banner.button_link}>{banner.button}</NavLink>
        </div>
    )
}



