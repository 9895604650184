import { Fragment } from 'react'
import { app_image, logo } from '../../File'
import { NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
    faStar,
} from '@fortawesome/free-solid-svg-icons'
import {
    isIOS,
    isMobile,
    isTablet,
    isAndroid,
  } from 'react-device-detect';





const DownloadApp = () => {
  return (
    <Fragment>
        {
            isMobile || isTablet ?
            (
                <div className="mobile-banner-image">
                    <LeftContent/>
                    <RightContent/>
                </div>
            ) : null
        }
    </Fragment>
  )
}

export default DownloadApp














const LeftContent = () => {
  return (
    <div className="left">
        <div className="logo">
            <img src={logo('logo-2.png')} alt="logo-2"/>
        </div>
        <div className="text">
            <h3>Payizzy</h3>
            <div className="stars">
                <FontAwesomeIcon className="icon" icon={faStar} />
                <FontAwesomeIcon className="icon" icon={faStar} />
                <FontAwesomeIcon className="icon" icon={faStar} />
                <FontAwesomeIcon className="icon" icon={faStar} />
                <FontAwesomeIcon className="icon" icon={faStar} />
            </div>
        </div>
    </div>
  )
}






const RightContent = () => {
    return (
        <div className="right">
            {
                isIOS && (
                    <NavLink to="/">
                        <img src={app_image('app-store.png')} alt="app-store"/>
                    </NavLink>
                )
            }
            {
                isAndroid && (
                    <NavLink to="/">
                        <img src={app_image('play-store.png')} alt="play-store"/>
                    </NavLink>
                )
            }
        </div>
    )
  }
  