import React, { useState, useRef, useEffect, Fragment } from 'react';
import './css/Style.css'
import Cookies from 'js-cookie'
import { Route, Routes } from 'react-router-dom'
import Login from './auth/Login'
import Transaction from './transaction/Transaction'
import Navigation from './navigation/Navigation'
import AdminUser from './admin-user/AdminUser'
import AddAdmin from './admin-user/AddAdmin'
import Preloader from './preloader/Preloader'
import ActionPreloader from './preloader/ActionPreloader'
import LoanRequest from './loan/LoanRequest'
import Referal from './referal/Referal'
import User from './user/User'
import DashBoard from './dashboard/DashBoard'
import Profile from './profile/Profile'
import DarkTheme from './include/DarkTheme'
import FloatProfile from './profile/FloatProfile'
import ModalDropDown from './modal/ModalDropDown'
import ThemeOption from './modal/ThemeOption'
import AdminProfile from './admin-user/AdminProfile'
import PageContent from './page-content/PageContent'
import PageCategory from './pageCategory/PageCategory'
import MobileAddPageCategory from './pageCategory/MobileAddPageCategory'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';





const AdminPanel = () => {
    const fetchPageThemeEffect = useRef()
    const pageloaderEffect = useRef()

    const [alert, setAlert] = useState('')
    const [theme, setTheme] = useState('light')
    const [actionloader, setActionloader] = useState({state: false, text: 'Loading, please wait...'})
    const [isThemeOption, setIsThemeOption] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [pageDarkTheme, setPageDarkTheme] = useState(false)
    const [isUserProfile, setIsUserProfile] = useState(false)
    const [floatProfile, setFloatProfile] = useState(false)
    const [navState, setNavState] = useState(true)

    // modal useState
    const [deactvateModal, setDeactvateModal] = useState({state: true, modal: false})
    const [deletePageCategoryModal, setDeletePageCategoryModal] = useState({state: true, modal: false})
    
    // toggle modal open or close
    const toggleModal = (modal = '', state = '') => {
        if(state){
            setDeactvateModal({state: state, modal: false})
            setTimeout(() => {
                setDeactvateModal({state: state, modal: modal})
            },100)
        }else{
            setDeactvateModal({state: false, modal: false})
        }
    }
    // toggle delete page category modal open or close
    const toggleDeletePageCategoryModal = (modal = '', state = '', id='') => {
        if(state){
            setDeletePageCategoryModal({state: state, modal: false})
            setTimeout(() => {
                setDeletePageCategoryModal({state: state, modal: modal, id: id})
            },100)
        }else{
            setDeletePageCategoryModal({state: false, modal: false})
        }
    }

    // initialize functions
    const init = () => {
        setPageDarkTheme(false)
        setFloatProfile(false)
        setIsUserProfile(false)
    }

    // toggle user float profile 
    const toggleFloatProfile = (state) => {
        let x = state === true ? true : false
        setIsUserProfile(x)
        setTimeout(() => {
            setPageDarkTheme(x)
            setFloatProfile(x)
        }, 100)
    }
    

    // fetch page theme from cookie
    const fetchPageTheme = () => {
        let pageTheme = Cookies.get('pazzy-theme')
        if(pageTheme){
            return setTheme(pageTheme)
        }
    }

    // alert notification
    const alertNotification = (state, messsage) => {
        if(state === 'error'){
            return toast.error(messsage, {theme: theme});
        }else if(state === 'success'){
            return toast.success(messsage, {theme: theme});
        }else if(state === 'warning'){
            return toast.warning(messsage, {theme: theme});
        }
    }

    // toogle dark background
    const toggelDarkTheme = () => {
        init()
        setPageDarkTheme(!pageDarkTheme)
    }

    // remove preloader when page loads
    const pageLoader = () => {
        setTimeout(() => {
            setIsLoading(false)
        }, 3000)
    }

    // toggle action prelaoder
    const getActionloader = (state, text) => {
        setActionloader({ state: state,  text: text})
    }

    fetchPageThemeEffect.current = fetchPageTheme
    pageloaderEffect.current = pageLoader

    useEffect(() => {
        fetchPageThemeEffect.current()
        pageloaderEffect.current()
    }, [])

    
    return (
        <div className={`admin-container ${theme}`}>
        { isLoading === true ? (<Preloader/>) : (
            <Fragment> 
                <ActionPreloader actionloader={actionloader}/>
                <ThemeOption theme={theme} setTheme={setTheme} setIsThemeOption={setIsThemeOption} isThemeOption={isThemeOption}/>
                { navState ? (<Navigation setIsThemeOption={setIsThemeOption}/>) : null }
                <Routes>
                    <Route path="/admin" element={<DashBoard toggleFloatProfile={toggleFloatProfile} pageDarkTheme={pageDarkTheme} setPageDarkTheme={setPageDarkTheme}/>}/>
                    <Route path="/admin/dashboard" element={<DashBoard toggleFloatProfile={toggleFloatProfile} pageDarkTheme={pageDarkTheme} setPageDarkTheme={setPageDarkTheme}/>}/>
                    <Route path="/admin/login" element={<Login setNavState={setNavState}/>}/>
                    <Route path="/admin/users" element={<User toggleFloatProfile={toggleFloatProfile}/>}/>
                    <Route path="/admin/manage-admin" element={<AdminUser toggleFloatProfile={toggleFloatProfile}/>}/>
                    <Route path="/admin/add-admin" element={<AddAdmin/>}/>
                    <Route path="/admin/transaction" element={<Transaction toggleFloatProfile={toggleFloatProfile}/>}/>
                    <Route path="/admin/loan-request" element={<LoanRequest toggleFloatProfile={toggleFloatProfile}/>}/>
                    <Route path="/admin/referals" element={<Referal toggleFloatProfile={toggleFloatProfile}/>}/>
                    <Route path="/admin/profile" element={<Profile toggleModal={toggleModal}/>}/>
                    <Route path="/admin/admin-profile" element={<AdminProfile/>}/>
                    <Route path="/admin/add-page-categroy" element={<MobileAddPageCategory/>}/>
                    <Route path="/admin/page-content" element={<PageContent getActionloader={getActionloader} alertNotification={alertNotification}/>}/>
                    
                    <Route path="/admin/page-category" element={<PageCategory toggleDeletePageCategoryModal={toggleDeletePageCategoryModal} alertNotification={alertNotification}/>}/>
                </Routes>
                { isUserProfile ? (<FloatProfile floatProfile={floatProfile} toggleModal={toggleModal} toggleFloatProfile={toggleFloatProfile}/>) : null }
                { pageDarkTheme ? (<DarkTheme toggelDarkTheme={toggelDarkTheme}/>) : null }
                <ModalDropDown 
                    deactvateModal={deactvateModal} toggleModal={toggleModal}
                    toggleDeletePageCategoryModal={toggleDeletePageCategoryModal}
                    deletePageCategoryModal={deletePageCategoryModal}
                    alertNotification={alertNotification}
                />
                <ToastContainer />
            </Fragment>
        )}
    </div>
    )
    
}


export default AdminPanel