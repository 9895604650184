
import React, { useState, useEffect, useRef, Fragment} from 'react';
import './css/Style.css'
import { Route, Routes } from 'react-router-dom'

import Navigation from './navigation/Navigation'
import Footer from './footer/Footer'

import Home from './home/Home'
import Blog from './blog/home/Blog'
import ContactUs from './contact/ContactUs'
import Help from './help/Help'
import Faq from './faq/Faq'
import HelpContent from './help/HelpContent'
import Personal  from './personal/Personal'
import Detail from './personal/Detail'
import Agent from './agent/Agent'
import Company from './company/Company'


import Preloader from './preloader/Preloader'
import ContentPreloader from './preloader/ContentPreloader'
import Alert  from './alerts/Alert'
import AwaitList  from './subscription/AwaitList'







// Static data for  development
import {  
  bannerTwoStatic,
  faqItemsStatic,
  bannerDescStatic,
  bannerImageStatic,
  featuredPostStatic,
  helpCategoriesStatic,
} from './Data'







const ClientPanel = () => {
    const [isAppReady, setIsAppReady] = useState(false)
    const [isContentLoader, setIsContentLoader] = useState(false)
    const [awaitListActive, setAwaitListActive] = useState(false)
    const [bannerImage, setBannerImage] = useState(bannerImageStatic)
    const [bannerTwo, setBannerTwo] = useState(bannerTwoStatic)
    const [faqItems, setFaqItems] = useState(faqItemsStatic)
    const [featuredPost, setFeaturedPost] = useState(featuredPostStatic)
    const [helpCategories, setHelpCategories] = useState(helpCategoriesStatic)
  
    
    const [faqQuestion, setFaqQuestion] = useState(faqItems[0])
    const [bannerDesc, setBannerDesc] = useState(bannerDescStatic)
    const [isLoading, setIsLoading] = useState({state: true, text: 'Loading...', time: 3000})
    
  
    // app alert useState
    const [alertType, setAlertType] = useState('')
  
    const toggleFqContent = (item) => {
      setFaqQuestion(item)
      // add preloader to faq items
    }

     // toggle content preloader
     const Contentloader = (state) => {
      setIsContentLoader(state)
    }
  
    
    // show client alert
    const showAlert = (alert) => {
      setAlertType(alert)
    }
     // remove client alert
    const closeAlert = () => {
      setAlertType('')
    }

  
    return (
      <div className="app-parent-container">
        {
          isAppReady === false ? (
            <Preloader setIsAppReady={setIsAppReady}/>
          ) : (
            <Fragment>
                <Navigation/>
                <Routes>
                  <Route path="/" element={<Home Contentloader={Contentloader} bannerImage={bannerImage} bannerDesc={bannerDesc} bannerTwo={bannerTwo} faqItems={faqItems} faqQuestion={faqQuestion} toggleFqContent={toggleFqContent}/>}/>
                  <Route path="/personal" element={<Personal Contentloader={Contentloader}/>}/>
                  <Route path="/faq" element={<Faq Contentloader={Contentloader}/>}/>
                  <Route path="/agent" element={<Agent Contentloader={Contentloader}/>}/>
                  <Route path="/company" element={<Company Contentloader={Contentloader}/>}/>
                  <Route path="/blog" element={<Blog featuredPost={featuredPost}/>}/>
                  <Route path="/contact-us" element={<ContactUs AwaitList={AwaitList} showAlert={showAlert}/>}/>
                  <Route path="/help" element={<Help helpCategories={helpCategories}/>}/>
                  <Route path="/help-content" element={<HelpContent />}/>
                  <Route path="/personal/detail" element={<Detail Contentloader={Contentloader}/>}/>
                </Routes>
              <div className="footer"><Footer setAwaitListActive={setAwaitListActive}/></div>
              <AwaitList awaitListActive={awaitListActive} setAwaitListActive={setAwaitListActive}/>
              <Alert alertType={alertType} closeAlert={closeAlert}/>
              { isContentLoader ? (<ContentPreloader/>) : null }
            </Fragment>
          )
        }
      </div>
      );
}

export default ClientPanel
