import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
    faCircleCheck
} from '@fortawesome/free-solid-svg-icons'





const Alert = ({alertType, closeAlert}) => {
    const type = alertType.type
    const active = alertType.active
    const title = alertType.title
    const message = alertType.message
    
    return (
        <div className={`popup-alert ${type} ${active}`}>
            <div className="dark-theme"></div>
            <PopUpAlert message={message} title={title} closeAlert={closeAlert}/>
        </div>
    )
}

export default Alert






const PopUpAlert = ({message, title, closeAlert}) => {
    return (
        <div className="body">
            <div className="inner-popup-alert">
                <div className="icon-container">
                    <ul>
                        <li><FontAwesomeIcon className="icon" icon={faCircleCheck} /></li>
                        <li><h3 className="title-header">{title}</h3></li>
                        <li><p>{message}</p></li>
                        <li><button onClick={() => closeAlert()} type="">Ok</button></li>
                    </ul>
                </div>
            </div>
        </div>
    )
}



