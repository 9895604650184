import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
    faCircleCheck
} from '@fortawesome/free-solid-svg-icons'




const WaitListAlert = ({confirmation, toggelList}) => {
    const title = confirmation.title
    const message = confirmation.message
    
    return (
        <div className="confirmation-container">
            <FontAwesomeIcon className="icon" icon={faCircleCheck} />
            <h3>{title}</h3>
            <p>{message}</p>
            <button onClick={() => toggelList(false)} type="button">Close</button>
        </div>
    )
}

export default WaitListAlert


