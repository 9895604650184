import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { app_image } from '../../File'
import { useState, useEffect, useRef } from 'react'
import AOS from 'aos'





const HowItWorks = () => {
    const InitailImageRef = useRef(null)
    const [imageBanner, setImageBanner] = useState()

    const header = {
        title: 'How to Become an Izzy Agent',
        text: 'Step by step on how to become Izzy agent',
    }

    const howItWorks  = [
        {
            image: 'work-it-works.png',
            title: "Step 1",
            description: "Sign up on Payizzy Business Account"
        },
        {
            image: 'content-image-1.png',
            title: "Step 2",
            description: "Verify your account and complete all KYB information by providing details like your NIN, BVN etc.."
        },
        {
            image: 'content-image-2.png',
            title: "Step 3",
            description: "Set up your account, generate your QR code and print it out "
        },
        {
            image: 'content-image-3.png',
            title: "Step 4",
            description: "Fund your wallet"
        },
        {
            image: 'content-image-4.png',
            title: "Step 5",
            description: "Share and display your QR Codes and start transacting"
        },
    ]


    // toggle image
    const toggleImage = (item) => {
        if(item.image){
            setImageBanner(item.image)
        }
    }

    const InitailImage = () => {
        const initImage = howItWorks[0]
        setImageBanner(initImage.image)
    }



    InitailImageRef.current = InitailImage
    useEffect(() => {
        InitailImageRef.current()
        AOS.init({
            duration: 3000
        })

    }, [])

    return (
        <div className="how-it-works-container agent-page">
            <TitleHeader header={header}/>
            <div className="how-it-works-body">
                <Row className="show-grid">
                    <Col xs={12} sm={12} md={12} lg={7} xl={7}><ContentLeft  toggleImage={toggleImage} howItWorks={howItWorks}/></Col>
                    <Col xs={12} sm={12} md={12} lg={5} xl={5}><ContentRight imageBanner={imageBanner}/></Col>
                </Row>
            </div>
        </div>
    )
}



export default HowItWorks




const TitleHeader = ({header}) => {
    return (
        <div className="title-header">
            <h3>{header.title}</h3>
            <p>{header.text}</p>
        </div>
    )
}



const ContentLeft = ({howItWorks, toggleImage, contentRef}) => {
    return (
        <div className="howitworks-item-left-container">
           <ul>
                { howItWorks.map((item, index) => (<ContentItem key={index} toggleImage={toggleImage} number={index+1} item={item}/>))}
           </ul>
        </div>
    )
}



const ContentItem = ({ number, item, toggleImage}) => {
    return (
        <li data-aos={number % 2 === 0 ? 'slide-right' : 'fade-left'} onClick={() => toggleImage(item)}>
            <div className="number">
                <span>{number}</span>
            </div>
            <div className="content">
                {item.title ? (<h3>{item.title}</h3>) : null }
                { item.description ? (<p>{item.description}</p>) : null }
            </div>
        </li>
    )
}

const ContentRight = ({imageBanner}) => {
    return (
        <div data-aos="slide-left" className="howitworks-item-right-container">
            {
                imageBanner? (<img src={app_image(imageBanner)} alt="work-it-works"/>) : ''
            }
        </div>
    )
}





