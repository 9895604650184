import { useState } from 'react'
import {  logo } from '../../File'
import { NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
    faBars,
    faAngleRight,
    faAngleDown,
} from '@fortawesome/free-solid-svg-icons'




const SideNavigation = ({ pageCategories, sideNav, sections, scrollToSection, sideNavToggle}) => {
    return (
        <div className={`side-navigation ${sideNav && 'active'}`}>
            <div onClick={() => sideNavToggle(false)} className="dark-skin"></div>
            <div className="side-nav-container">
                <SideNavHeader  sideNavToggle={sideNavToggle}/>
                <SideNavLinks pageCategories={pageCategories} sections={sections} sideNavToggle={sideNavToggle} scrollToSection={scrollToSection}/>
            </div>
        </div>
    )
}




export default SideNavigation






const SideNavHeader = ({sideNavToggle}) => {
    return (
        <div className="side-nav-header">
            <div className="side-nav-img">
                <NavLink onClick={() => sideNavToggle(false)} to="/">
                    <img src={logo('6.jpg')} alt="side-nav"/>
                </NavLink>
            </div>
            <div className="side-nav-icon">
                <FontAwesomeIcon  onClick={() => sideNavToggle(false)} className="icon" icon={faBars} /> 
            </div>
        </div>
    )
}







const SideNavLinks = ({pageCategories, sections, sideNavToggle, scrollToSection}) => {
    const [dropDownState, setDropDownState] = useState(false)

    const toggleDropDown = (state=false) => {
        if(state === 'close'){
            setDropDownState(false)
            return sideNavToggle(false, 'scroll')
        }
        setDropDownState(state)
    }


    return (
        <div className="side-nav-links">
            <ul>
                <li>
                    <div className="nav-drop-down">
                        <div onClick={() => toggleDropDown(!dropDownState)} className="title">Personal <FontAwesomeIcon  className="icon" icon={dropDownState ? faAngleDown : faAngleRight} /></div>
                            <div className={`drop-down ${dropDownState ? 'active' : ''}`}>
                                <ul>
                                    {
                                        pageCategories && pageCategories.length > 0 ? (
                                            pageCategories.map((pageCategory, index) => (
                                                <li key={index} onClick={() => toggleDropDown('close')}>
                                                    <NavLink to={`/personal/detail?category=${pageCategory._id}`}>{pageCategory.category}</NavLink>
                                                </li>
                                            ))
                                        ) : null
                                    }
                                </ul>
                        </div>
                    </div>
                </li>
                <li onClick={() => sideNavToggle(false, 'scroll')}><NavLink to="/agent">Agent</NavLink></li>
                <li onClick={() => sideNavToggle(false, 'scroll')}><NavLink to="/company">Company</NavLink></li>
                <li onClick={() => sideNavToggle(false, 'scroll')}><NavLink to="/help">Pricing</NavLink></li>
                <li onClick={() => sideNavToggle(false, 'scroll')}><NavLink to="/blog">Blog</NavLink></li>
                <li onClick={() => sideNavToggle(false, 'scroll')}><NavLink to="/faq">FAQ</NavLink></li>
                <li onClick={() => sideNavToggle(false, 'scroll')}><NavLink to="/help">Help</NavLink></li>
                <li onClick={() => sideNavToggle(false, 'scroll')}><NavLink to="/contact-us">Contact Us</NavLink></li>
                <li onClick={() => sideNavToggle(false, 'scroll')}><NavLink to="/">Register</NavLink></li>
                <li onClick={() => sideNavToggle(false, 'scroll')}><NavLink to="/">Login</NavLink></li>
            </ul>
        </div>
    )
}


