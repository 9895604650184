
import { app_image } from '../../File'
import { NavLink } from 'react-router-dom'
import { useEffect } from 'react'
import AOS from 'aos'


const Content2 = () => {
    const contents = [
        {
            _id: '1',
            image: 'content-image-11.png',
            title: 'Our Fees are Simply the Best Rates',
            text: 'We put a lot of thought into giving you the best rates because we care about you. ',
            link: '/',
            button: 'See all our fees',
            position: 'left',
            is_featured: 1,
            updated_at: '',
            created_at: ''
        },
        {
            _id: '2',
            image: 'content-image-12.png',
            title: 'Need Izzy Help?',
            text: 'We got you. Youre the centre of our world so we are always available to attend to your needs. Here how you can contact us',
            link: '/',
            button: 'Get Help',
            position: 'right',
            is_featured: 1,
            updated_at: '',
            created_at: ''
        },
    ]

    useEffect(() => {
        AOS.init({
            duration: 2000
        })

    }, [])

    return (
        <div className="main-content-container-1">
            {
                contents.map((content, index) => (<ContentItem key={index} number={index+1} content={content}/>))
            }
        </div>
    )
}



export default Content2




const ContentItem = ({number, content}) => {
    return (
        <div data-aos={'zoom-out-up'} className={`content-item ${content.position === 'left' ? 'left' : 'right'}`}>
            <MobileImage content={content}/>
                {
                    content.position === 'left' ? (
                        <div className="inner-content-item left">
                           <ContentLeft content={content}/>
                            <ContentRight content={content}/>
                        </div>
                    ) : (
                        <div className="inner-content-item">
                            <ContentRight content={content}/>
                            <ContentLeft content={content}/>
                        </div>
                    )
                }
        </div>
    )
}



const ContentLeft = ({content}) => {
    return (
        <div className={`main-content-left`}>
          <div className="content">
            <div className="title-header">
                <h3>{content.title}</h3>
            </div>
            <div className="body">
                <p>{content.text}</p>
            </div>
            {
                content.link ? (
                    <div className="link">
                       <NavLink to={content.link}>{content.button}</NavLink>
                    </div>
                ) : null
            }
          </div>
        </div>
    )
}




const ContentRight = ({content}) => {
    return (
        <div className="main-content-right">
            <div className="img">
                <img src={app_image(content.image)} alt={content.image}/>
            </div>
        </div>
    )
}




const MobileImage = ({content}) => {
    return (
        <div className="main-content-mobile-img">
            <div className="img">
                <img src={app_image(content.image)} alt={content.image}/>
            </div>
        </div>
    )
}