import { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
    faCircle,
} from '@fortawesome/free-solid-svg-icons'
import { Chart as ChartJS } from "chart.js/auto";
import { Line } from "react-chartjs-2"
import Carousel from "react-multi-carousel";










const ContentLeft = () => {
   
   const responsive = {
        superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 4
        },
        desktop: {
        breakpoint: { max: 3000, min: 993 },
        items: 4
        },
        tablet: {
        breakpoint: { max: 992, min: 568 },
        items: 3
        },
        mobile: {
        breakpoint: { max: 567, min: 0 },
        items: 2
        }
    };


  

    return (
        <div className="metrics-left-body">
            <div className="title-header">
                <h3>Website Customer Metrics</h3>
            </div>
            <div className="slider-frame">
                <Carousel responsive={responsive}>
                    <SliderContent  total={2000}  title="Total Users" color=""/>
                    <SliderContent  total={3000}  title="Bounce Rate" color=""/>
                    <SliderContent  total={1000} title="Active Users" color="rgb(38, 192, 38)"/>
                    <SliderContent  total={4000}  title="Inactive Users" color="purple"/>
                </Carousel>
            </div>
            <div className="chart-frame">
            <Line
                data={{
                    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                    datasets: [
                        {
                            label: 'Data',
                            data: [65, 59, 80, 81, 56, 55, 80, 50, 40, 30, 60, 70],
                            fill: false,
                            borderColor: 'purple',
                            tension: 0.1
                        },
                        {
                            label: 'User',
                            data: [20, 48, 30, 40, 80, 30, 20, 30, 35, 45, 50, 30],
                            fill: false,
                            borderColor: 'orange',
                            tension: 0.1
                        }
                    ]
                }}
            />
            </div>
        </div>
    )
}


export default ContentLeft





const SliderContent = ({total, title, color}) => {
    let initialCount = parseInt(total)
    const [countTotal, setCountTotal] = useState(initialCount);

    const counter = () => {
        let start = 50
        let duration = 100
        let value = initialCount > start ? (initialCount - start) : 0
        let count = setInterval(() => {
            value += 1
            setCountTotal(value)
            if(value >= (initialCount)){
                let amount = total.toLocaleString()
                setCountTotal(amount)
                clearInterval(count)
            }
        }, duration)
   }

   useEffect(() => {
        counter()
   }, [])

    return (
        <div className="content-left-item orange">
            <div className="total">
                <h3>{countTotal}</h3>
            </div>
            <div className="title-header">
                <h3>
                    {title.length ? (title) : null }
                    {
                        color.length ? (
                            <span>
                                <FontAwesomeIcon style={{ color: color }} className="icon" icon={faCircle} />
                            </span>
                        ) : null
                    }
                </h3>
            </div>
        </div>
    )
}