
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
    faTimes,
} from '@fortawesome/free-solid-svg-icons'
import Axios from 'axios'
import { url } from '../../File'
import { useDispatch } from 'react-redux'
import { deletePageCategories } from '../redux/pageCategorySlice'






const DeletePageCategory = ({deletePageCategoryModal, toggleDeletePageCategoryModal, alertNotification}) => {
    const dispatch = useDispatch()

    const deletePageCategory = () => {
        Axios.post(url('/api/admin/delete-page-category'), {id: deletePageCategoryModal.id}).then((response) => {
            const data = response.data
            if(data.status === 'ok'){
                dispatch(deletePageCategories(data.deleteItem))
                return toggleDeletePageCategoryModal(false, false)
            }else{
                return  alertNotification('error', data.error)
            }
        })
    }

    return (
        <div className={`action-modal ${deletePageCategoryModal.modal ? 'active' : ''}`}>
            <div className="dark-skin"></div>
            <div className="modal-content">
                <div className="modal-content-body">
                    <div className="cancel-button">
                        <FontAwesomeIcon onClick={() => toggleDeletePageCategoryModal(false, false)} className="icon" icon={faTimes} />
                    </div>
                    <div className="title-header">
                        <h3>Delete Category</h3>
                        <p>Do you wish to delete this category?</p>
                    </div>
                    <div className="button">
                        <button onClick={() => deletePageCategory()} type="button">Delete</button>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default DeletePageCategory