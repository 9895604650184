
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
    faTimes,
} from '@fortawesome/free-solid-svg-icons'





const DeactivateModal = ({deactvateModal, toggleModal}) => {
    return (
        <div className={`action-modal ${deactvateModal.modal ? 'active' : ''}`}>
            <div className="dark-skin"></div>
            <div className="modal-content">
                <div className="modal-content-body">
                    <div className="cancel-button">
                        <FontAwesomeIcon onClick={() => toggleModal(false, false)} className="icon" icon={faTimes} />
                    </div>
                    <div className="title-header">
                        <h3>Deactivate User</h3>
                        <p>Do you wish to deactivate Jane's account?</p>
                    </div>
                    <div className="button">
                        <button type="button">Deactivate</button>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default DeactivateModal