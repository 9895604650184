import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'aos/dist/aos.css'
import ClientPanel from './client/ClientPanel'
import AdminPanel from './admin/AdminPanel'
import { page } from './File'




const App = () => {
    return (
        <div className="">
            {
                page('admin') ? (<AdminPanel/>) : (<ClientPanel/>)
            }
        </div>
    )
}


export default App;