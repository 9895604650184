import {  logo, url } from '../../File'
import { useEffect, useRef } from 'react'
import Axios from 'axios'




const Preloader = ({setIsAppReady}) => {
    const serverReaderRef = useRef(null)

    const CheckIFServerIsReady = () => {
        Axios.get(url('/api/client/check-if-server-is-ready')).then((response) => {
            const data = response.data
            if(data.status === 'ok'){
                removerPreloader()
            }
        }).catch(error => {
            console.log(error)
        })
    }

    // remove preloader
    const removerPreloader = () => {
        setTimeout(() => {
            setIsAppReady(true)
        }, 500)
    }


    serverReaderRef.current = CheckIFServerIsReady
    
    useEffect(() => {
        serverReaderRef.current()
    }, [])


    return (
        <div className="preloader">
            <img src={logo('logo-3.png')} className="loader-image" alt="preloader-logo"/>
        </div>
    )
}

export default Preloader