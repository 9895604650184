
import { useEffect } from 'react'
import TopBar from './TopBar'
import TransactionBody from './TransactionBody'
import MobileTable from './MobileTable'



const Transaction = ({toggleFloatProfile}) => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className="general-table-container">
            <TopBar/>
            <TransactionBody toggleFloatProfile={toggleFloatProfile}/>
            <MobileTable toggleFloatProfile={toggleFloatProfile}/>
        </div>
    )
}


export default Transaction





