import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { app_image } from '../../File'
import { useEffect } from 'react'
import AOS from 'aos'






const OurValues = () => {
    const header = {
        title: 'How to Become an Izzy Agent',
        text: 'Step by step on how to become Izzy agent',
    }

    const reasons = [
        {
            icon: 'icon-business.png',
            title: 'Ease',
            text: 'We believe in always making things easy for our team and for our customers. This is why we exist, to make the regular and complex, easier and better.',
        },
        {
            icon: 'icon-moneys.png',
            title: 'Customer Focus',
            text: 'We keep our Izzy tribers first and always because its for them that we build and they deserve the best and positive experiences',
        },
        {
            icon: 'icon-shield-security.png',
            title: 'Expertise',
            text: 'We make sure that everyone on the team has the right skills and knowledge needed to keep building world class solutions',
        },
        {
            icon: 'icon-business.png',
            title: 'honesty',
            text: 'We understand that honesty is a virtue and we maintain a culture of openness,  truth and the safety to share and ask for help',
        },
        {
            icon: 'icon-moneys.png',
            title: 'Curiosity',
            text: "We maintain the childlike wonder that keeps us asking the 'hows' that lead us to truly experiment and innovate",
        },
        {
            icon: 'icon-24-support.png',
            title: 'Growth',
            text: "We push ourselves to never stay stuck in a level. We evolve daily in our skills and ability in providing the best for our tribe",
        },
        {
            icon: 'icon-business.png',
            title: 'Quality',
            text: "We don't believe in half measures, or going too fast and forgetting how good. To us, every piece of work must be done with absolute finesse.",
        },
        {
            icon: 'icon-moneys.png',
            title: 'Love & Respect',
            text: "We treat each other with the kind regard we expect our tribe of users to experience. We believe in sharing laughter and showing love in little and big ways.",
        },
    ]


    useEffect(() => {
        AOS.init({ duration: 2000 })

    }, [])

  return (
    <div className="reasons-contaniner">
       <TitleHeader header={header}/>
       <ReasonBody reasons={reasons}/>
    </div>
  )
}

export default OurValues











const TitleHeader = ({header}) => {
    return (
        <div className="page-title-header text-center">
            <h3>{header.title}</h3>
            <p>{header.text}</p>
        </div>
    )
}




const ReasonBody = ({reasons}) => {
    return (
        <div className="content-card-container">
            <Row className="show-grid">
                {
                    reasons.map((content, index) => (<ContentItem key={index} content={content}/>))
                }
            </Row>
        </div>
    )
}



const ContentItem = ({content}) => {
    return (
        <Col xs={12} sm={12} md={6} lg={4} xl={4}>
            <div data-aos={'zoom-in'} className="content-card-items">
                <ul>
                        <li className="icon">
                            <img src={app_image(content.icon)} alt={content.icon}/>
                        </li>
                        <li className="title">{content.title}</li>
                        <li className="text">{content.text}</li>
                </ul>
            </div>
        </Col>
    )
}
