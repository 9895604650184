import { useEffect } from 'react'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { app_image } from '../../File'
import { NavLink } from 'react-router-dom'
import AOS from 'aos'



const ContentLinks = () => {

    const header = {
        title: 'How does POS with QR Codes Work?',
        text: 'Step by step on how QR code work',
    }
    

    const contentLinks = [
        {
            title: "QR Transfers",
            text: "With your smartphone, scan a Payizzy user's QR code to transfer cash to their bank accounts instantly",
            icon: "send-money-icon.png",
        },
        {
            title: "QR Withdrawals",
            text: "You can print out or display your unique QR Code with your unique details   Get transfers from other Payizzy users immediately they scan your QR code and validate payment",
            icon: "receive-payments-icon.png",
        },
        {
            title: "Instant Transfer",
            text: "Get immediate credit and debit alerts with absolutely no delays",
            icon: "request-payments-icon.png",
        },
        {
            title: "No Failed Transactions",
            text: "With our advanced payment algorithm designed to ensure 100% successful transactions, you don't need to worry about failed transactions.",
            icon: "pay-in-chat-icon.png",
        },
    ]

    useEffect(() => {
        AOS.init({ duration: 2000 })

    }, [])

    return (
        <div className="content-banner-container">
            <TitleHeader header={header}/>
           <Row className="show-grid">
                { contentLinks.map((content, index) => (<ContentItem key={index} number={index+1} content={content}/>)) }
            </Row>
        </div>
    )
}



export default ContentLinks









const TitleHeader = ({header}) => {
    return(
        <div className="title-header">
            <h3>{header.title}</h3>
            <p>{header.text}</p>
        </div>
    )
}






const ContentItem = ({ number, content }) => {
    return (
        <Col data-aos={'flip-up'} xs={12} sm={12} md={6} lg={4} xl={3}>
            <div className="content-banner-item">
                <NavLink to="/">
                    <ul>
                    { 
                        content.icon ? (
                            <li>
                                <img src={app_image(content.icon)} alt="content-banner-img"/>
                            </li>
                        ) : null
                    }
                        { content.title ? ( <li className="title">{content.title}</li>) : null }
                        { content.text ? ( <li className="text">{content.text}</li>) : null }
                    </ul>
                </NavLink>
            </div>
        </Col>
    )
}