
import  { preloaderImg } from '../../File'




const Preloader = () => {
    return (
        <div className="preloader-container">
            <div className="inner-preloader">
                <div className="preloader-body">
                    <div className="img">
                        <img src={preloaderImg('full-logo.png')} alt="admin-preloader"/>
                    </div>
                    <div className="loading">
                        <div className="spinner"></div> Loading...
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Preloader