

/* ******************************************************************************** */
//                   STATIC DATA                             
// **********************************************************************************/



//  container two items
const bannerTwoStatic = [
    {
      header: 'Global Solution',
      image: '36.png',
      text: 'We follow the developing technologies  and integrate them in accordance with every infrastructure.'
    },
    {
      header: 'Fraud Protection',
      image: '30.png',
      text: 'We follow the developing technologies  and integrate them in accordance with every infrastructure.'
    },
    {
      header: 'Admin Tool',
      image: '36.png',
      text: 'We follow the developing technologies  and integrate them in accordance with every infrastructure.'
    }
  ]
  
  
  // faq items
  // const faqItemsStatic_x = [
  //   {
  //     title: 'How do i scan',
  //     text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vitae neque faucibus dui viverra ornare lobortis elementum sit. Risus placerat leo porttitor interdum cras sit. Pretium fusce in vitae sodales ipsum diam. Lectus aliquam imperdiet convallis luctus euismod volutpat sed amet.Feugiat vitae, purus id mattis eu risus habitasse. Eget rhoncus ultrices turpis tortor, amet, enim. Pulvinar aliquet praesent ornare turpis ut. Sed id morbi est eu donec. sed amet.Feugiat vitae, purus id mattis eu risus habitasse. Eget rhoncus ultrices turpis tortor, amet, enim. Pulvinar aliquet praesent ornare turpis ut. Sed id morbi est eu donec.'
  //   },
  //   {
  //     title: 'How do I fix an barcode error',
  //     text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vitae neque faucibus dui viverra ornare lobortis elementum sit. Risus placerat leo porttitor interdum cras sit. Pretium fusce in vitae sodales ipsum diam. Lectus aliquam imperdiet convallis luctus euismod volutpat sed amet.Feugiat vitae, purus id mattis eu risus habitasse. Eget rhoncus ultrices turpis tortor, amet, enim. Pulvinar aliquet praesent ornare turpis ut. Sed id morbi est eu donec. sed amet.Feugiat vitae, purus id mattis eu risus habitasse. Eget rhoncus ultrices turpis tortor, amet, enim. Pulvinar aliquet praesent ornare turpis ut. Sed id morbi est eu donec.'
  //   },
  //   {
  //     title: 'How do i buy items',
  //     text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vitae neque faucibus dui viverra ornare lobortis elementum sit. Risus placerat leo porttitor interdum cras sit. Pretium fusce in vitae sodales ipsum diam. Lectus aliquam imperdiet convallis luctus euismod volutpat sed amet.Feugiat vitae, purus id mattis eu risus habitasse. Eget rhoncus ultrices turpis tortor, amet, enim. Pulvinar aliquet praesent ornare turpis ut. Sed id morbi est eu donec. sed amet.Feugiat vitae, purus id mattis eu risus habitasse. Eget rhoncus ultrices turpis tortor, amet, enim. Pulvinar aliquet praesent ornare turpis ut. Sed id morbi est eu donec.'
  //   },
  //   {
  //     title: 'How do i scan',
  //     text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vitae neque faucibus dui viverra ornare lobortis elementum sit. Risus placerat leo porttitor interdum cras sit. Pretium fusce in vitae sodales ipsum diam. Lectus aliquam imperdiet convallis luctus euismod volutpat sed amet.Feugiat vitae, purus id mattis eu risus habitasse. Eget rhoncus ultrices turpis tortor, amet, enim. Pulvinar aliquet praesent ornare turpis ut. Sed id morbi est eu donec. sed amet.Feugiat vitae, purus id mattis eu risus habitasse. Eget rhoncus ultrices turpis tortor, amet, enim. Pulvinar aliquet praesent ornare turpis ut. Sed id morbi est eu donec.'
  //   },
  //   {
  //     title: 'How do I fix an barcode error',
  //     text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vitae neque faucibus dui viverra ornare lobortis elementum sit. Risus placerat leo porttitor interdum cras sit. Pretium fusce in vitae sodales ipsum diam. Lectus aliquam imperdiet convallis luctus euismod volutpat sed amet.Feugiat vitae, purus id mattis eu risus habitasse. Eget rhoncus ultrices turpis tortor, amet, enim. Pulvinar aliquet praesent ornare turpis ut. Sed id morbi est eu donec. sed amet.Feugiat vitae, purus id mattis eu risus habitasse. Eget rhoncus ultrices turpis tortor, amet, enim. Pulvinar aliquet praesent ornare turpis ut. Sed id morbi est eu donec.'
  //   }
  // ]
  


// ************************************************
//              FREQUENTLY ASKED QUESTIONS
// ************************************************
  const faqItemsStatic = [
    {
      title: 'What New',
      question: [
        {
          title: 'How do i scan',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vitae neque faucibus dui viverra ornare lobortis elementum sit. Risus placerat leo porttitor interdum cras sit. Pretium fusce in vitae sodales ipsum diam. Lectus aliquam imperdiet convallis luctus euismod volutpat sed amet.Feugiat vitae, purus id mattis eu risus habitasse. Eget rhoncus ultrices turpis tortor, amet, enim. Pulvinar aliquet praesent ornare turpis ut. Sed id morbi est eu donec. sed amet.Feugiat vitae, purus id mattis eu risus habitasse. Eget rhoncus ultrices turpis tortor, amet, enim. Pulvinar aliquet praesent ornare turpis ut. Sed id morbi est eu donec.'
        },
        {
          title: 'How do I fix an barcode error',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vitae neque faucibus dui viverra ornare lobortis elementum sit. Risus placerat leo porttitor interdum cras sit. Pretium fusce in vitae sodales ipsum diam. Lectus aliquam imperdiet convallis luctus euismod volutpat sed amet.Feugiat vitae, purus id mattis eu risus habitasse. Eget rhoncus ultrices turpis tortor, amet, enim. Pulvinar aliquet praesent ornare turpis ut. Sed id morbi est eu donec. sed amet.Feugiat vitae, purus id mattis eu risus habitasse. Eget rhoncus ultrices turpis tortor, amet, enim. Pulvinar aliquet praesent ornare turpis ut. Sed id morbi est eu donec.'
        },
        {
          title: 'How do i buy items',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vitae neque faucibus dui viverra ornare lobortis elementum sit. Risus placerat leo porttitor interdum cras sit. Pretium fusce in vitae sodales ipsum diam. Lectus aliquam imperdiet convallis luctus euismod volutpat sed amet.Feugiat vitae, purus id mattis eu risus habitasse. Eget rhoncus ultrices turpis tortor, amet, enim. Pulvinar aliquet praesent ornare turpis ut. Sed id morbi est eu donec. sed amet.Feugiat vitae, purus id mattis eu risus habitasse. Eget rhoncus ultrices turpis tortor, amet, enim. Pulvinar aliquet praesent ornare turpis ut. Sed id morbi est eu donec.'
        },
        {
          title: 'How do i scan',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vitae neque faucibus dui viverra ornare lobortis elementum sit. Risus placerat leo porttitor interdum cras sit. Pretium fusce in vitae sodales ipsum diam. Lectus aliquam imperdiet convallis luctus euismod volutpat sed amet.Feugiat vitae, purus id mattis eu risus habitasse. Eget rhoncus ultrices turpis tortor, amet, enim. Pulvinar aliquet praesent ornare turpis ut. Sed id morbi est eu donec. sed amet.Feugiat vitae, purus id mattis eu risus habitasse. Eget rhoncus ultrices turpis tortor, amet, enim. Pulvinar aliquet praesent ornare turpis ut. Sed id morbi est eu donec.'
        },
        {
          title: 'How do I fix an barcode error',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vitae neque faucibus dui viverra ornare lobortis elementum sit. Risus placerat leo porttitor interdum cras sit. Pretium fusce in vitae sodales ipsum diam. Lectus aliquam imperdiet convallis luctus euismod volutpat sed amet.Feugiat vitae, purus id mattis eu risus habitasse. Eget rhoncus ultrices turpis tortor, amet, enim. Pulvinar aliquet praesent ornare turpis ut. Sed id morbi est eu donec. sed amet.Feugiat vitae, purus id mattis eu risus habitasse. Eget rhoncus ultrices turpis tortor, amet, enim. Pulvinar aliquet praesent ornare turpis ut. Sed id morbi est eu donec.'
        }
      ]
    },
    {
      title: 'General',
      question: [
        {
          title: 'How do General work',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vitae neque faucibus dui viverra ornare lobortis elementum sit. Risus placerat leo porttitor interdum cras sit. Pretium fusce in vitae sodales ipsum diam. Lectus aliquam imperdiet convallis luctus euismod volutpat sed amet.Feugiat vitae, purus id mattis eu risus habitasse. Eget rhoncus ultrices turpis tortor, amet, enim. Pulvinar aliquet praesent ornare turpis ut. Sed id morbi est eu donec. sed amet.Feugiat vitae, purus id mattis eu risus habitasse. Eget rhoncus ultrices turpis tortor, amet, enim. Pulvinar aliquet praesent ornare turpis ut. Sed id morbi est eu donec.'
        },
        {
          title: 'How do I fix an general error',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vitae neque faucibus dui viverra ornare lobortis elementum sit. Risus placerat leo porttitor interdum cras sit. Pretium fusce in vitae sodales ipsum diam. Lectus aliquam imperdiet convallis luctus euismod volutpat sed amet.Feugiat vitae, purus id mattis eu risus habitasse. Eget rhoncus ultrices turpis tortor, amet, enim. Pulvinar aliquet praesent ornare turpis ut. Sed id morbi est eu donec. sed amet.Feugiat vitae, purus id mattis eu risus habitasse. Eget rhoncus ultrices turpis tortor, amet, enim. Pulvinar aliquet praesent ornare turpis ut. Sed id morbi est eu donec.'
        },
        {
          title: 'How do i buy general items',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vitae neque faucibus dui viverra ornare lobortis elementum sit. Risus placerat leo porttitor interdum cras sit. Pretium fusce in vitae sodales ipsum diam. Lectus aliquam imperdiet convallis luctus euismod volutpat sed amet.Feugiat vitae, purus id mattis eu risus habitasse. Eget rhoncus ultrices turpis tortor, amet, enim. Pulvinar aliquet praesent ornare turpis ut. Sed id morbi est eu donec. sed amet.Feugiat vitae, purus id mattis eu risus habitasse. Eget rhoncus ultrices turpis tortor, amet, enim. Pulvinar aliquet praesent ornare turpis ut. Sed id morbi est eu donec.'
        },
        {
          title: 'How do i scan general',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vitae neque faucibus dui viverra ornare lobortis elementum sit. Risus placerat leo porttitor interdum cras sit. Pretium fusce in vitae sodales ipsum diam. Lectus aliquam imperdiet convallis luctus euismod volutpat sed amet.Feugiat vitae, purus id mattis eu risus habitasse. Eget rhoncus ultrices turpis tortor, amet, enim. Pulvinar aliquet praesent ornare turpis ut. Sed id morbi est eu donec. sed amet.Feugiat vitae, purus id mattis eu risus habitasse. Eget rhoncus ultrices turpis tortor, amet, enim. Pulvinar aliquet praesent ornare turpis ut. Sed id morbi est eu donec.'
        },
        {
          title: 'How do I fix an general error',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vitae neque faucibus dui viverra ornare lobortis elementum sit. Risus placerat leo porttitor interdum cras sit. Pretium fusce in vitae sodales ipsum diam. Lectus aliquam imperdiet convallis luctus euismod volutpat sed amet.Feugiat vitae, purus id mattis eu risus habitasse. Eget rhoncus ultrices turpis tortor, amet, enim. Pulvinar aliquet praesent ornare turpis ut. Sed id morbi est eu donec. sed amet.Feugiat vitae, purus id mattis eu risus habitasse. Eget rhoncus ultrices turpis tortor, amet, enim. Pulvinar aliquet praesent ornare turpis ut. Sed id morbi est eu donec.'
        }
      ]
    },
    {
      title: 'Barcode & Scanning',
      question: [
        {
          title: 'How do i scan barcode',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vitae neque faucibus dui viverra ornare lobortis elementum sit. Risus placerat leo porttitor interdum cras sit. Pretium fusce in vitae sodales ipsum diam. Lectus aliquam imperdiet convallis luctus euismod volutpat sed amet.Feugiat vitae, purus id mattis eu risus habitasse. Eget rhoncus ultrices turpis tortor, amet, enim. Pulvinar aliquet praesent ornare turpis ut. Sed id morbi est eu donec. sed amet.Feugiat vitae, purus id mattis eu risus habitasse. Eget rhoncus ultrices turpis tortor, amet, enim. Pulvinar aliquet praesent ornare turpis ut. Sed id morbi est eu donec.'
        },
        {
          title: 'How do I fix an barcode error',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vitae neque faucibus dui viverra ornare lobortis elementum sit. Risus placerat leo porttitor interdum cras sit. Pretium fusce in vitae sodales ipsum diam. Lectus aliquam imperdiet convallis luctus euismod volutpat sed amet.Feugiat vitae, purus id mattis eu risus habitasse. Eget rhoncus ultrices turpis tortor, amet, enim. Pulvinar aliquet praesent ornare turpis ut. Sed id morbi est eu donec. sed amet.Feugiat vitae, purus id mattis eu risus habitasse. Eget rhoncus ultrices turpis tortor, amet, enim. Pulvinar aliquet praesent ornare turpis ut. Sed id morbi est eu donec.'
        },
        {
          title: 'How do i buy barcode',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vitae neque faucibus dui viverra ornare lobortis elementum sit. Risus placerat leo porttitor interdum cras sit. Pretium fusce in vitae sodales ipsum diam. Lectus aliquam imperdiet convallis luctus euismod volutpat sed amet.Feugiat vitae, purus id mattis eu risus habitasse. Eget rhoncus ultrices turpis tortor, amet, enim. Pulvinar aliquet praesent ornare turpis ut. Sed id morbi est eu donec. sed amet.Feugiat vitae, purus id mattis eu risus habitasse. Eget rhoncus ultrices turpis tortor, amet, enim. Pulvinar aliquet praesent ornare turpis ut. Sed id morbi est eu donec.'
        },
        {
          title: 'How do i scan barcode',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vitae neque faucibus dui viverra ornare lobortis elementum sit. Risus placerat leo porttitor interdum cras sit. Pretium fusce in vitae sodales ipsum diam. Lectus aliquam imperdiet convallis luctus euismod volutpat sed amet.Feugiat vitae, purus id mattis eu risus habitasse. Eget rhoncus ultrices turpis tortor, amet, enim. Pulvinar aliquet praesent ornare turpis ut. Sed id morbi est eu donec. sed amet.Feugiat vitae, purus id mattis eu risus habitasse. Eget rhoncus ultrices turpis tortor, amet, enim. Pulvinar aliquet praesent ornare turpis ut. Sed id morbi est eu donec.'
        },
        {
          title: 'How do I fix an barcode error',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vitae neque faucibus dui viverra ornare lobortis elementum sit. Risus placerat leo porttitor interdum cras sit. Pretium fusce in vitae sodales ipsum diam. Lectus aliquam imperdiet convallis luctus euismod volutpat sed amet.Feugiat vitae, purus id mattis eu risus habitasse. Eget rhoncus ultrices turpis tortor, amet, enim. Pulvinar aliquet praesent ornare turpis ut. Sed id morbi est eu donec. sed amet.Feugiat vitae, purus id mattis eu risus habitasse. Eget rhoncus ultrices turpis tortor, amet, enim. Pulvinar aliquet praesent ornare turpis ut. Sed id morbi est eu donec.'
        }
      ]
    },
    {
      title: 'Checkout & Payments',
      question: [
        {
          title: 'How do payment work',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vitae neque faucibus dui viverra ornare lobortis elementum sit. Risus placerat leo porttitor interdum cras sit. Pretium fusce in vitae sodales ipsum diam. Lectus aliquam imperdiet convallis luctus euismod volutpat sed amet.Feugiat vitae, purus id mattis eu risus habitasse. Eget rhoncus ultrices turpis tortor, amet, enim. Pulvinar aliquet praesent ornare turpis ut. Sed id morbi est eu donec. sed amet.Feugiat vitae, purus id mattis eu risus habitasse. Eget rhoncus ultrices turpis tortor, amet, enim. Pulvinar aliquet praesent ornare turpis ut. Sed id morbi est eu donec.'
        },
        {
          title: 'How do I fix payment error',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vitae neque faucibus dui viverra ornare lobortis elementum sit. Risus placerat leo porttitor interdum cras sit. Pretium fusce in vitae sodales ipsum diam. Lectus aliquam imperdiet convallis luctus euismod volutpat sed amet.Feugiat vitae, purus id mattis eu risus habitasse. Eget rhoncus ultrices turpis tortor, amet, enim. Pulvinar aliquet praesent ornare turpis ut. Sed id morbi est eu donec. sed amet.Feugiat vitae, purus id mattis eu risus habitasse. Eget rhoncus ultrices turpis tortor, amet, enim. Pulvinar aliquet praesent ornare turpis ut. Sed id morbi est eu donec.'
        },
        {
          title: 'How do items payment work',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vitae neque faucibus dui viverra ornare lobortis elementum sit. Risus placerat leo porttitor interdum cras sit. Pretium fusce in vitae sodales ipsum diam. Lectus aliquam imperdiet convallis luctus euismod volutpat sed amet.Feugiat vitae, purus id mattis eu risus habitasse. Eget rhoncus ultrices turpis tortor, amet, enim. Pulvinar aliquet praesent ornare turpis ut. Sed id morbi est eu donec. sed amet.Feugiat vitae, purus id mattis eu risus habitasse. Eget rhoncus ultrices turpis tortor, amet, enim. Pulvinar aliquet praesent ornare turpis ut. Sed id morbi est eu donec.'
        },
        {
          title: 'How do i scan general',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vitae neque faucibus dui viverra ornare lobortis elementum sit. Risus placerat leo porttitor interdum cras sit. Pretium fusce in vitae sodales ipsum diam. Lectus aliquam imperdiet convallis luctus euismod volutpat sed amet.Feugiat vitae, purus id mattis eu risus habitasse. Eget rhoncus ultrices turpis tortor, amet, enim. Pulvinar aliquet praesent ornare turpis ut. Sed id morbi est eu donec. sed amet.Feugiat vitae, purus id mattis eu risus habitasse. Eget rhoncus ultrices turpis tortor, amet, enim. Pulvinar aliquet praesent ornare turpis ut. Sed id morbi est eu donec.'
        },
        {
          title: 'How do I fix an general error',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vitae neque faucibus dui viverra ornare lobortis elementum sit. Risus placerat leo porttitor interdum cras sit. Pretium fusce in vitae sodales ipsum diam. Lectus aliquam imperdiet convallis luctus euismod volutpat sed amet.Feugiat vitae, purus id mattis eu risus habitasse. Eget rhoncus ultrices turpis tortor, amet, enim. Pulvinar aliquet praesent ornare turpis ut. Sed id morbi est eu donec. sed amet.Feugiat vitae, purus id mattis eu risus habitasse. Eget rhoncus ultrices turpis tortor, amet, enim. Pulvinar aliquet praesent ornare turpis ut. Sed id morbi est eu donec.'
        }
      ]
    },
    {
      title: 'Client',
      question: [
        {
          title: 'How do i client scan',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vitae neque faucibus dui viverra ornare lobortis elementum sit. Risus placerat leo porttitor interdum cras sit. Pretium fusce in vitae sodales ipsum diam. Lectus aliquam imperdiet convallis luctus euismod volutpat sed amet.Feugiat vitae, purus id mattis eu risus habitasse. Eget rhoncus ultrices turpis tortor, amet, enim. Pulvinar aliquet praesent ornare turpis ut. Sed id morbi est eu donec. sed amet.Feugiat vitae, purus id mattis eu risus habitasse. Eget rhoncus ultrices turpis tortor, amet, enim. Pulvinar aliquet praesent ornare turpis ut. Sed id morbi est eu donec.'
        },
        {
          title: 'How do I fix an barcode error',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vitae neque faucibus dui viverra ornare lobortis elementum sit. Risus placerat leo porttitor interdum cras sit. Pretium fusce in vitae sodales ipsum diam. Lectus aliquam imperdiet convallis luctus euismod volutpat sed amet.Feugiat vitae, purus id mattis eu risus habitasse. Eget rhoncus ultrices turpis tortor, amet, enim. Pulvinar aliquet praesent ornare turpis ut. Sed id morbi est eu donec. sed amet.Feugiat vitae, purus id mattis eu risus habitasse. Eget rhoncus ultrices turpis tortor, amet, enim. Pulvinar aliquet praesent ornare turpis ut. Sed id morbi est eu donec.'
        },
        {
          title: 'How do i buy items',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vitae neque faucibus dui viverra ornare lobortis elementum sit. Risus placerat leo porttitor interdum cras sit. Pretium fusce in vitae sodales ipsum diam. Lectus aliquam imperdiet convallis luctus euismod volutpat sed amet.Feugiat vitae, purus id mattis eu risus habitasse. Eget rhoncus ultrices turpis tortor, amet, enim. Pulvinar aliquet praesent ornare turpis ut. Sed id morbi est eu donec. sed amet.Feugiat vitae, purus id mattis eu risus habitasse. Eget rhoncus ultrices turpis tortor, amet, enim. Pulvinar aliquet praesent ornare turpis ut. Sed id morbi est eu donec.'
        },
        {
          title: 'How do i scan',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vitae neque faucibus dui viverra ornare lobortis elementum sit. Risus placerat leo porttitor interdum cras sit. Pretium fusce in vitae sodales ipsum diam. Lectus aliquam imperdiet convallis luctus euismod volutpat sed amet.Feugiat vitae, purus id mattis eu risus habitasse. Eget rhoncus ultrices turpis tortor, amet, enim. Pulvinar aliquet praesent ornare turpis ut. Sed id morbi est eu donec. sed amet.Feugiat vitae, purus id mattis eu risus habitasse. Eget rhoncus ultrices turpis tortor, amet, enim. Pulvinar aliquet praesent ornare turpis ut. Sed id morbi est eu donec.'
        },
        {
          title: 'How do I fix an barcode error',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vitae neque faucibus dui viverra ornare lobortis elementum sit. Risus placerat leo porttitor interdum cras sit. Pretium fusce in vitae sodales ipsum diam. Lectus aliquam imperdiet convallis luctus euismod volutpat sed amet.Feugiat vitae, purus id mattis eu risus habitasse. Eget rhoncus ultrices turpis tortor, amet, enim. Pulvinar aliquet praesent ornare turpis ut. Sed id morbi est eu donec. sed amet.Feugiat vitae, purus id mattis eu risus habitasse. Eget rhoncus ultrices turpis tortor, amet, enim. Pulvinar aliquet praesent ornare turpis ut. Sed id morbi est eu donec.'
        }
      ]
    },
    {
      title: 'Boost',
      question: [
        {
          title: 'How do Boost work',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vitae neque faucibus dui viverra ornare lobortis elementum sit. Risus placerat leo porttitor interdum cras sit. Pretium fusce in vitae sodales ipsum diam. Lectus aliquam imperdiet convallis luctus euismod volutpat sed amet.Feugiat vitae, purus id mattis eu risus habitasse. Eget rhoncus ultrices turpis tortor, amet, enim. Pulvinar aliquet praesent ornare turpis ut. Sed id morbi est eu donec. sed amet.Feugiat vitae, purus id mattis eu risus habitasse. Eget rhoncus ultrices turpis tortor, amet, enim. Pulvinar aliquet praesent ornare turpis ut. Sed id morbi est eu donec.'
        },
        {
          title: 'How do I fix an general error',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vitae neque faucibus dui viverra ornare lobortis elementum sit. Risus placerat leo porttitor interdum cras sit. Pretium fusce in vitae sodales ipsum diam. Lectus aliquam imperdiet convallis luctus euismod volutpat sed amet.Feugiat vitae, purus id mattis eu risus habitasse. Eget rhoncus ultrices turpis tortor, amet, enim. Pulvinar aliquet praesent ornare turpis ut. Sed id morbi est eu donec. sed amet.Feugiat vitae, purus id mattis eu risus habitasse. Eget rhoncus ultrices turpis tortor, amet, enim. Pulvinar aliquet praesent ornare turpis ut. Sed id morbi est eu donec.'
        },
        {
          title: 'How do i buy general items',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vitae neque faucibus dui viverra ornare lobortis elementum sit. Risus placerat leo porttitor interdum cras sit. Pretium fusce in vitae sodales ipsum diam. Lectus aliquam imperdiet convallis luctus euismod volutpat sed amet.Feugiat vitae, purus id mattis eu risus habitasse. Eget rhoncus ultrices turpis tortor, amet, enim. Pulvinar aliquet praesent ornare turpis ut. Sed id morbi est eu donec. sed amet.Feugiat vitae, purus id mattis eu risus habitasse. Eget rhoncus ultrices turpis tortor, amet, enim. Pulvinar aliquet praesent ornare turpis ut. Sed id morbi est eu donec.'
        },
        {
          title: 'How do i Boost work',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vitae neque faucibus dui viverra ornare lobortis elementum sit. Risus placerat leo porttitor interdum cras sit. Pretium fusce in vitae sodales ipsum diam. Lectus aliquam imperdiet convallis luctus euismod volutpat sed amet.Feugiat vitae, purus id mattis eu risus habitasse. Eget rhoncus ultrices turpis tortor, amet, enim. Pulvinar aliquet praesent ornare turpis ut. Sed id morbi est eu donec. sed amet.Feugiat vitae, purus id mattis eu risus habitasse. Eget rhoncus ultrices turpis tortor, amet, enim. Pulvinar aliquet praesent ornare turpis ut. Sed id morbi est eu donec.'
        },
        {
          title: 'How do I fix an general error',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vitae neque faucibus dui viverra ornare lobortis elementum sit. Risus placerat leo porttitor interdum cras sit. Pretium fusce in vitae sodales ipsum diam. Lectus aliquam imperdiet convallis luctus euismod volutpat sed amet.Feugiat vitae, purus id mattis eu risus habitasse. Eget rhoncus ultrices turpis tortor, amet, enim. Pulvinar aliquet praesent ornare turpis ut. Sed id morbi est eu donec. sed amet.Feugiat vitae, purus id mattis eu risus habitasse. Eget rhoncus ultrices turpis tortor, amet, enim. Pulvinar aliquet praesent ornare turpis ut. Sed id morbi est eu donec.'
        }
      ]
    },
    {
      title: 'Online booking',
      question: [
        {
          title: 'How do i online booking work',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vitae neque faucibus dui viverra ornare lobortis elementum sit. Risus placerat leo porttitor interdum cras sit. Pretium fusce in vitae sodales ipsum diam. Lectus aliquam imperdiet convallis luctus euismod volutpat sed amet.Feugiat vitae, purus id mattis eu risus habitasse. Eget rhoncus ultrices turpis tortor, amet, enim. Pulvinar aliquet praesent ornare turpis ut. Sed id morbi est eu donec. sed amet.Feugiat vitae, purus id mattis eu risus habitasse. Eget rhoncus ultrices turpis tortor, amet, enim. Pulvinar aliquet praesent ornare turpis ut. Sed id morbi est eu donec.'
        },
        {
          title: 'How do I fix an online booking error',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vitae neque faucibus dui viverra ornare lobortis elementum sit. Risus placerat leo porttitor interdum cras sit. Pretium fusce in vitae sodales ipsum diam. Lectus aliquam imperdiet convallis luctus euismod volutpat sed amet.Feugiat vitae, purus id mattis eu risus habitasse. Eget rhoncus ultrices turpis tortor, amet, enim. Pulvinar aliquet praesent ornare turpis ut. Sed id morbi est eu donec. sed amet.Feugiat vitae, purus id mattis eu risus habitasse. Eget rhoncus ultrices turpis tortor, amet, enim. Pulvinar aliquet praesent ornare turpis ut. Sed id morbi est eu donec.'
        },
        {
          title: 'How do i online booking',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vitae neque faucibus dui viverra ornare lobortis elementum sit. Risus placerat leo porttitor interdum cras sit. Pretium fusce in vitae sodales ipsum diam. Lectus aliquam imperdiet convallis luctus euismod volutpat sed amet.Feugiat vitae, purus id mattis eu risus habitasse. Eget rhoncus ultrices turpis tortor, amet, enim. Pulvinar aliquet praesent ornare turpis ut. Sed id morbi est eu donec. sed amet.Feugiat vitae, purus id mattis eu risus habitasse. Eget rhoncus ultrices turpis tortor, amet, enim. Pulvinar aliquet praesent ornare turpis ut. Sed id morbi est eu donec.'
        },
        {
          title: 'How do i scan',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vitae neque faucibus dui viverra ornare lobortis elementum sit. Risus placerat leo porttitor interdum cras sit. Pretium fusce in vitae sodales ipsum diam. Lectus aliquam imperdiet convallis luctus euismod volutpat sed amet.Feugiat vitae, purus id mattis eu risus habitasse. Eget rhoncus ultrices turpis tortor, amet, enim. Pulvinar aliquet praesent ornare turpis ut. Sed id morbi est eu donec. sed amet.Feugiat vitae, purus id mattis eu risus habitasse. Eget rhoncus ultrices turpis tortor, amet, enim. Pulvinar aliquet praesent ornare turpis ut. Sed id morbi est eu donec.'
        },
        {
          title: 'How do I fix an barcode error',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vitae neque faucibus dui viverra ornare lobortis elementum sit. Risus placerat leo porttitor interdum cras sit. Pretium fusce in vitae sodales ipsum diam. Lectus aliquam imperdiet convallis luctus euismod volutpat sed amet.Feugiat vitae, purus id mattis eu risus habitasse. Eget rhoncus ultrices turpis tortor, amet, enim. Pulvinar aliquet praesent ornare turpis ut. Sed id morbi est eu donec. sed amet.Feugiat vitae, purus id mattis eu risus habitasse. Eget rhoncus ultrices turpis tortor, amet, enim. Pulvinar aliquet praesent ornare turpis ut. Sed id morbi est eu donec.'
        }
      ]
    }
  ]



  const bannerDescStatic = [
    {
      title: 'Safe, Fast And Uninterrupted Payment',
      text: 'We follow the developing technologies  and integrate them in accordance with every infrastructue. We are at you service with 24/7 support team.'
    },
    {
      title: 'Easy, Fast And Uninterrupted Payment',
      text: 'We follow who know road. develpopment integrate them in accordance with every infrastructue. We are at you service with 24/7 support team.'
    },
    {
      title: 'Secure, Fast And Uninterrupted Payment',
      text: 'We follow the developing technologies  and integrate them in accordance with every infrastructue. We are at you service with 24/7 support team.'
    },
  ]


  // ********************************************
  // home page banner image
  //*********************************************
  const bannerImageStatic = 
    {
      banner_img: ''
    }
  




// ************************************************
//              FEATURED POST
// ************************************************
const featuredPostStatic = [
  {
    _id: 1,
    title: 'Season',
    text: 'Young businessman and attractive model in IT service in a tech portrait.',
    image: '3.png',
    duration: '4 min read',
    user: {
      _id: 1,
      first_name: 'John',
      last_name: 'Doe',
      image: '1.png',
    },
    created_at: '2022-12-04T00:00:00.000Z'
  },
  {
    _id: 2,
    title: 'Design',
    text: 'Production-ready, light weight fully customizable React carousel component that rocks supports.',
    image: '3.png',
    duration: '6 min read',
    user: {
      _id: 2,
      first_name: 'Moses',
      last_name: 'Okay',
      image: '2.png',
    },
    created_at: '2022-12-04T00:00:00.000Z'
  },
  

  {
    _id: 3,
    title: 'Template',
    text: 'Lorem ipsum dolor sit amet consectetur. Nunc ornare et lacinia on him are there.',
    image: '3.png',
    duration: '6 min read',
    user: {
      _id: 3,
      first_name: 'Moses',
      last_name: 'Okay',
      image: '',
    },
    created_at: '2022-12-04T00:00:00.000Z'
  }
]


// ************************************************
//              HELP 
// ************************************************

const helpCategoriesStatic = [
  {
    _id: '1',
    title: 'chat',
    image: '1.svg',
    created_at: '2022-12-04T00:00:00.000Z'
  },
  {
    _id: '2',
    title: 'Virtual Card',
    image: '2.svg',
    created_at: '2022-12-04T00:00:00.000Z'
  },
  {
    _id: '3',
    title: 'QR Code Payment',
    image: '3.svg',
    created_at: '2022-12-04T00:00:00.000Z'
  },
  {
    _id: '4',
    title: 'Dollar Card',
    image: '1.svg',
    created_at: '2022-12-04T00:00:00.000Z'
  },
  {
    _id: '5',
    title: 'chat',
    image: '2.svg',
    created_at: '2022-12-04T00:00:00.000Z'
  },
  {
    _id: '6',
    title: 'QR Code Payment',
    image: '3.svg',
    created_at: '2022-12-04T00:00:00.000Z'
  },
  {
    _id: '7',
    title: 'Virtual Card',
    image: '1.svg',
    created_at: '2022-12-04T00:00:00.000Z'
  },
  {
    _id: '8',
    title: 'QR Code Payment',
    image: '2.svg',
    created_at: '2022-12-04T00:00:00.000Z'
  },
]


























  export {
    bannerTwoStatic,
    faqItemsStatic,
    bannerDescStatic,
    bannerImageStatic,
    featuredPostStatic,
    helpCategoriesStatic,
}