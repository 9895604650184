





const DarkTheme = ({toggelDarkTheme}) => {
    return (
        <div onClick={() => toggelDarkTheme()} className="app-dark-theme"></div>
    )
}



export default DarkTheme