

import { useState } from 'react'
import TopNavigation from './TopNavigation'
import SideNavigation from './SideNavigation'
import Notification from '../modal/Notification'

const Navigation = ({ setIsThemeOption }) => {
    const [sideNav, setSideNav] = useState(false)
    const [isActive, setIsActive] = useState(false)
    const [stickNavigation, setStickNavigation] = useState(false)

    const toggleOptions = (param) => {
        let x = param.state === true ? 'active' : ''
        setSideNav(x)
    }

    const toggleTheme = (state) => {
        setIsThemeOption(state)
    }


    const toggleNavigationPosition = () => {
        setSideNav('')
        setStickNavigation(!stickNavigation)
    }

    const toggleNotification = (state) => {
        setIsActive(state)
    }



    return (
        <div className="">
            <TopNavigation stickNavigation={stickNavigation} toggleNavigationPosition={toggleNavigationPosition} toggleNotification={toggleNotification} setIsActive={setIsActive} toggleOptions={toggleOptions} toggleTheme={toggleTheme}/>
            <SideNavigation stickNavigation={stickNavigation} toggleNavigationPosition={toggleNavigationPosition} toggleOptions={toggleOptions} sideNav={sideNav}/>
            <Notification toggleNotification={toggleNotification} isActive={isActive} setIsActive={setIsActive}/>
        </div>
    )
}


export default Navigation



