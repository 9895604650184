import { useState, useRef, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
    faFilter,
} from '@fortawesome/free-solid-svg-icons'




const TopBar = () => {
    let formRef = useRef()
    const [isAtive, setIsAtive] = useState(false)
    const [filterBy, setFilterBy] = useState('Users')

    const toggleSelectForm = () => {
        setIsAtive(true)
    }

    const updateField = (string) => {
        setFilterBy(string)
        return setIsAtive(false)
    }

    const handler = (e) => {
        if(formRef.current && !e.composedPath().includes(formRef.current)){
            setIsAtive(false)
        }
    }


    useEffect(() => {
        document.addEventListener('click', handler)
        return () => {
            document.removeEventListener('click', handler) // clears document.addEventListener('click', handler) from browser
        }
    }, [])


    return (
        <div className="topbar-container">
            <div className="top-bar-inner">
                <div className="top-left">
                    <h3 className="title-header">Quick Overview</h3>
                </div>
                <div className="top-right">
                    <div className="date">23rd February, 2024</div>
                    <div className="select-drop-down" ref={formRef}>
                        <div onClick={() => toggleSelectForm()} className="drop-down-bar">
                            <FontAwesomeIcon className="icon" icon={faFilter} />
                            <span>Filter by {filterBy}</span>
                        </div>
                        <div className={`drop-down-frame ${isAtive ? 'active' : ''}`}>
                            <ul>
                                <li onClick={() => updateField('User')}>Users</li>
                                <li onClick={() => updateField('Date')}>Date</li>
                                <li onClick={() => updateField('Profile')}>Profile</li>
                                <li onClick={() => updateField('Amount')}>Amount</li>
                                <li onClick={() => updateField('Time')}>Times</li>
                                <li onClick={() => updateField('User')}>Users</li>
                                <li onClick={() => updateField('Date')}>Date</li>
                                <li onClick={() => updateField('Profile')}>Profile</li>
                                <li onClick={() => updateField('Amount')}>Amount</li>
                                <li onClick={() => updateField('Time')}>Times</li>
                                <li onClick={() => updateField('User')}>Users</li>
                                <li onClick={() => updateField('Date')}>Date</li>
                                <li onClick={() => updateField('Profile')}>Profile</li>
                                <li onClick={() => updateField('Amount')}>Amount</li>
                                <li onClick={() => updateField('Time')}>Times</li>
                                <li onClick={() => updateField('Date')}>Date</li>
                                <li onClick={() => updateField('Profile')}>Profile</li>
                                <li onClick={() => updateField('Amount')}>Amount</li>
                                <li onClick={() => updateField('Time')}>Times</li>
                                <li onClick={() => updateField('User')}>Users</li>
                                <li onClick={() => updateField('Date')}>Date</li>
                                <li onClick={() => updateField('Profile')}>Profile</li>
                                <li onClick={() => updateField('Amount')}>Amount</li>
                                <li onClick={() => updateField('Time')}>Times</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default TopBar