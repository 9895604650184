import { configureStore } from '@reduxjs/toolkit'
import pageCategoryReducer from './pageCategorySlice'
import pageContentReducer from './PageContentSlice'



const Store = configureStore({
    reducer: {
        pageCategories: pageCategoryReducer,
        pageContents: pageContentReducer
    }
})


export default Store