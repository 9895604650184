
import { useEffect } from 'react'
import ProfileTop from './ProfileTop'
import ProfileBody from './ProfileBody'
import MobileTable from './MobileTable'




const Profile = ({toggleModal}) => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className="general-table-container">
            <ProfileTop toggleModal={toggleModal}/>
            <ProfileBody/>
            <MobileTable/>
        </div>
    )
}



export default Profile