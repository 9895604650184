
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import ProfileTopLeft from './ProfileTopLeft'
import ProfileTopRight from './ProfileTopRight'






const ProfileTop = ({toggleModal}) => {
    return (
        <div className="profile-top">
            <Row className="show-grid">
                <Col className="top-col" xs={12} sm={12} md={12} lg={12} xl={5}>
                    <ProfileTopLeft toggleModal={toggleModal}/>
                </Col>
                <Col className="top-col" xs={12} sm={12} md={12} lg={12} xl={7}>
                    <ProfileTopRight/>
                </Col>
            </Row>
        </div>
    )
}



export default ProfileTop