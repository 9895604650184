import { useEffect, useState, useRef } from 'react'
import Axios from 'axios'
import {  logo, url } from '../../File'
import { NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
    faBars,
    faAngleDown
} from '@fortawesome/free-solid-svg-icons'
import SideNavigation from './SideNavigation'
// import FloatNavigation from './FloatNavigation'


const Navigation = () => {
    const fetchPageCategoriesRef = useRef(null)
    const [pageCategories, setPageCategories] = useState([])
    const [sideNav, setSideNav] = useState(false)

    // side bar toggle
    const sideNavToggle = (state) => {
        setSideNav(state)
        fetchPageCategories()
        // window.scrollTo(0, 0) // page scroll to top
    }


     // Fetch page categories
     const fetchPageCategories = () => {
        Axios.get(url('/api/fetch-page-categories')).then((response) => {
            const data = response.data
            if(data.status){
                return setPageCategories(data.categories)
            }
            return setPageCategories([])
        })
    }

   fetchPageCategoriesRef.current =  fetchPageCategories



    useEffect(() => {
       fetchPageCategoriesRef.current()
    }, [])
    
    return (
        <div className="main-navigation">
            <div className={`navigation-container`}>
                <SideNavigation pageCategories={pageCategories} sideNav={sideNav} sideNavToggle={sideNavToggle}/>
                <div className="inner-main-navi">
                    <NavigationLeft/>
                    <NavigationRight pageCategories={pageCategories} sideNavToggle={sideNavToggle} />
                </div>
                {/* <FloatNavigation pageCategories={pageCategories} sideNav={sideNav}  sideNavToggle={sideNavToggle}/> */}
            </div>
        </div>
    )
}




export default Navigation







const NavigationLeft = () => {
    return (
        <div className="nav-left">
            <NavLink to="/"><img src={logo('6.jpg')} alt="nav"/></NavLink>
        </div>
    )
}




const NavigationRight = ({ pageCategories, sideNavToggle}) => {
    return (
        <div className="nav-right main">
            <ul className="nav-links">
                { pageCategories.length > 0 ? (<NavigationLink pageCategories={pageCategories}/>) : null }
                <li><NavLink to="/agent">Agent</NavLink></li>
                <li><NavLink to="/company">Company</NavLink></li>
                <li><NavLink to="/">Pricing</NavLink></li>
                <li><NavLink to="/contact-us">Contact us</NavLink></li>
                <li className="button"><NavLink to="/">Join Payizzy</NavLink></li>
            </ul>
            <div className="nav-icon">
                <FontAwesomeIcon  onClick={() => sideNavToggle(true)} className="icon" icon={faBars} />
            </div>
        </div>
    )
}





const NavigationLink = ({pageCategories}) => {
    return (
        <li className="link-container">
            <span>
                Personal
                <FontAwesomeIcon className="icon" icon={faAngleDown} />
            </span>
            <div className="drop-down-container">
                <ul>
                {
                    pageCategories.map((pageCategory, index) => (
                        <NavLink key={index} to={`/personal/detail?category=${pageCategory._id}`}>
                            <li>
                                {pageCategory.category}
                            </li>
                        </NavLink>
                    )) 
                }
                </ul>
            </div>
        </li>
    )
}