
import { NavLink } from 'react-router-dom'
import { useEffect } from 'react'
import AOS from 'aos'






const Banner2 = ({contentBanner}) => {
    useEffect(() => {
        AOS.init({ duration: 2000 })
    })


    return (
        <div data-aos={'zoom-in'} className="Banner2-container">
            <ul>
                <li>
                    <div className="title-header">
                        <h3>{contentBanner.title}</h3>
                    </div>
                </li>
                <li>
                    <p>{contentBanner.text}</p>
                </li>
                {
                    contentBanner.button_active ? (
                        <li>
                            <NavLink to={contentBanner.link} className="link">{contentBanner.button}</NavLink>
                        </li>
                    ) : null
                }
            </ul>
        </div>
    )
}


export default Banner2