import { useEffect } from 'react'
import Banner from './Banner'
import Banner3 from './Banner3'
import Content from './Content'
import DownloadApp from '../extras/DownloadApp'
import ContentLinks from './ContentLinks'
import HowItWorks from './HowItWorks'
import Reason from './Reason'








const Agent = ({Contentloader}) => {
    useEffect(() => {
        window.scrollTo(0, 0) // page scroll to top
      }, [])

    return (
        <div className="personal-container">
            <Banner />
            <Content />
            <ContentLinks/>
            <HowItWorks/>
            <Reason/>
            <Banner3/>
            <DownloadApp/>
        </div>
    )
}




export default Agent
