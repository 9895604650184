import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
    faDollar,
    faCreditCard,
    faCircleCheck
} from '@fortawesome/free-solid-svg-icons'


const TotalTransaction = () => {
    return (
        <div className="general-total">
            <Row className="show-grid">
                <Content title={"Total Inflow"} amount={"NGN 2,000,000"} card={faDollar} color={'green'}/>
                <Content title={"Total Outflow"} amount={"NGN 7,560,000"}  card={faCreditCard}  color={'orange'}/>
                <Content title={"Total virtual Balance"} amount={"NGN 10,221,000"}  card={faCircleCheck} color={'red'}/>
                <Content title={"Total trade Credit"} amount={"NGN 10,221,000"}  card={faCreditCard} color={'purple'}/>
            </Row>
        </div>
    )
}


export default TotalTransaction



const Content = ({card, title, amount, color }) => {
    return (
        <Col xs={12} sm={12} md={6} lg={4} xl={3}>
            <div className="total-card">
                <div className="total-icon">
                    <FontAwesomeIcon className={`icon ${color}`} icon={card} />
                </div>
                <div className="total-right">
                    <span>{title}</span>
                    <h3>{amount}</h3>
                </div>
            </div>
        </Col>
    )
}
