import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
    faTimes,
} from '@fortawesome/free-solid-svg-icons'
import FormInputAlert from '../alert/FormInputAlert'
import Axios from 'axios'
import { url } from '../../File'
import { useDispatch } from 'react-redux'
import { updatePageCategories } from '../redux/pageCategorySlice'







const EditPageCategory = ({ id, name, setName, setUpdated, alertNotification, toggleEditCategory}) => {
    const [alert, setAlert] = useState('')
    // react hooks
    const dispatch = useDispatch()

    const editPageCategory = () => {
        const validate = validate_input(name)
        if(validate === false) return 

        Axios.post(url('/api/admin/edit-page-category'), {id: id, name: name}).then((response) => {
            const data = response.data
            if(data.status === 'input-error'){
                return setAlert(data.validationError)
            }else if(data.status === 'ok'){
                setUpdated(data.updatedCategory)
                dispatch(updatePageCategories(data.updatedCategory))
                return toggleEditCategory(false)
            }else{
                return  alertNotification('error', data.error)
            }
        })
    }


    const validate_input = (input) => {
        let failed = false;
        if(input.length === 0){
            failed = true
            setAlert(`*Category field is required`)
        } else if(input.length < 3){
            failed = true
            setAlert(`*Must be minimum of 3 characters`)
        }else if(input.length > 30){
            failed = true
            setAlert(`*Must be minimum of 30 characters`)
        }
        if(failed === true){
            return false
        }else{
            return true
        }
    }
    
    return (
        <div className="add-admin-container">
            <div className="form">
                <TitleHeader toggleEditCategory={toggleEditCategory}/>
                <div className="form-body">
                    <div className="form-group">
                        <label>Category</label>
                        <input onChange={(e) => setName(e.target.value)} type="text" className="" placeholder="Enter Category" value={name}/>
                        <FormInputAlert alert={alert}/>
                    </div>
                    <div className="form-button">
                        <button onClick={() => editPageCategory()} type="button">EDIT CATEGORY</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditPageCategory







const TitleHeader = ({toggleEditCategory}) => {
    return (
        <div className="title-header">
            <h3>Edit Category</h3>
            <div className="form-cancel">
                <FontAwesomeIcon onClick={() => toggleEditCategory(false)} className="icon" icon={faTimes} />
            </div>
        </div>
    )
}


