import React, { useState, useEffect, useRef, Fragment} from 'react';
import {  app_image, url } from '../../File'
import Cookies from 'js-cookie'
import FormAlertError from '../alerts/FormAlertError'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
    faTimes
 } from '@fortawesome/free-solid-svg-icons'
 import Axios from 'axios'
 import WaitListAlert from '../alerts/WaitListAlert'







const AwaitList = ({awaitListActive, setAwaitListActive}) => {
    const awaitListRef = useRef()
    let wait = Cookies.get('_wait_list')
    const [email, setEmail] = useState('')
    const [is_success, setIs_success] = useState(false)
    const [buttonLock, setButtonLock] = useState(false)
    const [emailAlert, setEmailAlert] = useState('')
    const [image, setImage] = useState('waiting-list-demo-img.png')
    const [confirmation, setConfirmation] = useState({title: "Subscription Successful!", message: "Thank you for subscribing to payizzy waiting list."})


    const toggelList = (state) => {
        return setAwaitListActive(state)
    }


    
    const fetchWaitList = () => {
        // if waitlist cookies does not exists, show waitlist
        if(!wait){
            Axios.get(url('/api/fetch-waiting-list')).then((response) => {
                const data = response.data
                if(data){
                    setImage(data.image)
                    showWaitList(data.is_active, data.duration)
                }
            })
        }
    }


    // subscribe to waiting list
    const subscribe = () => {
        setButtonLock(true)
        // validate input fields
        const validate = validate_input(email)
        if(validate === 'failed'){
            return setButtonLock(false)
        }
       
        //add user email to wishlist in the database.
        Axios.post(url('/api/subscribe-waiting-list'), {email}).then((response) => {
            setButtonLock(false)
            const data = response.data
            if(data.validationError){
                return setEmailAlert(data.validation.email_error)
            }
            if(data.exist){
                return setEmailAlert(data.exist)
            }

            if(data.success){
                setEmail('')
                setConfirmation({
                    title: data.confirmation_title,
                    message: data.confirmation_message,
                })
                showWaitList(false, 10000) // remove waitlist after 1 minute
                setIs_success(true)
                Cookies.set('_wait_list', 'ok', { expires: 7 }) //set cookies
            }
        })
    }

    const validate_input = (email) => {
        let failed = false
        const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if(email.length === 0){
            failed = true
            setEmailAlert(`*Email field is required`)
        } else if(!email.match(validRegex)){
            failed = true
            setEmailAlert(`*Invalid email address`)
        }
        if(failed === true){
            return 'failed'
        }else{
            return 'success'
        }
    }


    const showWaitList = (state, duration) => {
        if(!awaitListActive && !state){
            fetchWaitList()
        }else{
            setTimeout(() => {
                toggelList(state)
            }, duration)
        }
    }
    


    // onload page content
    awaitListRef.current = fetchWaitList
    useEffect(() => {
        // awaitListRef.current()
    }, [])


    return (
        <Fragment>
            {
                awaitListActive === true ? (<ParentList image={image} emailAlert={emailAlert} buttonLock={buttonLock} toggelList={toggelList} subscribe={subscribe} email={email} setEmail={setEmail} confirmation={confirmation} is_success={is_success}/>) : null
            }
            
        </Fragment>
    )
}



export default AwaitList




const ParentList = ({toggelList, image, buttonLock, emailAlert, confirmation, is_success, email, setEmail, subscribe}) => {
    return (
        <div className="await-list-container">
            <div className="dark-theme">
                <div className="await-body">
                    <CancelButton toggelList={toggelList}/>
                    <TopImage image={image}/>
                    <Left buttonLock={buttonLock} emailAlert={emailAlert} confirmation={confirmation} email={email} setEmail={setEmail} subscribe={subscribe} toggelList={toggelList} is_success={is_success}/>
                    <Right image={image}/>
                </div>
            </div>
        </div>
    )
}


const CancelButton = ({toggelList}) => {
    return (
        <div className="cancel-button">
            <FontAwesomeIcon  onClick={() => toggelList(false)} className="icon" icon={faTimes} />
        </div>
    )
}




const Left = ({ confirmation, emailAlert, buttonLock, toggelList, is_success, setEmail, email, subscribe }) => {
    return (
        <div className="left">
            <div className="left-container">
                {
                    is_success === true ? (
                        <WaitListAlert toggelList={toggelList} confirmation={confirmation}/>
                    ) : (
                        <WaitListForm emailAlert={emailAlert} buttonLock={buttonLock} setEmail={setEmail} email={email} subscribe={subscribe}/>
                    )
                }
            </div>
        </div>
    )
}


const WaitListForm = ({setEmail, email, emailAlert, subscribe, buttonLock}) => {
    return (
        <div className="wait-list-form">
            <div className="title-header">
                <h3>Waiting List?</h3>
            </div>
            <div className="body">
                <p>Join our waiting list, be the first to test and explore our app</p>
            </div>
            <div className="form">
                <div className="form-group">
                    <FormAlertError alert={emailAlert}/>
                    <input  onChange={(e) => setEmail(e.target.value)} value={email} type="text" placeholder="Enter your email address"/>
                </div>
                <div className="form-group">
                    {
                        buttonLock === true ? (
                            <button type="button" className="subscribe" disabled>Please wait...</button>
                        ) : (
                            <button onClick={() => subscribe()} type="button" className="subscribe">SUBSCRIBE NOW</button>
                        )
                    }
                </div>
            </div>
        </div>
    )
}



const Right = ({image}) => {
    return (
        <div className="right">
            <div className="image">
                <img src={app_image(image)} alt="subscribe-img"/>
            </div>
        </div>
    )
}


const TopImage = ({image}) => {
    return (
        <div className="top-image">
            <div className="image">
                <img src={app_image(image)} alt="subscribe-img"/>
            </div>
        </div>
    )
}
