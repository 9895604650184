import { useState, useEffect, useRef } from 'react'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
    faAngleDown
} from '@fortawesome/free-solid-svg-icons'





const SearchRange = ({pageDarkTheme, setPageDarkTheme, byCountry, setByCountry, byType, setByType, byDate, setByDate}) => {
    return (
        <div className="search-range">
            <div className="inner-search-range">
                <Row className="show-grid">
                    <Col xs={12} sm={12} md={6} lg={4} xl={4}><ByDate pageDarkTheme={pageDarkTheme} setPageDarkTheme={setPageDarkTheme} byDate={byDate} setByDate={setByDate}/></Col>
                    <Col xs={12} sm={12} md={6} lg={4} xl={4}><ByType pageDarkTheme={pageDarkTheme} setPageDarkTheme={setPageDarkTheme} setByType={setByType} byType={byType}/></Col>
                    <Col xs={12} sm={12} md={6} lg={4} xl={4}><ByCountry pageDarkTheme={pageDarkTheme} setPageDarkTheme={setPageDarkTheme} setByCountry={setByCountry} byCountry={byCountry}/></Col>
                </Row>
            </div>
        </div>
    )
}


export default SearchRange





const ByDate = ({ byDate, setByDate, pageDarkTheme, setPageDarkTheme}) => {
    let formRef = useRef()
    const [selectForm, setSelectForm] = useState('')
    
    const toggleSelectForm = (state) => {
        let x = state === true ? 'active' : ''
        return setSelectForm(x)
    }

    const updateField = (string) => {
        setByDate(string)
        return setSelectForm('')
    }

    const handler = (e) => {
        if(formRef.current && !e.composedPath().includes(formRef.current)){
            setSelectForm('')
        }
    }


    useEffect(() => {
        document.addEventListener('click', handler)
        return () => {
            document.removeEventListener('click', handler) // clears document.addEventListener('click', handler) from browser
        }
    }, [])

    return (
        <div className="search-range-inner">
            <div className="title">Date Range</div>
            <div className="select-container" ref={formRef}>
                <div onClick={() => toggleSelectForm(!selectForm)} className="form-top">
                    <span>{byDate}</span>
                    <FontAwesomeIcon className="icon" icon={faAngleDown} />
                </div>
                <div className={`select-item-form ${selectForm}`}>
                    <ul>
                        <li onClick={() => updateField('All')}>All</li>
                        <li onClick={() => updateField('Orange')}>Orange</li>
                        <li onClick={() => updateField('Purple')}>Purple</li>
                        <li onClick={() => updateField('Green')}>Green</li>
                        <li onClick={() => updateField('Yellow')}>Yellow</li>
                        <li onClick={() => updateField('All')}>All</li>
                        <li onClick={() => updateField('Orange')}>Orange</li>
                        <li onClick={() => updateField('Purple')}>Purple</li>
                        <li onClick={() => updateField('Green')}>Green</li>
                        <li onClick={() => updateField('Yellow')}>Yellow</li>
                        <li onClick={() => updateField('All')}>All</li>
                        <li onClick={() => updateField('Orange')}>Orange</li>
                        <li onClick={() => updateField('Purple')}>Purple</li>
                        <li onClick={() => updateField('Green')}>Green</li>
                        <li onClick={() => updateField('Yellow')}>Yellow</li>
                        <li onClick={() => updateField('All')}>All</li>
                        <li onClick={() => updateField('Orange')}>Orange</li>
                    </ul>
                </div>
            </div>
        </div>
    )
}





const ByType = ({ byType, setByType, pageDarkTheme, setPageDarkTheme}) => {
    let formRef = useRef()
    const [selectForm, setSelectForm] = useState('')
    
    const toggleSelectForm = (state) => {
        let x = state === true ? 'active' : ''
        return setSelectForm(x)
    }

    const updateField = (string) => {
        setByType(string)
        return setSelectForm('')
    }

    const handler = (e) => {
        if(formRef.current && !e.composedPath().includes(formRef.current)){
            setSelectForm('')
        }
    }


    useEffect(() => {
        document.addEventListener('click', handler)
        return () => {
            document.removeEventListener('click', handler) // clears document.addEventListener('click', handler) from browser
        }
    }, [])

    return (
        <div className="search-range-inner">
            <div className="title">User Type</div>
            <div className="select-container" ref={formRef}>
                <div onClick={() => toggleSelectForm(!selectForm)} className="form-top">
                    <span>{byType}</span>
                    <FontAwesomeIcon className="icon" icon={faAngleDown} />
                </div>
                <div className={`select-item-form ${selectForm}`}>
                    <ul>
                        <li onClick={() => updateField('All')}>All</li>
                        <li onClick={() => updateField('Orange')}>Orange</li>
                        <li onClick={() => updateField('Purple')}>Purple</li>
                        <li onClick={() => updateField('Green')}>Green</li>
                        <li onClick={() => updateField('Yellow')}>Yellow</li>
                        <li onClick={() => updateField('All')}>All</li>
                        <li onClick={() => updateField('Orange')}>Orange</li>
                        <li onClick={() => updateField('Purple')}>Purple</li>
                        <li onClick={() => updateField('Green')}>Green</li>
                        <li onClick={() => updateField('Yellow')}>Yellow</li>
                        <li onClick={() => updateField('All')}>All</li>
                        <li onClick={() => updateField('Orange')}>Orange</li>
                        <li onClick={() => updateField('Purple')}>Purple</li>
                        <li onClick={() => updateField('Green')}>Green</li>
                        <li onClick={() => updateField('Yellow')}>Yellow</li>
                        <li onClick={() => updateField('All')}>All</li>
                        <li onClick={() => updateField('Orange')}>Orange</li>
                    </ul>
                </div>
            </div>
        </div>
    )
}





const ByCountry = ({ byCountry, setByCountry, pageDarkTheme, setPageDarkTheme}) => {
    let formRef = useRef()
    const [selectForm, setSelectForm] = useState('')
    
    const toggleSelectForm = (state) => {
        let x = state === true ? 'active' : ''
        return setSelectForm(x)
    }

    const updateField = (string) => {
        setByCountry(string)
        return setSelectForm('')
    }

    const handler = (e) => {
        if(formRef.current && !e.composedPath().includes(formRef.current)){
            setSelectForm('')
        }
    }


    useEffect(() => {
        document.addEventListener('click', handler)
        return () => {
            document.removeEventListener('click', handler) // clears document.addEventListener('click', handler) from browser
        }
    }, [])

    return (
        <div className="search-range-inner">
            <div className="title">Country</div>
            <div className="select-container" ref={formRef}>
                <div onClick={() => toggleSelectForm(!selectForm)} className="form-top">
                    <span>{byCountry}</span>
                    <FontAwesomeIcon className="icon" icon={faAngleDown} />
                </div>
                <div className={`select-item-form ${selectForm}`}>
                    <ul>
                        <li onClick={() => updateField('All')}>All</li>
                        <li onClick={() => updateField('Orange')}>Orange</li>
                        <li onClick={() => updateField('Purple')}>Purple</li>
                        <li onClick={() => updateField('Green')}>Green</li>
                        <li onClick={() => updateField('Yellow')}>Yellow</li>
                        <li onClick={() => updateField('Purple')}>Purple</li>
                        <li onClick={() => updateField('Green')}>Green</li>
                        <li onClick={() => updateField('Yellow')}>Yellow</li>
                        <li onClick={() => updateField('All')}>All</li>
                        <li onClick={() => updateField('Orange')}>Orange</li>
                        <li onClick={() => updateField('Purple')}>Purple</li>
                        <li onClick={() => updateField('Green')}>Green</li>
                        <li onClick={() => updateField('Yellow')}>Yellow</li>
                        <li onClick={() => updateField('All')}>All</li>
                        <li onClick={() => updateField('Orange')}>Orange</li>
                        <li onClick={() => updateField('Purple')}>Purple</li>
                        <li onClick={() => updateField('Green')}>Green</li>
                        <li onClick={() => updateField('Yellow')}>Yellow</li>
                        <li onClick={() => updateField('All')}>All</li>
                        <li onClick={() => updateField('Orange')}>Orange</li>
                        <li onClick={() => updateField('Purple')}>Purple</li>
                        <li onClick={() => updateField('Green')}>Green</li>
                        <li onClick={() => updateField('Yellow')}>Yellow</li>
                    </ul>
                </div>
            </div>
        </div>
    )
}


