import { useState, useEffect } from 'react'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import {  user_img } from '../../File'
import { NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
    faCircleCheck
} from '@fortawesome/free-solid-svg-icons'
import TableBottom from './TableBottom'





const MobileTable = ({toggleFloatProfile}) => {
    const [screenWidth, setScreenWidth] = useState(false)



    const fetchUserProfile = () => {
        console.log('yes')
        toggleFloatProfile(true)
    }


    useEffect(() => {
        let width = window.innerWidth
        if(width <= 767){
            setScreenWidth(true)
        }
    }, [])
    
    return(
        <div className="mobile-table-content">
            <div className="mobile-general-table">
                <div className="title-header">
                    <h3>User <span>(273,890)</span></h3>
                </div>
                <div className="general-table-mobile-body">
                    <TableContent screenWidth={screenWidth}  fetchUserProfile={fetchUserProfile}/>
                    <TableContent screenWidth={screenWidth}  fetchUserProfile={fetchUserProfile}/>
                    <TableContent screenWidth={screenWidth}  fetchUserProfile={fetchUserProfile}/>
                    <TableContent screenWidth={screenWidth}  fetchUserProfile={fetchUserProfile}/>
                    <TableContent screenWidth={screenWidth}  fetchUserProfile={fetchUserProfile}/>
                </div>
            </div>
            <TableBottom/>
        </div>
    )
}




export default MobileTable





const TableContent = ({screenWidth, fetchUserProfile}) => {
    return (
        <div className="table-content">
            <Row className="show-grid">
                <Col xs={12} sm={6} md={6} lg={6} xl={6}><TableContentLeft screenWidth={screenWidth} fetchUserProfile={fetchUserProfile}/></Col>
                <Col xs={12} sm={6} md={6} lg={6} xl={6}><TableContentRight/></Col>
            </Row>
        </div>
    )
}



const TableContentLeft = ({screenWidth, fetchUserProfile}) => {
    return (
            <div className="table-content-left">
                <div className="left">
                {
                    screenWidth ? (<UserImageMobile/>) : (<UserImageDesktop fetchUserProfile={fetchUserProfile}/>)
                }
                <div className="left-detail">
                    <ul>
                        <li className="name">
                            {
                                screenWidth ? (
                                    <NavLink to="/admin/profile"><h3>John Doe</h3></NavLink>
                                ) : (<h3 onClick={() => fetchUserProfile()}>John Doe</h3>)
                            }
                            
                            <p>Acc No: 1100223344</p>
                        </li>
                        <li className="list">
                            <span>Account Number: </span> 1103208463
                        </li>
                        <li className="list">
                            <span>Phone No: </span> +234765342783
                        </li>
                        <div className="toggle-item">
                            <li className="list">
                                <span>BVN: </span> 2278463987
                            </li>
                            <li className="list">
                                <span>Device Type: </span> Ios 16.7 | Iphone 15 Promax
                            </li>
                            <li className="list">
                                <span>Status: </span>
                                <FontAwesomeIcon className="icon success" icon={faCircleCheck} />
                                <span className="success">Verified</span>
                            </li>
                        </div>
                    </ul>
                </div>
            </div>
        </div>
    )
}



const UserImageMobile = () => {
    return (
        <NavLink to="/admin/profile">
            {/* <div className="no-img" style={{ backgroundColor: 'green' }}><span>R</span></div> */}
            <div className="img">
                <img src={user_img('9.png')} alt="user-img"/>
            </div>
        </NavLink>
    )
}


const UserImageDesktop = ({fetchUserProfile}) => {
    return (
        <div className="">
            {/* <div className="no-img" style={{ backgroundColor: 'green' }}><span>R</span></div> */}
            <div onClick={() => fetchUserProfile()} className="img">
                <img src={user_img('9.png')} alt="user-img"/>
            </div>
        </div>
    )
}



const TableContentRight = () => {
    return (
        <div className="table-content-right">
            <ul>
                <li className="list">
                    <span>BVN: </span> 2278463987
                </li>
                <li className="list">
                    <span>Device Type: </span> Ios 16.7 | Iphone 15 Promax
                </li>
                <li className="list">
                    <span>Status: </span>
                    <FontAwesomeIcon className="icon success" icon={faCircleCheck} />
                    <span className="success">Verified</span>
                </li>
            </ul>
        </div>
    )
}





























