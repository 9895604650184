import Axios from 'axios'
import { useState, useEffect, useRef } from 'react'
import { NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
    faPlus,
    faPen,
    faEye,
    faTrash,
    faCircle,
    faCamera,
    faToggleOn,
    faToggleOff,
    faEllipsis,
} from '@fortawesome/free-solid-svg-icons'
import TableBottom from './TableBottom'
import { DateTime, url, app_image } from '../../File'
import EmptyContent from '../common/EmptyContent'
import HTMLReactParser from 'html-react-parser'









const PageContentBody = ({ getActionloader, pageContents, alertNotification, toggleAddMember}) => {

    return (
        <div className="general-table-body">
            <div className="inner-body">
                <SearchBar toggleAddMember={toggleAddMember}/>
                {pageContents ? (<ContentTableParent pageContents={pageContents} alertNotification={alertNotification} getActionloader={getActionloader}/>) : (<EmptyContent text="There are no contents yet!"/>)}
                {pageContents ? (<TableBottom/>)  : null }
            </div>
        </div>
    )
}



export default PageContentBody





const SearchBar = ({toggleAddMember}) => {
    return (
        <div className="search-bar">
            <div className="bar-left">
                <h3 className="title-header">Search contents</h3>
            </div>
            <div className="bar-right">
                <div className="form">
                    <input type="text" className="" placeholder="Search contents"/>
                    <div className="add-member-btn">
                        <NavLink to="/admin/add-page-content">
                            <FontAwesomeIcon className="icon" icon={faPlus} /> <span>Add Content</span>
                        </NavLink>
                    </div>
                    <button onClick={() => toggleAddMember(true)} type="button" className="admin-admin-btn">
                        <FontAwesomeIcon className="icon" icon={faPlus} /> <span>Add Content</span>
                    </button>
                </div>
            </div>
        </div>
    )
}






const ContentTableParent = ({getActionloader, pageContents, alertNotification}) => {
    return (
        <div className="general-table">
            <table className="table table-hover">
                <thead>
                    <tr>
                        <th scope="col">Title <span>(222,000)</span></th>
                        <th scope="col">Image</th>
                        <th scope="col">Category</th>
                        <th scope="col">Text</th>
                        <th scope="col">Button</th>
                        <th scope="col">Position</th>
                        <th scope="col">Registered Date | Time</th>
                        <th scope="col">Action</th>
                    </tr>
                </thead>
                <tbody>
                    { pageContents.map((pageContent, index) => (<TabelContent key={index} alertNotification={alertNotification} getActionloader={getActionloader} pageContent={pageContent}/>))}
                </tbody>
            </table>
        </div>
    )
}

    


const TabelContent = ({getActionloader, pageContent, alertNotification}) => {

    return (
            <tr>
                <td>{pageContent.title}</td>
                <td>
                    <ContentImage getActionloader={getActionloader} alertNotification={alertNotification} pageContent={pageContent}/>
                </td>
                <td>{pageContent.category.category}</td>
                <td>{ HTMLReactParser(pageContent.text) }</td>
                <td>{pageContent.link}</td>
                <td>{pageContent.position}</td>
                <td>{DateTime(pageContent.updated_at, 'Do MMMM YYYY | h:mma')}</td>
                <td><ContentDropDown pageContent={pageContent}/></td>
            </tr>
    )
}




const ContentDropDown = () => {
    let optionRef = useRef()
    const [state, setState] = useState (false)

    const toggleOptions = () => {
        setState(true)
    }

    const handler = (e) => {
        if(optionRef.current && !e.composedPath().includes(optionRef.current)){
            setState(false)
        }
    }

    useEffect(() => {
        document.addEventListener('click', handler)
        return () => {
            document.removeEventListener('click', handler) // clears document.addEventListener('click', handler) from browser
        }
    }, [])
    return (
        <div ref={optionRef} className="table-drop-down">
            <FontAwesomeIcon  onClick={() => toggleOptions()} className="icon" icon={faEllipsis} />
            <div className={`drop-down ${state ? 'active' : ''}`}>
                <ul>
                    <li>
                        <FontAwesomeIcon  className="icon" icon={faPen} />
                        <span>Edit</span>
                    </li>
                    <li>
                        <FontAwesomeIcon  className="icon" icon={faEye} />
                        <span>Featured</span>
                    </li>
                    <li>
                        <FontAwesomeIcon  className="icon" icon={faCircle} />
                        <span>Button Active</span>
                    </li>
                    <li>
                        <FontAwesomeIcon  className="icon" icon={faTrash} />
                        <span>Delete</span>
                    </li>
                </ul>
            </div>
        </div>
    )
}





const ContentImage = ({getActionloader, pageContent, alertNotification}) => {
    const imageRef = useRef()

    const uploadContentImage = (event) => {
        if(event.target.files && event.target.files.length > 0){
            // getActionloader(true, 'Uploading image, please wait...')
            // upload image here
            const formData = new FormData()
            formData.append('contentID', pageContent._id)
            formData.append('image', event.target.files[0])
            Axios.post(url(`/api/admin/upload-page-content-image`), formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }).then((response) => {
                const data = response.data
                if(data.status === 'ok'){
                    console.log(data.imageName)
                    clearFileInput()
                    getActionloader(false)
                    alertNotification('success', 'Image uploaded sucessfully!')
                }
            }).catch(error => {
                console.log(error)
            })
        }
    }

    // clear file input
    const clearFileInput = () => {
        return imageRef.current.value = ''; 
    }

    const toggleImageInput = () => {
        return imageRef.current.click()
    }
    return (
        <div className="table-image">
            {
                pageContent.image.length ? (
                    <img onClick={() => toggleImageInput()} src={app_image('content-image-3.png')}  alt="content-1"/>
                ) : (<FontAwesomeIcon  onClick={() => toggleImageInput()} className="icon" icon={faCamera} />)
            }
            <input ref={imageRef} type="file" style={{ display: 'none' }} onChange={uploadContentImage} />
        </div>
    )
}



