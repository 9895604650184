import { useState, useEffect } from 'react'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
    faCircle,
    faArrowRight,
} from '@fortawesome/free-solid-svg-icons'
import { Chart as ChartJS } from "chart.js/auto";
import { Doughnut } from "react-chartjs-2"








const ContentRight = () => {
    const [countTotal, setCountTotal] = useState(1000)
    const [fontNormal, setFontNormal] = useState('')


    const counter = () => {
        let start = 50
        let duration = 100
        let initialCount = parseInt(countTotal)
        let value = initialCount > start ? (initialCount - start) : 0
        let count = setInterval(() => {
            value += 1
            setCountTotal(value)
            if(value >= (countTotal)){
                let amount = countTotal.toLocaleString()
                setCountTotal(amount)
                setFontNormal('normal')
                clearInterval(count)
            }
        }, duration)
   }




   useEffect(() => {
    counter()
   }, [])


    return (
        <div className="metrics-right-body">
            <div className="metrics-right-body-top">
                <Row className="show-grid">
                    <ContentRightInner title="Total Registered Users"  total={5470} percentage="" color='#fff' button={true}/>
                    <ContentRightInner title="User Retension Rate" total={0}  percentage="80.50%" color='#fff' button={false}/>
                </Row>
            </div>
            <div className="metrics-right-body-m">
                <div className="title-header">
                    <h3>User Metrics</h3>
                </div>
                <Row className="show-grid">
                    <Col xs={12} sm={12} md={6} lg={7} xl={7}>
                        <div className="left">
                            <div className="doughnut-chart">
                                <div className="title-header">
                                    <div className="inner">
                                        <h3 className={`total ${fontNormal}`}>{countTotal}</h3>
                                        <h3 className="title">total Users</h3>
                                    </div>
                                </div>
                                <Doughnut
                                    data={{
                                        labels: [],
                                        datasets: [
                                            {
                                                label: 'Data',
                                                data: [300, 100, 200],
                                                backgroundColor: [
                                                    'green',
                                                    'purple',
                                                    'orange'
                                                ],
                                                cutout: '90%',
                                                hoverOffset: 4
                                            }
                                        ]
                                    }}
                                />
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={6} lg={5} xl={5}>
                        <ContentRightRate title="Active Users" percentage={"67%"} total={1000} color="orange"/>
                        <ContentRightRate title="Active Users" percentage={"84%"} total={1000} color="purple"/>
                        <ContentRightRate title="Active Users" percentage={"28%"} total={1000} color="green"/>
                    </Col>
                </Row>
            </div>
        </div>
    )
}



export default ContentRight











const ContentRightRate = ({color, title, total, percentage }) => {
    let initialCount = parseInt(total)
    const [countTotal, setCountTotal] = useState(initialCount);

    const counter = () => {
        let start = 50
        let duration = 100
        let value = initialCount > start ? (initialCount - start) : 0
        let count = setInterval(() => {
            value += 1
            setCountTotal(value)
            if(value >= (initialCount)){
                let amount = total.toLocaleString()
                setCountTotal(amount)
                clearInterval(count)
            }
        }, duration)
   }

   useEffect(() => {
        counter()
   }, [])

    return (
        <div className="right">
            <h3 className="title-header">{title ? (title) : null}</h3>
            <h3 className="total">{countTotal} {percentage}</h3>
            <div className="circle-item">
                <FontAwesomeIcon style={{ color: color }} className="icon" icon={faCircle} />
            </div>
        </div>
    )
}








const ContentRightInner = ({color, total, percentage, title, button}) => {
    let initialCount = parseInt(total)
    const [countTotal, setCountTotal] = useState(initialCount);

    const counter = () => {
        let start = 50
        let duration = 100
        let value = initialCount > start ? (initialCount - start) : 0
        let count = setInterval(() => {
            value += 1
            setCountTotal(value)
            if(value >= (initialCount)){
                let amount = total.toLocaleString()
                setCountTotal(amount)
                clearInterval(count)
            }
        }, duration)
   }

   useEffect(() => {
        counter()
   }, [])

    return (
        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
            <div className="item">
                <h3 className="total">{total > 0 ? (countTotal) : null} {percentage ? (percentage) : null}</h3>
                <h3 className="title-header">{title ? (title) : null}</h3>
                {
                    button ? (
                        <div className="icon-right">
                            <FontAwesomeIcon style={{ color: color }} className="icon" icon={faArrowRight} />
                        </div>
                    ) : null
                }
            </div>
        </Col>
    )
}