import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import PageViews from './PageViews'
import SessionsByChannel from './SessionsByChannel'





const SessionsAndPageView = () => {
    return (
        <div className="sessions-pageview-container">
            <Row className="show-grid">
                <Col xs={12} sm={12} md={12} lg={12} xl={7}>
                    <SessionsByChannel/>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={5}>
                    <PageViews/>
                </Col>
            </Row>
        </div>
    )
}



export default SessionsAndPageView




