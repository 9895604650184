import { createSlice } from '@reduxjs/toolkit'


const PageContentSlice = createSlice({
    name: 'pageContents',
    initialState: {
        pageContents: []
    },
    reducers: {
        getPageContents: (state, action) => {
            state.pageContents = action.payload.map(content => {
                return {
                    _id: content._id,
                    category: content.category,
                    name: content.name,
                    button: content.button,
                    image: content.image,
                    title: content.title,
                    text: content.text,
                    link: content.link,
                    position: content.position,
                    is_featured: content.is_featured,
                    created_at: content.created_at,
                    updated_at: content.updated_at,
                }
            })
        },
        addPageContents: (state, action) => {
            state.pageContents.push(action.payload)
        }
    }
})





export const {
    getPageContents,
    addPageContents,
} = PageContentSlice.actions
export default PageContentSlice.reducer