import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import ContentLeft from './ContentLeft'
import ContentRight from './ContentRight'



const Metrics = () => {



    return (
        <div className="metrics-container">
            <div className="metrics-inner">
            <Row className="show-grid">
                <Col xs={12} sm={12} md={12} lg={12} xl={7}>
                    <ContentLeft/>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={5}>
                    <ContentRight/>
                </Col>
            </Row>
            </div>
        </div>
    )
}




export default Metrics








