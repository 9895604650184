import { useState, useEffect } from 'react'
import TopBar from './TopBar'
import AdminUserBody from './AdminUserBody'
import AddUser from './AddUser'
import MobileTable from './MobileTable'



const AdminUser = ({toggleFloatProfile}) => {
    const [formState, steFormState] = useState(false)
    
    const toggleAddMember = (state) => {
        return steFormState(state)
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className="general-table-container">
            {
                formState === true ? (<AddUser toggleAddMember={toggleAddMember}/>) : null
            }
            <TopBar/>
            <AdminUserBody toggleFloatProfile={toggleFloatProfile} toggleAddMember={toggleAddMember}/>
            <MobileTable toggleFloatProfile={toggleFloatProfile}/>
        </div>
    )
}

export default AdminUser




