import { createSlice } from '@reduxjs/toolkit'


const pageCategorySlice = createSlice({
    name: 'pageCategories',
    initialState: {
        pageCategories: []
    },
    reducers: {
        getPageCategories: (state, action) => {
            state.pageCategories = action.payload.map(pageCategory => {
                return {
                    _id: pageCategory._id,
                    category: pageCategory.category,
                    bottom_banner_active: pageCategory.bottom_banner_active,
                    content_banner_active: pageCategory.content_banner_active,
                    testimonial_active: pageCategory.testimonial_active,
                    is_featured: pageCategory.is_featured,
                    created_at: pageCategory.created_at,
                    updated_at: pageCategory.updated_at,
                }
            })
        },
        addPageCategories: (state, action) => {
            state.pageCategories.push(action.payload)
        },
        deletePageCategories: (state, action) => {
            const _id = action.payload._id
            state.pageCategories = state.pageCategories.filter(category => category._id !== _id)
        },
        updatePageCategories: (state, action) => {
            if(state.pageCategories !== undefined){
                const index = state.pageCategories.findIndex(category => category._id === action.payload._id)
                state.pageCategories[index] = action.payload
            }
        }
    }
})


export const {
    getPageCategories,
    addPageCategories,
    deletePageCategories,
    updatePageCategories,
} = pageCategorySlice.actions
export default pageCategorySlice.reducer