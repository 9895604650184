import Axios from 'axios'
import { useState, useEffect, useRef } from 'react'
import { NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
    faPlus,
    faPen,
    faTrash,
    faToggleOn,
    faToggleOff,
    faEllipsis,
} from '@fortawesome/free-solid-svg-icons'
import TableBottom from './TableBottom'
import { DateTime, url  } from '../../File'
import EmptyContent from '../common/EmptyContent'




const PageCategoryBody = ({ updated, toggleDeletePageCategoryModal, toggleEditCategory, alertNotification, categories, toggleAddCategory}) => {
    

    return (
        <div className="general-table-body">
            <div className="inner-body">
                <SearchBar toggleAddCategory={toggleAddCategory}/>
                { categories ? (<ContentTableParent updated={updated} toggleDeletePageCategoryModal={toggleDeletePageCategoryModal} toggleEditCategory={toggleEditCategory} alertNotification={alertNotification} categories={categories} />) : (<EmptyContent text={"There are no Page categories yet!"}/>) }
                { categories ? (<TableBottom/>)  : null }
            </div>
        </div>
    )
}



export default PageCategoryBody





const SearchBar = ({toggleAddCategory}) => {
    return (
        <div className="search-bar">
            <div className="bar-left">
                <h3 className="title-header">Search Categories</h3>
            </div>
            <div className="bar-right">
                <div className="form">
                    <input type="text" className="" placeholder="Search categories"/>
                    <div className="add-member-btn">
                        <NavLink to="/admin/add-page-category">
                            <FontAwesomeIcon className="icon" icon={faPlus} /> <span>Add Category</span>
                        </NavLink>
                    </div>
                    <button onClick={() => toggleAddCategory(true)} type="button" className="admin-admin-btn">
                        <FontAwesomeIcon className="icon" icon={faPlus} /> <span>Add Category</span>
                    </button>
                </div>
            </div>
        </div>
    )
}






const ContentTableParent = ({ updated, toggleDeletePageCategoryModal, toggleEditCategory, alertNotification, categories }) => {
    return (
        <div className="general-table">
            <table className="table table-hover">
                <thead>
                    <tr>
                        <th scope="col">Categories <span>({categories.length})</span></th>
                        <th scope="col">Content Banner</th>
                        <th scope="col">Featured</th>
                        <th scope="col">Bottom Banner</th>
                        <th scope="col">Testimonial</th>
                        <th scope="col">Created at</th>
                        <th scope="col">Updated at</th>
                        <th scope="col">Options</th>
                    </tr>
                </thead>
                <tbody>
                    { categories.map((category, index) => (<TabelContent key={index} category={category} updated={updated} toggleDeletePageCategoryModal={toggleDeletePageCategoryModal} toggleEditCategory={toggleEditCategory} alertNotification={alertNotification}/>)) }
                </tbody>
            </table>
        </div>
    )
}

    


const TabelContent = ({ toggleEditCategory, toggleDeletePageCategoryModal, alertNotification, category, updated}) => {
    const [is_featured, setIs_featured] = useState(category.is_featured)
    const [is_testimonial, setIs_testimonial] = useState(category.testimonial_active)
    const [is_cntBanner, setIs_cntBanner] = useState(category.content_banner_active)
    const [is_btmBanner, setIs_btmBanner] = useState(category.bottom_banner_active)
    
    const toggleState = (_id, content) => {
        Axios.post(url('/api/admin/toggle-page-categories'), {_id: _id, content: content}).then((response) => {
            const data = response.data
            if(data.status === 'ok'){
                if(data.content === 'featured'){
                    return setIs_featured(!is_featured)
                }
                if(data.content === 'testimonial'){
                    return setIs_testimonial(!is_testimonial)
                }
                if(data.content === 'contentBanner'){
                    return setIs_cntBanner(!is_cntBanner)
                }
                if(data.content === 'bottomBanner'){
                    return setIs_btmBanner(!is_btmBanner)
                }
            }else if(data.status === 'error'){
                alertNotification('error', data.error)
            }
        })
    }

    return (
            <tr>
                <td>{ updated._id === category._id ? updated.category : category.category }</td>
                <td>
                    <FontAwesomeIcon onClick={() => toggleState(category._id, 'contentBanner')} className={`toggle icon ${is_cntBanner ? 'active' : '' }`} icon={is_cntBanner ? faToggleOn : faToggleOff} />
                </td>
                <td>
                    <FontAwesomeIcon onClick={() => toggleState(category._id, 'featured')} className={`toggle icon ${is_featured ? 'active' : '' }`} icon={is_featured ? faToggleOn : faToggleOff} />
                </td>
                <td>
                    <FontAwesomeIcon onClick={() => toggleState(category._id, 'bottomBanner')} className={`toggle icon ${is_btmBanner ? 'active' : '' }`} icon={is_btmBanner ? faToggleOn : faToggleOff} />
                </td>
                <td>
                    <FontAwesomeIcon onClick={() => toggleState(category._id, 'testimonial')} className={`toggle icon ${is_testimonial ? 'active' : '' }`} icon={is_testimonial ? faToggleOn : faToggleOff} />
                </td>
                <td>{DateTime(category.created_at, 'Do MMMM YYYY | h:mma')}</td>
                <td>
                    {DateTime(updated._id === category._id ? updated.updated_at : category.updated_at, 'Do MMMM YYYY | h:mma') }</td>
                <td>
                    <ContentDropDown category={category} updated={updated} toggleDeletePageCategoryModal={toggleDeletePageCategoryModal} toggleEditCategory={toggleEditCategory}/>
                </td>
            </tr>
    )
}




const ContentDropDown = ({category, updated, toggleEditCategory, toggleDeletePageCategoryModal}) => {
    let optionRef = useRef()
    const [state, setState] = useState (false)
    let categoryName = updated.category ? updated : category

    const toggleOptions = () => {
        setState(true)
    }

    const handler = (e) => {
        if(optionRef.current && !e.composedPath().includes(optionRef.current)){
            setState(false)
        }
    }

    useEffect(() => {
        document.addEventListener('click', handler)
        return () => {
            document.removeEventListener('click', handler) // clears document.addEventListener('click', handler) from browser
        }
    }, [])
    return (
        <div ref={optionRef} className="table-drop-down">
            <FontAwesomeIcon  onClick={() => toggleOptions()} className="icon" icon={faEllipsis} />
            <div className={`drop-down ${state ? 'active' : ''}`}>
                <ul>
                    <li onClick={() => toggleEditCategory(true, categoryName)}>
                        <FontAwesomeIcon  className="icon" icon={faPen} />
                        <span>Edit</span>
                    </li>
                    <li onClick={() => toggleDeletePageCategoryModal(true, true, category._id)}>
                        <FontAwesomeIcon  className="icon" icon={faTrash} />
                        <span>Delete</span>
                    </li>
                </ul>
            </div>
        </div>
    )
}




