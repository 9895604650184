import Moment from "moment";




// data base url link
const clientURI = 'http://localhost:3000'
const databaseURI = 'http://localhost:3001'
let productionClientURL = 'https://usepayizzy.com'
let productionServerURL = 'https://server.usepayizzy.com'





const today = () => {
  let today = new Date().toISOString()
  return today
}




const DateTime = (time, format) => {
    // example of format "dddd h:mma D MMM YYYY"
    let x = ''
    if(time && format){
        x =  Moment(time).format(format)
    }
    return x
}



const url = (string = null) => { 
    const url = window.location.href.split('/')[2]
    const url_name = url.split(':')[0]
    if(url_name === 'localhost'){
        return databaseURI + string
    }
    return productionServerURL + string
}


const ClientUrl = (string = null) => { 
    const url = window.location.href.split('/')[2]
    const url_name = url.split(':')[0]
    if(url_name === 'localhost'){
        return clientURI + string
    }
    return productionClientURL + string
}

// const url = (string = null) => { 
//     const url = window.location.href.split('/')
//     if(url[0] === "http"){
//         return databaseURI + string
//     }
//     return productionServerURL + string
// }


const page = (string) => {
    let state = false
    const url = window.location.href.split('/')
    for(let i = 0; i < url.length; i++){
        if(url[i] === string){
            state = true
        }
    }
    return state
}


// web app logo
const logo = (string) => {
    return ClientUrl('/asset/client/images/logo/' + string)
}



// app image 
const app_image = (string) => {
    return ClientUrl('/asset/client/images/app-image/' + string)
}




const blog_img = (string) => {
    return ClientUrl('/asset/client/images/blog/' + string)
}



const user_img = (string) => {
    return ClientUrl('/asset/client/images/users/' + string)
}


const stack_img = (string) => {
    return ClientUrl('/asset/client/images/our-stack/' + string)
}


const contact_img = (string) => {
    return ClientUrl('/asset/client/images/contact/' + string)
}



const help_img = (string) => {
    return ClientUrl('/asset/client/images/help/' + string)
}



const preloader = (string) => {
    return ClientUrl('/asset/preloader/' + string)
}



const icons = (string) => {
    return ClientUrl('/asset/client/images/icons/' + string)
}




const our_partner_img = (string) => {
    return ClientUrl('/asset/client/images/our-partners/' + string)
}




// ***************** ADMIN SECTION ***************
const admin_logo = (string) => {
    return ClientUrl('/asset/admin/image/logo/' + string)
}

const  preloaderImg = (string) => {
    return ClientUrl('/asset/admin/preloader/' + string)
}



const  sounds = (string) => {
    return ClientUrl('/asset/sounds/' + string)
}



export {
    url,
    logo,
    today,
    page,
    icons,
    sounds,
    DateTime,
    stack_img,
    help_img,
    user_img,
    blog_img,
    app_image,
    preloader,
    admin_logo,
    contact_img,
    preloaderImg,
    our_partner_img,
}