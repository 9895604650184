import { useEffect } from 'react'
import Banner from './Banner'
import Content from './Content'
import DownloadApp from '../extras/DownloadApp'
import OurTribes from './OurTribes'
import OurValues from './OurValues'
import OurStack from './OurStack'






const Agent = ({Contentloader}) => {
    useEffect(() => {
        window.scrollTo(0, 0) // page scroll to top
      }, [])

    return (
        <div className="personal-container">
            <Banner />
            <OurTribes/>
            <Content />
            <OurValues/>
            <OurStack/>
            <DownloadApp/>
        </div>
    )
}




export default Agent



