
import ProfileTop from './ProfileTop'
import ProfileBody from './ProfileBody'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
    faTimes,
} from '@fortawesome/free-solid-svg-icons'
import MobileTable from './MobileTable'






const FloatProfile = ({floatProfile, toggleFloatProfile, toggleModal}) => {
    return (
        <div className={`general-table-container float-profile ${floatProfile ? 'active' : ''}`}>
            <div className="float-profile-button">
                <button onClick={() => toggleFloatProfile(false)}>
                    <FontAwesomeIcon className="icon" icon={faTimes} />
                </button>
            </div>
            <div className="user-profile-content">
                <ProfileTop toggleModal={toggleModal}/>
                <ProfileBody/>
                <MobileTable/>
            </div>
        </div>
    )
}


export default FloatProfile



