import { useState, useEffect, useRef } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
    faEye,
    faEyeSlash
} from '@fortawesome/free-solid-svg-icons'
import { admin_logo }  from '../../File'




const Login = ({setNavState}) => {
    const removeNavEffect = useRef()
    const [passwordVisibility, setPasswordVisibility] = useState(false)

    const toggleType = () => {
        setPasswordVisibility(!passwordVisibility)
    }

    const removeNav = () => {
        setNavState(false)
    }
    
    removeNavEffect.current = removeNav

    useEffect(() => {
        removeNavEffect.current()
    }, [])

    return (
        <div className="auth-container">
           <BackImage/>
            <LoginForm passwordVisibility={passwordVisibility} toggleType={toggleType}/>
        </div>
    )
    
}


export default Login







const BackImage = () => {
    return (
        <div className="auth-back-img">
            <img src={admin_logo('full-logo.png')} alt="auth-logo"/>
        </div>
    )
}




const LoginForm = ({passwordVisibility, toggleType}) => {
    return (
        <div className="middile-container">
            <div className="title-header">
                <h3>Welcome!</h3>
                <p>Enter email and password to gain access</p>
            </div>
            <div className="form">
                <div className="form-group">
                    <label>Email</label>
                    <input type="email" placeholder="Enter Email"/>
                </div>
                <div className="form-group">
                    <label>Password</label>
                    <div className="password-field">
                        <input type={passwordVisibility ? 'text' : 'password' } className="password" placeholder="Enter Password"/>
                        <FontAwesomeIcon onClick={() => toggleType()} className="visible" icon={passwordVisibility ? faEye : faEyeSlash} />
                    </div>
                </div>
                <div className="form-group">
                    <button>GAIN ACCESS</button>
                </div>
            </div>
        </div>
    )
}