import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { user_img } from '../../File'
import { useEffect } from 'react'
import AOS from 'aos'




const OurTribes = () => {
    const header = {
        title: 'The Izzy Tribe',
        text: 'Meet the izzy team behind the only money app you need!',
    }

    const tribes = [
        {
            _id: 1,
            image: 'kossy.png',
            first_name: 'Kossy',
            last_name: 'Okonkwo',
            job_title: 'CEO',
            is_featured: 1,
        },
        {
            _id: 2,
            image: 'kolade.png',
            first_name: 'Kolade',
            last_name: 'Kolawole',
            job_title: 'Product Designer',
            is_featured: 1,
        },
        {
            _id: 3,
            image: '16.png',
            first_name: 'Udeme',
            last_name: '',
            job_title: 'Product Manager',
            is_featured: 1,
        },
        {
            _id: 4,
            image: '14.png',
            first_name: 'Kolapo',
            last_name: 'Kolawole',
            job_title: 'Backend Developer',
            is_featured: 1,
        },
        {
            _id: 5,
            image: 'paul.png',
            first_name: 'Paul',
            last_name: 'Adesola',
            job_title: 'Product Designer',
            is_featured: 1,
        },
        {
            _id: 6,
            image: '12.png',
            first_name: 'Isaiah',
            last_name: '',
            job_title: 'Product Manager',
            is_featured: 1,
        },
        {
            _id: 7,
            image: 'charles.png',
            first_name: 'Charles',
            last_name: 'Anonye',
            job_title: 'Front-end Developer',
            is_featured: 1,
        },
        {
            _id: 8,
            image: '15.png',
            first_name: 'Queen',
            last_name: '',
            job_title: 'Application Tester',
            is_featured: 1,
        },
    ]


    useEffect(() => {
        AOS.init({duration: 2000 })

    }, [])

  return (
    <div className="our-tribes-container">
      <TitleHeader header={header}/>
      <TribeContainer tribes={tribes}/>
    </div>
  )
}

export default OurTribes







const TitleHeader = ({header}) => {
    return (
        <div className="page-title-header text-center">
            <h3>{header.title}</h3>
            <p>{header.text}</p>
        </div>
    )
}



const TribeContainer = ({tribes}) => {
    return (
        <div className="tribe-card">
             <Row className="show-grid">
                { tribes.map((tribe, index) => (<ContentItem key={index}  tribe={tribe}/>)) }
            </Row>
        </div>
    )
}





const ContentItem = ({tribe}) => {
    return (
        <Col data-aos={'slide-up'} xs={12} sm={6} md={6} lg={4} xl={3}>
            <div className="content-tribe-item">
                <div className="image">
                    <img src={user_img(tribe.image)} alt={tribe.image}/>
                </div>
                <ul>
                    <li className="title">{`${tribe.last_name} ${tribe.first_name}`}</li>
                    <li className="job-title">{tribe.job_title}</li>
                </ul>
            </div>
        </Col>
    )
}