import React, {useEffect}from 'react'
import { NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
    faCheck
 } from '@fortawesome/free-solid-svg-icons'
 import HTMLReactParser from 'html-react-parser'
 import AOS from 'aos'



const Steps = ({steps}) => {
    // const steps = [
    //     {
    //         title: "Send Money Using QR Codes",
    //         description: "Cardless transactions are the safest and easiest way to send money with no failed transactions. Simply scan the codes of your clients and loved ones with Payizzy's inbuilt QR scanner to send money securely. Input the amount and that's all you need. All in 3 steps.", 
    //         link: "/",
    //         is_featured: 1,
    //         created_at: "2023-09-11T21:00:51.904+00:00",
    //         updated_at: "2023-09-11T21:00:51.904+00:00"
    //     },
    //     {
    //         title: "Receive Money Using QR Codes",
    //         description: "Generate your unique QR codes instantly for payments. Share your codes to your clients, friends and family digitally or print it out for quick scanning. Receive your money in seconds", 
    //         link: "/",
    //         is_featured: 1,
    //         created_at: "2023-09-11T21:00:51.904+00:00",
    //         updated_at: "2023-09-11T21:00:51.904+00:00"
    //     },
    //     {
    //         title: "Withdraw Money Using QR Code",
    //         description: "If you need to get cash, don't worry, we got you. Simply withdraw with any Payizzy agent by scanning their codes and transferring the amount you need to them", 
    //         link: "/",
    //         is_featured: 1,
    //         created_at: "2023-09-11T21:00:51.904+00:00",
    //         updated_at: "2023-09-11T21:00:51.904+00:00"
    //     },
    //     {
    //         title: "Send and Receive Money with Izzy Usernames",
    //         description: "Usernames are cool and you can also send money to others using their usernames. Of course you can get money with your Izzy usernames as well", 
    //         link: "/",
    //         is_featured: 1,
    //         created_at: "2023-09-11T21:00:51.904+00:00",
    //         updated_at: "2023-09-11T21:00:51.904+00:00"
    //     },
    //     {
    //         title: "You Don't Need a Card",
    //         description: "Have the ease and flexibility of not carrying cards everywhere you go. You worked hard for your money, move it the easy way with Payizzy", 
    //         link: "",
    //         is_featured: 1,
    //         created_at: "2023-09-11T21:00:51.904+00:00",
    //         updated_at: "2023-09-11T21:00:51.904+00:00"
    //     },
    //     {
    //         title: "Make Free Transfers",
    //         description: "As long as you're sending money to an izzy triber, it's completely free. Save money on charges by copping all free transfers. Get all your friends and family to join the Izzy tribe There's the regular way of moving money, and there's the Izzy way. Use Payizzy today Add testimonials and partners here", 
    //         link: "",
    //         is_featured: 1,
    //         created_at: "2023-09-11T21:00:51.904+00:00",
    //         updated_at: "2023-09-11T21:00:51.904+00:00"
    //     },
    // ]

 
    useEffect(() => {
        AOS.init({ duration: 3000 })
    }, [])

  return (
    <div className="page-content-steps">
        <div className="inner-page-content-steps">
            <div className="steps-content">
                <ul>
                    { steps.map((step, index) => (<StepsContent key={index} number={index+1} step={step}/>)) }
                </ul>
            </div>
        </div>
    </div>
  )
}

export default Steps











const StepsContent = ({number, step}) => {
    return (
        <li data-aos={number % 2 === 0 ? 'slide-left' : 'fade-right'}>
            <div className="left">
                <FontAwesomeIcon  className="icon" icon={faCheck} />
            </div>
           <div className="right">
                <div className="title">{step.title}</div>
                <div className="body">{step.description ? (HTMLReactParser(step.description)) : null }</div>
                {
                    step.link ? (
                        <div className="link">
                            <NavLink to={step.link}> See FAQ</NavLink>
                        </div>
                    ) : null 
                }
           </div>
        </li>
    )
}