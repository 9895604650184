import { useEffect } from 'react'
import TopBar from './TopBar'
import LoanRequestBody from './LoanRequestBody'
import MobileTable from './MobileTable'


const LoanRequest = ({toggleFloatProfile}) => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className="general-table-container">
            <TopBar/>
            <LoanRequestBody toggleFloatProfile={toggleFloatProfile}/>
            <MobileTable toggleFloatProfile={toggleFloatProfile}/>
        </div>
    )
}

export default LoanRequest