


const AdminProfile = () => {
    return (
        <div className="">No designed for this page yet...</div>
    )
}


export default AdminProfile