import { useEffect } from 'react'
import AOS from 'aos'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {  our_partner_img } from '../../File'









const OurPartners = () => {
    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 6
        },
        desktop: {
          breakpoint: { max: 3000, min: 993 },
          items: 5
        },
        tablet: {
          breakpoint: { max: 992, min: 568 },
          items: 4
        },
        mobile: {
          breakpoint: { max: 567, min: 0 },
          items: 2
        }
      };



      const ourPartners = [
        {
            name: "paypal",
            image: "paypal.png"
        },
        {
            name: "mtn",
            image: "mtn.png"
        },
        {
            name: "behance",
            image: "behance.png"
        },
        {
            name: "paystack",
            image: "paystack.png"
        },
        {
            name: "kuda",
            image: "kuda.png"
        },
      ]

    useEffect(() => {
        AOS.init({ duration: 2000 })
    })



  return (
    <div data-aos={'fade-up-left'} className="our-partners-container">
        <div className="title-header">
            <h3>Our Partners</h3>
        </div>
        <div className="slider-frame">
            <Carousel 
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={5000}
            responsive={responsive}>
                
                {
                    ourPartners.map((ourPartner, index) => (<SliderContent key={index} ourPartner={ourPartner}/>))
                }
            </Carousel>
        </div>
    </div>
  )
}

export default OurPartners




const SliderContent = ({ourPartner}) => {
    return (
        <div className="partners-slider-item">
            <div className="image">
                <img src={our_partner_img(ourPartner.image)} alt={ourPartner.image}/>
            </div>
        </div>
    )
}