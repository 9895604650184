import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import {  user_img } from '../../File'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
    faCircleCheck
} from '@fortawesome/free-solid-svg-icons'
import TableBottom from './TableBottom'





const MobileTable = () => {
    
    return(
        <div className="mobile-table-content">
            <div className="mobile-general-table">
                <div className="title-header">
                    <h3>User <span>(273,890)</span></h3>
                </div>
                <div className="general-table-mobile-body">
                    <TableContent/>
                    <TableContent/>
                    <TableContent/>
                    <TableContent/>
                    <TableContent/>
                </div>
            </div>
            <TableBottom/>
        </div>
    )
}




export default MobileTable





const TableContent = () => {
    return (
        <div className="table-content">
            <Row className="show-grid">
                <Col xs={12} sm={6} md={6} lg={6} xl={6}><TableContentLeft /></Col>
                <Col xs={12} sm={6} md={6} lg={6} xl={6}><TableContentRight/></Col>
            </Row>
        </div>
    )
}



const TableContentLeft = () => {
    return (
            <div className="table-content-left">
                <div className="left">
                 {/* <div className="no-img" style={{ backgroundColor: 'green' }}><span>R</span></div> */}
                 <div className="img">
                    <img src={user_img('9.png')} alt="user-img"/>
                </div>
                <div className="left-detail">
                    <ul>
                        <li className="name">
                            <h3>John Doe</h3>
                            <p>Acc No: 1100223344</p>
                        </li>
                        <li className="list">
                            <span>Description: </span> Wallet Topup
                        </li>
                        <li className="list">
                            <span>Transaction: </span> Credit
                        </li>
                        <div className="toggle-item">
                            <li className="list">
                                <span>Amount: </span> NGN 200,000
                            </li>
                            <li className="list">
                                <span>Time Stamp: </span> 24th february 2025 | 5:30am
                            </li>
                            <li className="list">
                                <span>Status: </span>
                                <FontAwesomeIcon className="icon success" icon={faCircleCheck} />
                                <span className="success">Verified</span>
                            </li>
                        </div>
                    </ul>
                </div>
            </div>
        </div>
    )
}






const TableContentRight = () => {
    return (
        <div className="table-content-right">
            <ul>
                <li className="list">
                    <span>Amount: </span> NGN 200,000
                </li>
                <li className="list">
                    <span>Time Stamp: </span> 24th february 2025 | 5:30am
                </li>
                <li className="list">
                    <span>Status: </span>
                    <FontAwesomeIcon className="icon success" icon={faCircleCheck} />
                    <span className="success">Verified</span>
                </li>
            </ul>
        </div>
    )
}





























