
import { NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
    faCircleCheck,
} from '@fortawesome/free-solid-svg-icons'
import {  user_img } from '../../File'
import SearchBar from './SearchBar'
import TableBottom from './TableBottom'


const ProfileBody = () => {
    return (
        <div className="general-table-body">
            <div className="inner-body">
                <SearchBar/>
                <TransactionTable/>
                <TableBottom/>
            </div>
        </div>
    )
}



export default ProfileBody












const TransactionTable = () => {
    return (
        <div className="general-table table-responsive">
            <table className="table table-hover">
                <thead>
                    <tr>
                        <th scope="col">Name <span>(222,000)</span></th>
                        <th scope="col">Phone Number <span>(7)</span></th>
                        <th scope="col">Total Referals</th>
                        <th scope="col">Gender</th>
                        <th scope="col">Action</th>
                    </tr>
                </thead>
                <tbody>
                    <TabelContent/>
                    <TabelContent/>
                    <TabelContent/>
                    <TabelContent/>
                    <TabelContent/>
                    <TabelContent/>
                    <TabelContent/>
                    <TabelContent/>
                    <TabelContent/>
                    <TabelContent/>
                    <TabelContent/>
                </tbody>
            </table>
        </div>
    )
}

    


const TabelContent = () => {
    return (
            <tr>
                <td>
                    <div className="user-detail">
                        <div className="left">
                            <div className="no-img" style={{ backgroundColor: 'orange'}}><span>R</span></div>
                            {/* <div className="img">
                                <img src={user_img('9.png')} alt="user-img"/>
                            </div> */}
                        </div>
                        <div className="right">
                            <NavLink to="/admin/profile">
                                <h3>John Doe</h3>
                            </NavLink>
                            <p>Acc No: 1100223344</p>
                        </div>
                    </div>
                </td>
                <td>+2347023456789</td>
                <td>3</td>
                <td>Unknown</td>
                <td className="status">
                    <span><FontAwesomeIcon className="icon success" icon={faCircleCheck} /></span> Success
                </td>
            </tr>
    )
}