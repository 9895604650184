import { useEffect, useState, useRef } from 'react'
import Axios from 'axios'
import { url } from '../../File'
import Banner from './Banner'
import ContentLinks from './ContentLinks'
import HowItWorks from './HowItWorks'
import Content from './Content'
import Banner2 from './Banner2'
import Testimonial from '../home/Testimonial'
import Banner3 from './Banner3'




const Personal = ({Contentloader}) => {
    const FetchBannerRef = useRef(null)
    const [banner, setBanner] = useState([])

    // Fetch personal banner
    const FetchBanner = () => {
        Contentloader(true)
        Axios.get(url('/api/fetch-personal-banner/personal-top-banner')).then((response) => {
            const data = response.data
            if(data.status){
                if(data.banner){
                    return setBanner(data.banner)
                }
            }
            Contentloader(false)
            return setBanner([])
        })
    }


    FetchBannerRef.current = FetchBanner

    useEffect(() => {
        
        FetchBannerRef.current()
        
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant", // Optional if you want to skip the scrolling animation
        })

      }, [])

    return (
        <div className="personal-container">
            <Banner banner={banner}/>
            <ContentLinks/>
            <HowItWorks/>
            <Content/>
            <Banner2/>
            <Testimonial/>
            <Content/>
            <Banner3/>
        </div>
    )
}




export default Personal