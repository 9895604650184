import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Chart as ChartJS } from "chart.js/auto";
import { Doughnut } from "react-chartjs-2"
import { useState, useEffect } from 'react'




const SessionsByChannel = () => {
    return (
        <div className="sessions-by-channel">
            <div className="title-header">
                <h3>Sessions By Channel</h3>
            </div>
            <Row className="show-grid">
                <Col xs={12} sm={12} md={12} lg={6} xl={6}><ContentLeft/></Col>
                <Col xs={12} sm={12} md={12} lg={6} xl={6}><ContentRight/></Col>
            </Row>
        </div>
    )
}



export default SessionsByChannel









const ContentLeft = () => {
    return (
        <div className="sessions-doughnut">
            <Doughnut
                data={{
                    labels: [],
                    datasets: [
                        {
                            label: 'Data',
                            data: [200, 50, 100, 100, 100],
                            backgroundColor: [
                                'green',
                                'purple',
                                'orange',
                                'pink',
                                'red'
                            ],
                            cutout: '40%',
                            hoverOffset: 4
                        }
                    ]
                }}
            />
        </div>
    )
}






const ContentRight = () => {
    return (
        <div className="horizontal-chart">
            <BarRating backgroundColor={'orange'} title={'Organic Search'} amount={'110,112'} percentage={'90%'}/>
            <BarRating backgroundColor={'#6F58E9'}  title={'Email'} amount={'210,612'} percentage={'70%'}/>
            <BarRating backgroundColor={'green'} title={'Referral'} amount={'310,152'} percentage={'50%'}/>
            <BarRating backgroundColor={'pink'} title={'Social Media'} amount={'50,192'} percentage={'30%'}/>
            <BarRating backgroundColor={'red'} title={'Other'} amount={'10,132'} percentage={'10%'}/>
        </div>
    )
}




const BarRating = ({ backgroundColor, title, amount, percentage}) => {
    return (
        <div className="chart-bar-item">
            <style>
                {`
                    @keyframes progree {

                        0% {width: 0;}
                        ${percentage} {width: ${percentage};}
                    }
                `}
            </style>
            <div className="title">
                <h3>{title}</h3>
                <h3>{amount} <span>({percentage})</span></h3>
            </div>
            <div className="horizontal-bar">
                <div style={{ backgroundColor: backgroundColor, width: percentage }} className="bar"></div>
            </div>
        </div>
    )
}