

const FormAlertError = ({alert}) => {
    return (
        <div className="alert-error">
            {
                alert.length > 0 ? (<span>{alert}</span>) : null
            }
        </div>
    )
}




export default FormAlertError