





const EmptyContent = ({text}) => {
    return (
        <div className="empty-content-container">
            <div className="title-header">
                <h3>{text}</h3>
            </div>
        </div>
    )
}



export default EmptyContent