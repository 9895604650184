import { useState } from 'react'




const MobileAddPageCategory = () => {
    return (
        <div className="add-admin-container mobile-view">
            <div className="form">
                <TitleHeader/>
                <div className="form-body">
                    <div className="form-group">
                        <label>Category</label>
                        <input type="text" className="" placeholder="Enter Category"/>
                    </div>
                    <div className="form-button">
                        <button type="button">CREATE CATEGORY</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MobileAddPageCategory







const TitleHeader = () => {
    return (
        <div className="title-header">
            <h3>Add New Category</h3>
        </div>
    )
}



