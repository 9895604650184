import { useState, useEffect } from 'react'
import TopBar from './TopBar'
import PageCategoryBody from './PageCategoryBody'
import AddPageCategory from './AddPageCategory'
import EditPageCategory from './EditPageCategory'
import MobileTable from './MobileTable'
import Axios from 'axios'
import { url } from '../../File'
import { useDispatch, useSelector } from 'react-redux'
import { getPageCategories } from '../redux/pageCategorySlice'






const PageCategory = ({toggleDeletePageCategoryModal, alertNotification}) => {
    const [id, setId] = useState('')
    const [name, setName] = useState('')
    const [updated, setUpdated] = useState('')
    const [formState, setFormState] = useState(false)
    const [formEditState, setFormEditState] = useState(false)


    // react hooks
    const dispatch = useDispatch()
    const categories = useSelector(state => state.pageCategories.pageCategories)
    
    const toggleAddCategory = (state) => {
        return setFormState(state)
    }

    const toggleEditCategory = (state, item = '') => {
        if(item){
            setId(item._id)
            setUpdated('')
            setName(item.category)
        }
        return setFormEditState(state)
    }



   





    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant", // Optional if you want to skip the scrolling animation
        })

        // using redux
        const FetchPageCategory = () => {
            Axios.get(url('/api/admin/fetch-page-categories')).then((response) => {
                const data = response.data
                if(data.status === 'ok'){
                    dispatch(getPageCategories(data.categories))
                }
            }).catch(error => {
                console.log(error)
            })
        }
        FetchPageCategory()

    }, [])

    return (
        <div className="general-table-container">
            { formState === true ? (<AddPageCategory toggleAddCategory={toggleAddCategory} alertNotification={alertNotification}/>) : null }
            { formEditState === true ? (<EditPageCategory id={id} name={name} setName={setName} setUpdated={setUpdated} alertNotification={alertNotification} toggleEditCategory={toggleEditCategory}/>) : null }
            <TopBar/>
            <PageCategoryBody updated={updated} toggleDeletePageCategoryModal={toggleDeletePageCategoryModal} alertNotification={alertNotification} categories={categories} toggleEditCategory={toggleEditCategory} toggleAddCategory={toggleAddCategory}/>
            <MobileTable />
        </div>
    )
}

export default PageCategory




