import { NavLink } from 'react-router-dom'
import ReactCountryFlag from "react-country-flag";





const VisitByCountry = () => {
    return (
        <div className="general-table-body">
            <div className="general-table table-responsive">
                <div className="main-title-header">
                    <h3>Visited by country</h3>
                </div>
                <table className="table table-hover">
                    <thead>
                        <tr>
                            <th scope="col">Country <span>(222,000)</span></th>
                            <th scope="col">Entrances</th>
                            <th scope="col">Bounce Rate</th>
                            <th scope="col">Exits</th>
                        </tr>
                    </thead>
                    <tbody>
                        <TabelContent country={'Nigeria'} flag={'NG'}/>
                        <TabelContent country={'America'} flag={'US'}/>
                        <TabelContent country={'Ghana'} flag={'GN'}/>
                        <TabelContent country={'Senegal'} flag={'SN'}/>
                        <TabelContent country={'Canada'} flag={'CA'}/>
                        <TabelContent country={'China'} flag={'CH'}/>
                        <TabelContent country={'France'} flag={'FR'}/>
                    </tbody>
                </table>
                <div className="table-show-more">
                    <NavLink to="/admin/dashboard">
                        Show more
                    </NavLink>
                </div>
            </div>
        </div>
    )
}




export default VisitByCountry











const TabelContent = ({ flag, country }) => {
    return (
            <tr>
                <td>
                    <div className="user-detail">
                        <div className="left">
                            <NavLink to="/admin/profile">
                                <ReactCountryFlag className="icon country-flag" countryCode={flag} svg />
                            </NavLink>
                        </div>
                        <div className="right">
                            <NavLink to="/admin/profile">
                                <h3>{country}</h3>
                            </NavLink>
                        </div>
                    </div>
                </td>
                <td className="entrances">134<span>(1.51%)</span></td>
                <td>33.58%</td>
                <td>15.47%</td>
            </tr>
    )
}