import { useState, useRef, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
    faAngleDown,
    faAngleRight,
    faAngleLeft
} from '@fortawesome/free-solid-svg-icons'


const TableBottom = () => {
    return (
        <div className="table-bottom-container">
            <div className="table-bottom">
                <ContentLeft/>
                <ContentRight/>
            </div>
        </div>
    )
}


export default TableBottom






const ContentLeft = () => {
    let formRef = useRef()
    const [isActive, setIsActive] = useState(false)
    const [action, setAction] = useState(30)


    const toggleSelectForm = () => {
        setIsActive(true)
    }

    const updateField = (string) => {
        setAction(string)
        return setIsActive(false)
    }

    const handler = (e) => {
        if(formRef.current && !e.composedPath().includes(formRef.current)){
            setIsActive(false)
        }
    }


    useEffect(() => {
        document.addEventListener('click', handler)
        return () => {
            document.removeEventListener('click', handler) // clears document.addEventListener('click', handler) from browser
        }
    }, [])
    return (
        <div className="page-rows-left">
            <div className="title-header">
                <h3>Showing rows per page</h3>
            </div>
            <div className="select-drop-down table-drop-down" ref={formRef}>
                <div onClick={() => toggleSelectForm()} className="drop-down-bar">
                    <span>{action}</span>
                    <FontAwesomeIcon className="icon angle" icon={faAngleDown}/>
                </div>
                <div className={`drop-down-frame ${isActive ? 'active' : ''}`}>
                    <ul>
                        <li onClick={() => updateField(10)}>10</li>
                        <li onClick={() => updateField(20)}>20</li>
                        <li onClick={() => updateField(30)}>30</li>
                        <li onClick={() => updateField(40)}>40</li>
                        <li onClick={() => updateField(50)}>50</li>
                        <li onClick={() => updateField(60)}>60</li>
                        <li onClick={() => updateField(70)}>70</li>
                        <li onClick={() => updateField(80)}>80</li>
                        <li onClick={() => updateField(90)}>90</li>
                        <li onClick={() => updateField(100)}>100</li>
                    </ul>
                </div>
            </div>
        </div>
    )
}





const ContentRight = () => {
    return (
        <div className="page-rows-right">
            <ul>
                <li>1 - 30</li>
                <li><span>of 12,343,564</span></li>
                <li>
                    <FontAwesomeIcon className="icon" icon={faAngleLeft}/>
                    <FontAwesomeIcon className="icon active" icon={faAngleRight}/>
                </li>
            </ul>
        </div>
    )
}