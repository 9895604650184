import DeactivateModal from './DeactivateModal'
import DeletePageCategory from './DeletePageCategory'








const ModalDropDown = ({deactvateModal, toggleModal, deletePageCategoryModal, alertNotification, toggleDeletePageCategoryModal}) => {
    return (
        <div className="app-modal-drop-down">
            { deactvateModal.state ? (<DeactivateModal deactvateModal={deactvateModal} toggleModal={toggleModal}/>) : null }
            { deletePageCategoryModal.state ? (<DeletePageCategory alertNotification={alertNotification} deletePageCategoryModal={deletePageCategoryModal} toggleDeletePageCategoryModal={toggleDeletePageCategoryModal}/>) : null }
        </div>
    )
}


export default ModalDropDown