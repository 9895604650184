
import React, { useState, useEffect, useRef } from 'react';
import Axios from 'axios'
import AOS from 'aos'
import {  blog_img, url, user_img } from '../../File'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";







const Testimonial = () => {
    const FetchTestimonialRef = useRef(null)
    const [testimonials, setTestimonials] = useState([])



    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 5
        },
        desktop: {
          breakpoint: { max: 3000, min: 993 },
          items: 3
        },
        tablet: {
          breakpoint: { max: 992, min: 568 },
          items: 2
        },
        mobile: {
          breakpoint: { max: 567, min: 0 },
          items: 1
        }
      };

      // Fetch testimonial
      const FetchTestimonial = () => {
        Axios.get(url('/api/fetch-testimonial')).then((response) => {
            const data = response.data
            
            if(data){
                return setTestimonials(data)
            }
            setTestimonials([])
        })
    }
    FetchTestimonialRef.current = FetchTestimonial


      useEffect(() => {
        FetchTestimonialRef.current()
        AOS.init({ duration: 2000 })
    
      }, [])

    return (
        <div className="">
            {
                testimonials.length > 0 ? (<TestimonialContent responsive={responsive} testimonials={testimonials}/>) : null
            }
        </div>
    )
}

export default Testimonial




const TestimonialContent = ({responsive, testimonials}) => {
    return (
        <div  data-aos={'zoom-in'}  style={{ backgroundImage: `url("${blog_img('1.png')}")`}} className="home-reviews-container">
           <div className="home-reviews-inner testimonial">
                <div className="title-header">
                    <h3>You’re in good <br/> hands</h3>
                </div>
                <div className="slider-frame">
                    <Carousel 
                    infinite={true}
                    autoPlay={true}
                    autoPlaySpeed={5000}
                    responsive={responsive}>
                        
                        {
                            testimonials.map((testimonial, index) => (<SliderContent key={index} testimonial={testimonial}/>))
                        }
                    </Carousel>
                </div>
           </div>
        </div>
    )
}


const SliderContent = ({testimonial}) => {
    const [enlarge, setEnlarge] = useState(false)
    const [words, setWords] = useState(250)
    
    const toggleWords = () => {
        if(words === 250){
            setWords(500)
            return setEnlarge(true)
        }else{
            setWords(250)
            return setEnlarge(false)
        }
    }

    return (
        <div className="slide-item">
            <div className="title-header">
                <h3>{testimonial.title}</h3>
            </div>
            <div className={`body ${enlarge ? 'enlarge' : null}`}>
                <p>
                    {testimonial.description.substring(1, words)}
                    {testimonial.description.length > 250 ? (
                    <span onClick={() => toggleWords()} className="view-more">
                        {
                            words === 250 ? "Show more..." : "Show less..."
                        }
                     </span>) : null}
                </p>
            </div>
            <div className="user-profile">
                <div className="image">
                    <img src={user_img(testimonial.image)} className="banner-bg" alt={`images-${testimonial.name}`}/>
                </div>
                <div className="detail">
                    <ul>
                        <li className="name">{testimonial.name}</li>
                        <li className="location">{testimonial.state}, {testimonial.country}</li>
                    </ul>
                </div>
            </div>
        </div>
    )
}