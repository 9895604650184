import { useState, useEffect } from 'react'
import TopBar from './TopBar'
import PageContentBody from './PageContentBody'
import AddPageContent from './AddPageContent'
import MobileTable from './MobileTable'
import Axios from 'axios'
import { url } from '../../File'
import { useDispatch, useSelector } from 'react-redux'
import { getPageContents } from '../redux/PageContentSlice'


const PageContent = ({ getActionloader, alertNotification}) => {
    // react hooks
    const dispatch = useDispatch()
    const pageContents = useSelector(state => state.pageContents.pageContents)
    
    const [formState, steFormState] = useState(false)
    
    const toggleAddMember = (state) => {
        return steFormState(state)
    }

    useEffect(() => {
        window.scrollTo(0, 0) // page scroll to top

        const FetchPageContents = () => {
            Axios.get(url('/api/admin/fetch-page-content')).then((response) => {
                const data = response.data
                if(data.status === 'ok'){
                    dispatch(getPageContents(data.contents))
                }
            }).catch(error => {
                console.log(error)
            })
        }
        FetchPageContents()
    }, [dispatch])

    return (
        <div className="general-table-container">
            {
                formState === true ? (<AddPageContent getActionloader={getActionloader} alertNotification={alertNotification} toggleAddMember={toggleAddMember}/>) : null
            }
            <TopBar/>
            <PageContentBody pageContents={pageContents}  alertNotification={alertNotification} getActionloader={getActionloader} toggleAddMember={toggleAddMember}/>
            <MobileTable/>
        </div>
    )
}

export default PageContent




