
import { admin_logo, user_img }  from '../../File'
import { NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
    faBell,
    faBars,
    faGear,
    faMoon,
    faCircle,
    faEllipsis,
    faThumbtack,
} from '@fortawesome/free-solid-svg-icons'



const TopNavigation = ({ stickNavigation, toggleNotification,  toggleNavigationPosition, toggleTheme, toggleOptions, setIsActive }) => {
  
    return (
        <div className="navigation-top-container">
            <div className={`navigation-container ${stickNavigation ? 'stick' : ''}`}>
                <div className="top-navigation-inner">
                    <NavigationLeft/>
                    <NavigationMiddle  toggleOptions={toggleOptions}/>
                    <NavigationRight toggleOptions={toggleOptions} stickNavigation={stickNavigation} toggleNavigationPosition={toggleNavigationPosition} toggleNotification={toggleNotification} toggleTheme={toggleTheme}/>
                </div>
            </div>
        </div>
    )
}


export default TopNavigation





const NavigationLeft = () => {
    return (
        <div className="navi-left">
            <div className="title-header">
                <h3>ADMIN AREA </h3>
                <img src={admin_logo('full-logo.png')} alt="navi-logo"/>
            </div>
        </div>
    )
}



const NavigationMiddle = ({ toggleOptions}) => {
    return (
        <div className="navi-middle">
            <ul className="content">
                <li><NavLink to="/admin/dashboard">Dashboard</NavLink></li>
                <li><NavLink to="/admin/users">Users</NavLink></li>
                <li><NavLink to="/admin/transaction">Transactions</NavLink></li>
                <li><NavLink to="/admin/loan-request">Loan Request</NavLink></li>
                <li><NavLink to="/admin/manage-admin">Manage Admin</NavLink></li>
                <li title={"Open Side Navigation"} className="icon" onClick={() => toggleOptions({ state: true })}><FontAwesomeIcon icon={faEllipsis} /></li>
            </ul>
            {/* <div className="mobile-nav-logo">
                <img src={admin_logo('full-logo.png')} alt="navi-logo"/>
            </div> */}
        </div>
    )
}


const NavigationRight = ({ stickNavigation, toggleOptions, toggleNavigationPosition, toggleNotification, toggleTheme}) => {
    
    return (
        <div className="navi-right">
            <ul>
                <li>
                    <div title={"Open Navigation"} className="mobile-nav-icon">
                        <FontAwesomeIcon onClick={() => toggleOptions({ state: true })} className="icon" icon={faBars} />
                    </div>
                </li>
                <li>
                    <div title={"Day / Night Mode"} className="notification">
                        <FontAwesomeIcon onClick={() => toggleTheme('open')} className="icon icon-moon" icon={faMoon} />
                    </div>
                </li>
                <li>
                    <div title={"Settings"} className="notification">
                        <FontAwesomeIcon  className="icon gear" icon={faGear} />
                    </div>
                </li>
                <li>
                    <div onClick={() => toggleNavigationPosition()} className="notification">
                        <FontAwesomeIcon style={{ color: stickNavigation ? '#FDB813' : '' }} title={`${stickNavigation? 'Unstick' : 'Stick'} Navigation`} className="icon" icon={faThumbtack} />
                    </div>
                </li>
                <li>
                    <div title={"Notification"} onClick={() => toggleNotification(true)} className="notification">
                        <FontAwesomeIcon className="icon" icon={faBell} />
                        <FontAwesomeIcon className="bell-alert active" icon={faCircle} />
                    </div>
                </li>
                <li>
                    <div title={"User Profile"} className="avatar">
                        <NavLink to="/admin/admin-profile">
                             {/* <div className="avatar-letter"><span>K</span></div> */}
                            <img src={user_img('avatar.png')} alt="avatar-img"/>
                        </NavLink>
                    </div>
                </li>
            </ul>
        </div>
    )
}