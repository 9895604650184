import { useState, useEffect, useRef} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
    faEye,
    faTimes,
    faEyeSlash,
} from '@fortawesome/free-solid-svg-icons'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import JoditEditor from 'jodit-react'
import Axios from 'axios'
import FormInputAlert from '../alert/FormInputAlert'
import { url } from '../../File'
import { useDispatch } from 'react-redux'
import { addPageContents } from '../redux/PageContentSlice'









const AddPageContent = ({getActionloader, toggleAddMember, alertNotification}) => {
    const textRef = useRef(null)
    const [text, setText] = useState('')
    const [title, setTitle] = useState('')
    const [category, setCategory] = useState('')
    const [position, setPosition] = useState('right')
    const [buttonLink, setButtonLink] = useState('')
    const [button, setButton] = useState(false)
    const [pageCategories, setPageCategories ] = useState([])

    const [textAlert, setTextAlert] = useState('')
    const [titleAlert, setTitleAlert] = useState('')
    const [categoryAlert, setCategoryAlert] = useState('')
    const [positionAlert, setPositionAlert] = useState('')

    // react hooks
    const dispatch = useDispatch()
    

    // create content
    const addNewBanner = () => {
        const validate = validate_input(title, category, position, text)
        if(validate === false) return 
        const content = {
            title: title,
            text: text,
            position: position,
            buttonLink: buttonLink,
            category: category,
        }
        setButton(true)
        getActionloader(true, 'Adding new content...')
        Axios.post(url('/api/admin/create-new-content'), content).then((response) => {
            const data = response.data
            if(data.status === 'input-error'){
                inputErrorForBackend(data.validationError)
            }else if(data.status === 'error'){
                alertNotification('error', data.message)
            }else if(data.status === 'ok'){
                console.log(data.newContent)
                dispatch(addPageContents(data.newContent))
                alertNotification('success', 'Content Created sucessfully!')
            }
            getActionloader(false)
            return setButton(false)
        }).catch(error => {
            setButton(false)
            console.log(error)
        })
    }

    const initErrorAlert = () => {
        setTextAlert('')
        setTitleAlert('')
        setPositionAlert('')
        setCategoryAlert('')
    }

    const initFormInput = () => {
        setTitle('')
        setText('')
        setButtonLink('')
    }

    const inputErrorForBackend = (error) => {
        setTitleAlert(error.title)
        setTextAlert(error.text)
        setPositionAlert(error.position)
        setCategoryAlert(error.category)
    }

    const validate_input = (title='', category='', position='', text='') => {
        let failed = false;
        initErrorAlert()

        if(title.length === 0){
            failed = true
            setTitleAlert(`*Name field is required`)
        } else if(title.length < 3){
            failed = true
            setTitleAlert(`*Must be minimum of 3 characters`)
        }else if(title.length > 100){
            failed = true
            setTitleAlert(`*Must be maximum of 100 characters`)
        }
        if(category.length === 0){
            failed = true
            setCategoryAlert(`*Category field is required`)
        }
        if(position.length === 0){
            failed = true
            setPositionAlert(`*Posiiton field is required`)
        }
        if(text.length === 0){
            failed = true
            setTextAlert(`*Text field is required`)
        } else if(text.length < 20){
            failed = true
            setTextAlert(`*Must be minimum of 20 characters`)
        }else if(text.length > 2000){
            failed = true
            setTextAlert(`*Must be maximum of 2000 characters`)
        }
        if(failed === true){
            return false
        }else{
            return true
        }
    }


    useEffect(() => {
        // getch page categries
        const FetchPageCategory = () => {
            Axios.get(url('/api/admin/fetch-page-categories')).then((response) => {
                const data = response.data
                if(data.status === 'ok'){
                    setPageCategories(data.categories)
                }
            }).catch(error => {
                console.log(error)
            })
        }
        FetchPageCategory()

    }, [])

    return (
        <div className="add-admin-container large">
            <div className="form">
                <TitleHeader toggleAddMember={toggleAddMember}/>
                <div className="form-body">
                    <Row className="show-grid">
                        <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                            <div className="form-group">
                                <label>Title</label>
                                <input type="text" onChange={(e) => setTitle(e.target.value)} value={title} className="" placeholder="Enter Name"/>
                                <FormInputAlert alert={titleAlert}/>
                            </div>
                        </Col>
                        <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                            <div className="form-group">
                                <label>Category</label>
                                <select onChange={(e) => setCategory(e.target.value)}>
                                    {
                                        pageCategories.map((category, index) => (<option key={index} value={category._id}>{category.category}</option>))
                                    }
                                </select>
                                <FormInputAlert alert={categoryAlert}/>
                            </div>
                        </Col>
                        <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                            <div className="form-group">
                                <label>Position</label>
                                <select onChange={(e) => setPosition(e.target.value)}>
                                    <option  value="right">Right</option>
                                    <option  value="left">Left</option>
                                    <option  value="center">Center</option>
                                </select>
                            </div>
                            <FormInputAlert alert={positionAlert}/>
                        </Col>
                        <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                            <div className="form-group">
                                <label>Button Link</label>
                                <input type="text" onChange={(e) => setButtonLink(e.target.value)} value={buttonLink} className="" placeholder="Enter Link"/>
                            </div>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <div className="form-group">
                                <label>Text</label>
                                <JoditEditor ref={textRef} value={text} onChange={(text) => setText(text)}/>
                                <FormInputAlert alert={textAlert}/>
                            </div>
                        </Col>
                    </Row>
                    <div className="form-button">
                        { 
                            button ? (
                                <button type="button">PLEASE WAIT...</button>
                            ) : (
                                <button onClick={() => addNewBanner()} type="button">CREATE CONTENT</button>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddPageContent




const TitleHeader = ({toggleAddMember}) => {
    return (
        <div className="title-header">
            <h3>Add New Member</h3>
            <div className="form-cancel">
                <FontAwesomeIcon onClick={() => toggleAddMember(false)} className="icon" icon={faTimes} />
            </div>
        </div>
    )
}
