
import {  admin_logo } from '../../File'
import { NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faTimes,
    faUser,
    faGear,
    faThumbtack,
} from '@fortawesome/free-solid-svg-icons'





const SideNavigation = ({ sideNav, toggleOptions, stickNavigation, toggleNavigationPosition }) => {
   
    return (
        <div className={`side-navigation ${sideNav}`}>
            <div className="side-nav-dark-skin"></div>
            <div className="side-nav-body">
                <TitleHeader toggleOptions={toggleOptions}/>
                <SideNavContent stickNavigation={stickNavigation} toggleNavigationPosition={toggleNavigationPosition} toggleOptions={toggleOptions}/>
            </div>
        </div>
    )
}


export default SideNavigation








const TitleHeader = ({toggleOptions}) => {
    return (
        <div className="title-header">
            <img src={admin_logo('full-logo.png')} alt="sid-navi-logo"/>
            <FontAwesomeIcon title={"Close Side Navigation"} onClick={() => toggleOptions({state: false })} className="icon" icon={faTimes} />
        </div>
    )
}


const SideNavContent = ({ stickNavigation, toggleNavigationPosition, toggleOptions}) => {
    return (
        <div className="side-nav-content">
            <ul>
                <li>
                    <NavLink onClick={() => toggleOptions({state: false })} to="/admin/dashboard"> 
                        <FontAwesomeIcon className="icon" icon={faUser} />Dashboard
                    </NavLink>
                </li>
                <li>
                    <NavLink onClick={() => toggleOptions({state: false })} to="/admin/page-category"> 
                        <FontAwesomeIcon className="icon" icon={faUser} />Page Category
                    </NavLink>
                </li>
                <li>
                    <NavLink onClick={() => toggleOptions({state: false })} to="/admin/page-content"> 
                        <FontAwesomeIcon className="icon" icon={faUser} />Page content
                    </NavLink>
                </li>
                <li>
                    <NavLink onClick={() => toggleOptions({state: false })} to="/admin/users"> 
                        <FontAwesomeIcon className="icon" icon={faUser} />Users
                    </NavLink>
                </li>
                <li>
                    <NavLink onClick={() => toggleOptions({state: false })} to="/admin/transaction"> 
                        <FontAwesomeIcon className="icon" icon={faUser} />Transactions
                    </NavLink>
                </li>
                <li>
                    <NavLink onClick={() => toggleNavigationPosition()}> 
                        <FontAwesomeIcon className="icon" icon={faThumbtack} />
                        { stickNavigation ? 'Unstick Navigation' : 'Stick Navigation' }
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/admin/settings"> 
                        <FontAwesomeIcon className="icon settings" icon={faGear} />Settings
                    </NavLink>
                </li>
                <li>
                    <NavLink onClick={() => toggleOptions({state: false })} to="/admin/loan-request"> 
                        <FontAwesomeIcon className="icon" icon={faUser} />Loan Request
                    </NavLink>
                </li>
                <li>
                    <NavLink  onClick={() => toggleOptions({state: false })} to="/admin/manage-admin"> 
                        <FontAwesomeIcon className="icon" icon={faUser} />Manage Admin
                    </NavLink>
                </li>
                <li>
                    <NavLink onClick={() => toggleOptions({state: false })} to="/admin/referals"> 
                        <FontAwesomeIcon className="icon" icon={faUser} />Referals
                    </NavLink>
                </li>
                <li>
                    <NavLink onClick={() => toggleOptions({state: false })} to="/admin/transaction"> 
                        <FontAwesomeIcon className="icon" icon={faUser} />Transactions
                    </NavLink>
                </li>
                <li>
                    <NavLink onClick={() => toggleOptions({state: false })} to="/admin/loan-request"> 
                        <FontAwesomeIcon className="icon" icon={faUser} />Loan Request
                    </NavLink>
                </li>
                <li>
                    <NavLink onClick={() => toggleOptions({state: false, currentUrl: 'login' })} to="/admin/login"> 
                        <FontAwesomeIcon className="icon" icon={faUser} />Login
                    </NavLink>
                </li>
                <li>
                    <NavLink onClick={() => toggleOptions({state: false })} to="/admin/manage-admin"> 
                        <FontAwesomeIcon className="icon" icon={faUser} />Manage Admin
                    </NavLink>
                </li>
                <li>
                    <NavLink onClick={() => toggleOptions({state: false })} to="/admin/referals"> 
                        <FontAwesomeIcon className="icon" icon={faUser} />Referals
                    </NavLink>
                </li>
                <li>
                    <NavLink onClick={() => toggleOptions({state: false, currentUrl: 'login' })} to="/admin/login"> 
                        <FontAwesomeIcon className="icon" icon={faUser} />Login
                    </NavLink>
                </li>
                <li>
                    <NavLink onClick={() => toggleOptions({state: false })} to="/admin/manage-admin"> 
                        <FontAwesomeIcon className="icon" icon={faUser} />Manage Admin
                    </NavLink>
                </li>
                <li>
                    <NavLink onClick={() => toggleOptions({state: false })} to="/admin/referals"> 
                        <FontAwesomeIcon className="icon" icon={faUser} />Referals
                    </NavLink>
                </li>
                <li>
                    <NavLink onClick={() => toggleOptions({state: false, currentUrl: 'login' })} to="/admin/login"> 
                        <FontAwesomeIcon className="icon" icon={faUser} />Login
                    </NavLink>
                </li>
                
                <li>
                    <NavLink onClick={() => toggleOptions({state: false })} to="/admin/referals"> 
                        <FontAwesomeIcon className="icon" icon={faUser} />Referals
                    </NavLink>
                </li>
                <li>
                    <NavLink onClick={() => toggleOptions({state: false, currentUrl: 'login' })} to="/admin/login"> 
                        <FontAwesomeIcon className="icon" icon={faUser} />Login
                    </NavLink>
                </li>
                <li>
                    <NavLink onClick={() => toggleOptions({state: false })} to="/admin/manage-admin"> 
                        <FontAwesomeIcon className="icon" icon={faUser} />Manage Admin
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/admin/settings"> 
                        <FontAwesomeIcon className="icon" icon={faGear} />Settings
                    </NavLink>
                </li>
                <li>
                    <NavLink onClick={() => toggleNavigationPosition()}> 
                        <FontAwesomeIcon className="icon" icon={faThumbtack} />
                        { stickNavigation ? 'Unstick Navigation' : 'Stick Navigation' }
                    </NavLink>
                </li>
            </ul>
        </div>
    )
}