import HTMLReactParser from 'html-react-parser'



const TitleHeader = ({title}) => {

  return (
    <div className="page-title-section">
      <div className="title-header">
        {
            title.titles.map((title, index) => (
                <span key={index} style={{ color: title.color}}>{title.title} {title.line_break ? (<br/>) : null }</span>
            ))
        }
      </div>
      <div className="text">
            <p>{title.text ? HTMLReactParser(title.text) : null }</p>
      </div>
    </div>
  )
}

export default TitleHeader
