import { useEffect, useState, useRef } from 'react'
import Axios from 'axios'
import { url } from '../../File'
import Banner from './Banner'
import ContentLinks from './ContentLinks'
import HowItWorks from './HowItWorks'
import Content from './Content'
import Steps from './Steps'
import Banner2 from './Banner2'
import Testimonial from '../home/Testimonial'
import Banner3 from './Banner3'
import OurPartners from './OurPartners'
import DownloadApp from './DownloadApp'
import { useSearchParams } from 'react-router-dom'





const Detail = ({Contentloader}) => {
    const fetchPageDetailsRef = useRef(null)
    const [searchParams] = useSearchParams();
    const page_category = searchParams.get('category')
    const [items, setItems] = useState(false)
    const [steps, setSteps] = useState([])
    const [banner, setBanner] = useState(false)
    const [pageContent, setPageContent] = useState(false)
    const [contentBanner, setContentBanner] = useState(false)

    // fetch page etals
    const fetchPageDetails = () => {
        Contentloader(true)
        Axios.get(url(`/api/fetch-page-detail/${page_category}`)).then((response) => {
            const data = response.data
            if(data.status === 'ok'){
                setSteps(data.steps)
                data.items ?  setItems(data.items) : setItems(false)
                data.banner ?  setBanner(data.banner) : setBanner(false)
                data.content ? setPageContent(data.content) : setPageContent(false)
                data.content_banner ? setContentBanner(data.content_banner) : setContentBanner(false)
            }else{
                setItems(false)
                setBanner(false)
                setPageContent(false)
            }
            Contentloader(false)
        })
    }

    fetchPageDetailsRef.current = fetchPageDetails

    useEffect(() => {
        // Fetch personal banner
        if(page_category.length){
            fetchPageDetailsRef.current()
        }

    }, [page_category])

    return (
        <div className="personal-container">
            { banner ? (<Banner banner={banner}/>) : null }
            {steps && steps.length ? (<Steps steps={steps}/>) : null }
            { items.content_links ? (<ContentLinks/>) : null }
            { items.how_it_works ? (<HowItWorks/>) : null }
            { pageContent ? (<Content pageContent={pageContent}/>) : null }
            { contentBanner ? (<Banner2 contentBanner={contentBanner}/>) : null }
            { items.testimonial_active ? (<Testimonial/>) : null }
            <OurPartners/>
            { items.bottom_banner_active ? (<Banner3/>) : null }
            <DownloadApp/>
        </div>
    )
}




export default Detail