import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {  stack_img } from '../../File'
import { useEffect } from 'react'
import AOS from 'aos'





const OurStack = () => {
  
  const stacks = [
    {
      name: '1',
      image: '1.png'
    },
    {
      name: 'aws',
      image: 'aws.png'
    },
    {
      name: 'python',
      image: 'python.png'
    },
    {
      name: 'js',
      image: 'js.png'
    },
    {
      name: '2',
      image: '2.png'
    }
  ]



  const responsive = {
      superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 5
      },
      desktop: {
        breakpoint: { max: 3000, min: 993 },
        items: 5
      },
      tablet: {
        breakpoint: { max: 992, min: 568 },
        items: 3
      },
      mobile: {
        breakpoint: { max: 567, min: 0 },
        items: 3
      }
  }


  useEffect(() => {
    AOS.init({duration: 2000 })

}, [])

  return (
    <div data-aos={'fade-down-right'} className="our-stark-container">
      <TitleHeader/>
      <OurStackFrame stacks={stacks} responsive={responsive}/>
    </div>
  )
}

export default OurStack



const TitleHeader = () => {
  return (
    <div className="title-header">
      <h3>Our Stack</h3>
    </div>
  )
}



const OurStackFrame = ({stacks, responsive}) => {
  return (
    <div className="slider-frame">
      <Carousel 
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={5000}
        responsive={responsive}>
            
            {
                stacks.map((stack, index) => (<SliderContent key={index} stack={stack}/>))
            }
      </Carousel>
    </div>
  )
}




const SliderContent = ({stack}) => {
  return (
    <div className="frame-item">
      <img src={stack_img(stack.image)} alt={stack.image} />
    </div>
  )
}







