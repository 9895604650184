
import { app_image } from '../../File'
import { NavLink } from 'react-router-dom'
import { useEffect } from 'react'
import AOS from 'aos'


const Content = () => {
    const contents = [
        {
            _id: '1',
            image: 'content-image-1.png',
            title: 'All you need is your phone  your payizzy app',
            text: 'Minimal is the lifestyle and we made sure Payizzy goes with your vibe. All you need is your phone to move money like a boss',
            link: '',
            button: '',
            position: 'right',
            is_featured: 1,
            updated_at: '',
            created_at: ''
        },
        {
            _id: '2',
            image: 'content-image-2.png',
            title: 'Never ever worry about debit and credit cards ',
            text: 'Imagine not having to have and carry around your atm cards and constantly having to renew them and still having control of your money! Sounds like the best thing after ice cream',
            link: '',
            button: '',
            position: 'left',
            is_featured: 1,
            updated_at: '',
            created_at: ''
        },
        {
            _id: '3',
            image: 'content-image-3.png',
            title: 'No failed transactions. All fast and easy',
            text: 'Save yourself and your contacts the stress of sending and resending multiple transactions and still having them get bounced. Queue, run, transact with our advanced payment algorithm thats as sure as day and night',
            link: '',
            button: '',
            position: 'right',
            is_featured: 1,
            updated_at: '',
            created_at: ''
        },
        {
            _id: '4',
            image: 'content-image-9.png',
            title: 'Stay ahead of the newest technology with Chat powered payments',
            text: 'Imagine you could send and receive money while chatting. You can do this with Payizzy by opening a chat, selecting a contact, and effortlessly sending money or making transactions with just a few taps.',
            link: '',
            button: '',
            position: 'left',
            is_featured: 1,
            updated_at: '',
            created_at: ''
        },
        {
            _id: '5',
            image: 'content-image-10.png',
            title: 'Send money across borders',
            text: 'Send money across borders link your ATM cards, choose your currency, choose Payizzy as your payment option and effortlessly make international payments. No holds barred.',
            link: '',
            button: '',
            position: 'right',
            is_featured: 1,
            updated_at: '',
            created_at: ''
        },
    ]

    useEffect(() => {
        AOS.init({duration: 2000 })
    }, [])

    return (
        <div className="main-content-container-1">
            {
                contents.map((content, index) => (<ContentItem key={index} number={index+1} content={content}/>))
            }
        </div>
    )
}



export default Content




const ContentItem = ({number, content}) => {
    return (
        <div data-aos={'zoom-out-up'} className={`content-item ${content.position === 'left' ? 'left' : 'right'}`}>
            <MobileImage content={content}/>
                {
                    content.position === 'left' ? (
                        <div className="inner-content-item left">
                           <ContentLeft content={content}/>
                            <ContentRight content={content}/>
                        </div>
                    ) : (
                        <div className="inner-content-item">
                            <ContentRight content={content}/>
                            <ContentLeft content={content}/>
                        </div>
                    )
                }
        </div>
    )
}



const ContentLeft = ({content}) => {
    return (
        <div className={`main-content-left`}>
          <div className="content">
            <div className="title-header">
                <h3>{content.title}</h3>
            </div>
            <div className="body">
                <p>{content.text}</p>
            </div>
            {
                content.link ? (
                    <div className="link">
                        <NavLink to={content.link}>{content.button}</NavLink>
                    </div>
                ) : null
            }
          </div>
        </div>
    )
}




const ContentRight = ({content}) => {
    return (
        <div className="main-content-right">
            <div className="img">
                <img src={app_image(content.image)} alt={content.image}/>
            </div>
        </div>
    )
}




const MobileImage = ({content}) => {
    return (
        <div className="main-content-mobile-img">
            <div className="img">
                <img src={app_image(content.image)} alt={content.image}/>
            </div>
        </div>
    )
}