
import { app_image } from '../../File'
import { NavLink } from 'react-router-dom'
import { useEffect } from 'react'
import AOS from 'aos'


const Content = () => {
    const contents = [
        {
            _id: '1',
            image: 'content-image-23.png',
            title: 'Our Mission',
            text: 'We are changing and transforming the way money is moved and used in Africa with our innovative QR technology. We are making payments differently and empowering African for the future of money',
            link: '',
            button: '',
            position: 'right',
            is_featured: 1,
            updated_at: '',
            created_at: ''
        },
    ]

    useEffect(() => {
        AOS.init({duration: 2000})

    }, [])

    return (
        <div className="main-content-container-1">
            {
                contents.map((content, index) => (<ContentItem key={index} number={index+1} content={content}/>))
            }
        </div>
    )
}



export default Content




const ContentItem = ({number, content}) => {
    return (
        <div data-aos={'zoom-out-up'} className={`content-item ${content.position === 'left' ? 'left' : 'right'}`}>
            <MobileImage content={content}/>
                {
                    content.position === 'left' ? (
                        <div className="inner-content-item left">
                           <ContentLeft content={content}/>
                            <ContentRight content={content}/>
                        </div>
                    ) : (
                        <div className="inner-content-item">
                            <ContentRight content={content}/>
                            <ContentLeft content={content}/>
                        </div>
                    )
                }
        </div>
    )
}



const ContentLeft = ({content}) => {
    return (
        <div className={`main-content-left`}>
          <div className="content">
            <div className="title-header">
                <h3>{content.title}</h3>
            </div>
            <div className="body">
                <p>{content.text}</p>
            </div>
            {
                content.link ? (
                    <div className="link">
                        <NavLink to={content.link}>{content.button}</NavLink>
                    </div>
                ) : null
            }
          </div>
        </div>
    )
}




const ContentRight = ({content}) => {
    return (
        <div className="main-content-right">
            <div className="img">
                <img src={app_image(content.image)} alt={content.image}/>
            </div>
        </div>
    )
}




const MobileImage = ({content}) => {
    return (
        <div className="main-content-mobile-img">
            <div className="img">
                <img src={app_image(content.image)} alt={content.image}/>
            </div>
        </div>
    )
}