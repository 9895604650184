import { useEffect } from 'react'
import AOS from 'aos'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { app_image } from '../../File'
import { NavLink } from 'react-router-dom'




const Banner3 = () => {
    useEffect(() => {
        AOS.init({ duration: 2000 })
    })
    
    return (
        <div data-aos={'fade-down-right'} className="content-2-container">
            <div className="content-2-inner">
                <Row className="show-grid">
                    <Col xs={12} sm={12} md={7} lg={7} xl={7}><ContentLeft/></Col>
                    <Col xs={12} sm={12} md={5} lg={5} xl={5}><ContentRight/></Col>
                </Row>
            </div>
        </div>
    )
}


export default Banner3






const ContentLeft = () => {
    return (
        <div className="left">
            <ul>
                <li>
                    <h3>The Only Money App You Need</h3>
                </li>
                <li>
                    <p>
                        Send, receive , request money and 
                        make chat-powered transactions globally in the safest 
                        and fastest way with Payizzy's QR Codes 
                    </p>
                </li>
                <li>
                    <NavLink to="/">
                        <img src={app_image('app-store.png')} alt="apps-store"/>
                    </NavLink>
                    <NavLink to="/">
                        <img src={app_image('play-store.png')} alt="play-store"/>
                    </NavLink>
                </li>
            </ul>
        </div>
    )
}





const ContentRight = () => {
    return (
        <div className="right">
             <img src={app_image('content-image-14.png')} alt="play-store"/>
        </div>
    )
}