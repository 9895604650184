
import { app_image } from '../../File'
import { NavLink } from 'react-router-dom'
import HTMLReactParser from 'html-react-parser'
import { useEffect } from 'react'
import AOS from 'aos'




const Content = ({pageContent}) => {
    useEffect(() => {
        AOS.init({ duration: 2000 })
    })


    return (
        <div className="main-content-container-1">
            {
                pageContent.map((content, index) => (<ContentItem key={index} number={index+1} link={content.link} content={content}/>))
            }
        </div>
    )
}



export default Content



const ContentItem = ({number, content}) => {
    return (
        <div data-aos={number % 2 === 0 ? 'slide-right' : 'zoom-in'} className={`content-item ${content.position === 'left' ? 'left' : 'right'}`}>
            <MobileImage content={content}/>
                {
                    content.position === 'left' ? (
                        <div className="inner-content-item left">
                           <ContentLeft content={content}/>
                            <ContentRight content={content}/>
                        </div>
                    ) : (
                        <div className="inner-content-item">
                            <ContentRight content={content}/>
                            <ContentLeft content={content}/>
                        </div>
                    )
                }
        </div>
    )
}



const ContentLeft = ({content}) => {
    return (
        <div className={`main-content-left`}>
          <div className="content">
            <div className="title-header">
                <h3>{content.title}</h3>
            </div>
            <div className="body">
                <p>{content.text ? HTMLReactParser(content.text) : null }</p>
            </div>
            {
                content.link ? (
                    <div className="link">
                        <NavLink to={content.link}>See all our fees</NavLink>
                    </div>
                ) : null
            }
          </div>
        </div>
    )
}




const ContentRight = ({content}) => {
    return (
        <div className="main-content-right">
            <div className="img">
                <img src={app_image(content.image)} alt={content.image}/>
            </div>
        </div>
    )
}




const MobileImage = ({content}) => {
    return (
        <div className="main-content-mobile-img">
            <div className="img">
                <img src={app_image(content.image)} alt={content.image}/>
            </div>
        </div>
    )
}