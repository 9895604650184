import { useState, useRef, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
    faClock,
    faAngleDown,
    faCircleCheck,
    faCircleXmark,
} from '@fortawesome/free-solid-svg-icons'
import {  user_img } from '../../File'
import SearchBar from './SearchBar'
import TableBottom from './TableBottom'


const ReferalBody = ({toggleFloatProfile}) => {
    const fetchUserProfile = () => {
        console.log('yes')
        toggleFloatProfile(true)
    }

    return (
        <div className="general-table-body">
            <div className="inner-body">
                <SearchBar/>
                <ReferalTable fetchUserProfile={fetchUserProfile}/>
                <TableBottom/>
            </div>
        </div>
    )
}



export default ReferalBody












const ReferalTable = ({fetchUserProfile}) => {
    return (
        <div className="general-table">
            <table className="table table-hover">
                <thead>
                    <tr>
                        <th scope="col">Name <span>(222,000)</span></th>
                        <th scope="col">Phone Number <span>(7)</span></th>
                        <th scope="col">Total Referals</th>
                        <th scope="col">Gender</th>
                        <th scope="col">Action</th>
                    </tr>
                </thead>
                <tbody>
                    <TabelContent fetchUserProfile={fetchUserProfile}/>
                    <TabelContent fetchUserProfile={fetchUserProfile}/>
                    <TabelContent fetchUserProfile={fetchUserProfile}/>
                    <TabelContent fetchUserProfile={fetchUserProfile}/>
                    <TabelContent fetchUserProfile={fetchUserProfile}/>
                    <TabelContent fetchUserProfile={fetchUserProfile}/>
                    <TabelContent fetchUserProfile={fetchUserProfile}/>
                    <TabelContent fetchUserProfile={fetchUserProfile}/>
                    <TabelContent fetchUserProfile={fetchUserProfile}/>
                    <TabelContent fetchUserProfile={fetchUserProfile}/>
                    <TabelContent fetchUserProfile={fetchUserProfile}/>
                </tbody>
            </table>
        </div>
    )
}

    


const TabelContent = ({fetchUserProfile}) => {
    let formRef = useRef()
    const [isActive, setIsActive] = useState(false)
    const [action, setAction] = useState('reward')
    const [iconType, setIconType] = useState(faCircleCheck)


    const toggleSelectForm = () => {
        setIsActive(true)
    }

    const updateField = (string) => {
        setAction(string)
        if(string === 'reward'){
            setIconType(faCircleCheck)
        }else if(string === 'review'){
            setIconType(faClock)
        }else{
            setIconType(faCircleXmark)
        }
        return setIsActive(false)
    }

    const handler = (e) => {
        if(formRef.current && !e.composedPath().includes(formRef.current)){
            setIsActive(false)
        }
    }


    useEffect(() => {
        document.addEventListener('click', handler)
        return () => {
            document.removeEventListener('click', handler) // clears document.addEventListener('click', handler) from browser
        }
    }, [])

    return (
            <tr>
                <td>
                    <MobileViewImage fetchUserProfile={fetchUserProfile}/>
                    <DesktopViewImage fetchUserProfile={fetchUserProfile}/>
                </td>
                <td>+2347023456789</td>
                <td>3</td>
                <td>Unknown</td>
                <td>
                    <div className="select-drop-down table-drop-down" ref={formRef}>
                        <div onClick={() => toggleSelectForm()} className="drop-down-bar">
                            <FontAwesomeIcon className={`icon ${action}`} icon={iconType}/>
                            <span>{action}</span>
                            <FontAwesomeIcon className="icon angle" icon={faAngleDown}/>
                        </div>
                        <div className={`drop-down-frame ${isActive ? 'active' : ''}`}>
                            <ul>
                                <li onClick={() => updateField('reward')}><FontAwesomeIcon className="icon reward" icon={faCircleCheck}/><span>Reward</span></li>
                                <li onClick={() => updateField('review')}><FontAwesomeIcon className="icon review" icon={faClock}/><span>Review</span></li>
                                <li onClick={() => updateField('invalid')}><FontAwesomeIcon className="icon invalid" icon={faCircleXmark}/><span>Invalid</span></li>
                            </ul>
                        </div>
                    </div>
                </td>
            </tr>
    )
}









const DesktopViewImage = ({fetchUserProfile}) => {
    return (
        <div className="user-detail desktop">
            <div onClick={() => fetchUserProfile()} className="left">
                {/* <div className="no-img" style={{ backgroundColor: 'green'}}><span>R</span></div> */}
                <div className="img">
                    <img src={user_img('9.png')} alt="user-img"/>
                </div>
            </div>
            <div onClick={() => fetchUserProfile()} className="right">
                <h3>John Doe</h3>
                <p>Acc No: 1100223344</p>
            </div>
        </div>
    )
}






const MobileViewImage = ({fetchUserProfile}) => {
    return (
        <div className="user-detail mobile">
            <div onClick={() => fetchUserProfile()} className="left">
                <NavLink to="/admin/profile">
                    {/* <div className="no-img" style={{ backgroundColor: 'green'}}><span>R</span></div> */}
                    <div className="img">
                        <img src={user_img('9.png')} alt="user-img"/>
                    </div>
                </NavLink>
            </div>
            <div className="right">
                <NavLink to="/admin/profile">
                    <h3>John Doe</h3>
                </NavLink>
                <p>Acc No: 1100223344</p>
            </div>
        </div>
    )
}