import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { app_image } from '../../File'
import { useState, useEffect, useRef } from 'react'
import AOS from 'aos'





const HowItWorks = () => {
    const InitailImageRef = useRef(null)
    const [imageBanner, setImageBanner] = useState()

    const howItWorks  = [
        {
            image: 'work-it-works.png',
            title: "Create an account",
            description: "Set up your account with your legal name, a strong password and get a verification mail"
        },
        {
            image: 'content-image-1.png',
            title: "Verify your account",
            description: "Complete details such as your BVN, NIN. Complete your KYC for your identification and safety."
        },
        {
            image: 'content-image-2.png',
            title: "Generate your unique QR code",
            description: "Automatically generate and customize your secure and unique QR codes that serves as your identifier"
        },
        {
            image: 'content-image-3.png',
            title: "Receive payments with your shiny QR code",
            description: "Quickly send or print out your customized QR codes to people to get instant payments"
        },
        {
            image: 'content-image-4.png',
            title: "Scan and pay using QR codes",
            description: "Easily and safely send money to others using their QR codes. Absolutely no time wasted"
        },
        {
            image: 'content-image-5.png',
            title: "Enjoy the izzy ride!",
            description: "Feel the joy of never having to worry about your money. Izzy tribers love ease and peaces"
        },
    ]


    // toggle image
    const toggleImage = (item) => {
        if(item.image.length){
            setImageBanner(item.image)
        }
    }

    const InitailImage = () => {
        const initImage = howItWorks[0]
        setImageBanner(initImage.image)
    }



    InitailImageRef.current = InitailImage
    useEffect(() => {
        InitailImageRef.current()
        AOS.init({
            duration: 3000
        })

    }, [])

    return (
        <div className="how-it-works-container">
            <div className="title-header">
                <h3>How Payizzy Works</h3>
                <p>Step by step on how payizzy works</p>
            </div>
            <div className="how-it-works-body">
                <Row className="show-grid">
                    <Col xs={12} sm={12} md={12} lg={7} xl={7}><ContentLeft  toggleImage={toggleImage} howItWorks={howItWorks}/></Col>
                    <Col xs={12} sm={12} md={12} lg={5} xl={5}><ContentRight imageBanner={imageBanner}/></Col>
                </Row>
            </div>
        </div>
    )
}



export default HowItWorks





const ContentLeft = ({howItWorks, toggleImage, contentRef}) => {
    return (
        <div className="howitworks-item-left-container">
           <ul>
                { howItWorks.map((item, index) => (<ContentItem key={index} toggleImage={toggleImage} number={index+1} item={item}/>))}
           </ul>
        </div>
    )
}



const ContentItem = ({ number, item, toggleImage}) => {
    return (
        <li data-aos={number % 2 === 0 ? 'slide-right' : 'fade-left'} onClick={() => toggleImage(item)}>
            <div className="number">
                <span>{number}</span>
            </div>
            <div className="content">
                {item.title ? (<h3>{item.title}</h3>) : null }
                { item.description ? (<p>{item.description}</p>) : null }
            </div>
        </li>
    )
}

const ContentRight = ({imageBanner}) => {
    return (
        <div data-aos="slide-left" className="howitworks-item-right-container">
            {
                imageBanner? (<img src={app_image(imageBanner)} alt="work-it-works"/>) : ''
            }
        </div>
    )
}





