import {useState, useEffect} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
    faPlus,
    faMinus
} from '@fortawesome/free-solid-svg-icons'
import HTMLReactParser from 'html-react-parser'
import AOS from 'aos'








const FAQContentContainer = ({faqItems}) => {
    useEffect(() => {
        AOS.init({duration: 2000 })
    }, [])


  return (
    <div className="faq-content-container">
       { faqItems.map((faq, index) => (<ContentItem key={index} faq={faq}/>))}
    </div>
  )
}

export default FAQContentContainer





const ContentItem = ({faq}) => {
    const [state, setState] = useState(false)

    const toggleContent = () => {
        setState(!state)
    }

  return (
    <div data-aos={'slide-right'} className="faq-content-item">
        <div className="title">
            <h3>{faq.title}</h3>
        </div>
        <div className={`text ${state ? 'active' : null}`}>
            {faq.text ? HTMLReactParser(faq.text) : null }
            {
                faq.steps.length ? (
                    <ul>
                        {
                            faq.steps.map((step, index) => (
                                <li key={index}>
                                    <div className="number">{index + 1}.</div> 
                                    <div className="font">{step}</div>
                                </li>
                            ))
                        }
                    </ul>
                ) : null
            }
        </div>
        <div className="icon-item">
            <FontAwesomeIcon onClick={() => toggleContent()} className="icon" icon={state ? faMinus : faPlus} />
        </div>
    </div>
  )
}




