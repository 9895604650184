import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { app_image } from '../../File'
import { useEffect } from 'react'
import AOS from 'aos'






const Reason = () => {
    const header = {
        title: 'How to Become an Izzy Agent',
        text: 'Step by step on how to become Izzy agent',
    }

    const reasons = [
        {
            icon: 'icon-business.png',
            title: 'No initial or upfront costs',
            text: 'Starting a POS business with machines requires you to pay for the machine and logistics to get it to you. With Payizzy, there are no upfront costs of setting up. All you need is to create an account on Payizzy, verify and get into business. Truly the easiest way to go!',
        },
        {
            icon: 'icon-moneys.png',
            title: 'Higher commission rates',
            text: 'Our commissions are lower than current POS rates. This means that you get to keep a higher percentage of your commissions. More commissions = more earnings for you',
        },
        {
            icon: 'icon-shield-security.png',
            title: 'Safe secure system and technology',
            text: 'Our QR codes and app are designed to give you maximum protection against scams, failed transactions and losses..',
        },
        {
            icon: 'icon-24-support.png',
            title: 'Izzy Help and Support ',
            text: 'A dedicated team is always on call to help you with any inquiries and questions you have and provide the assistance you need. ',
        },
    ]


    useEffect(() => {
        AOS.init({ duration: 2000 })

    }, [])

  return (
    <div className="reasons-contaniner">
       <TitleHeader header={header}/>
       <ReasonBody reasons={reasons}/>
    </div>
  )
}

export default Reason











const TitleHeader = ({header}) => {
    return (
        <div className="page-title-header text-center">
            <h3>{header.title}</h3>
            <p>{header.text}</p>
        </div>
    )
}




const ReasonBody = ({reasons}) => {
    return (
        <div className="content-card-container">
            <Row className="show-grid">
                {
                    reasons.map((content, index) => (<ContentItem key={index} index={index+1} content={content}/>))
                }
            </Row>
        </div>
    )
}



const ContentItem = ({content, index}) => {
    return (
        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
            <div data-aos={'zoom-out'} className={`content-card-items ${index % 2 === 0 ? 'right' : 'left'}`}>
                <ul>
                        <li className="icon">
                            <img src={app_image(content.icon)} alt={content.icon}/>
                        </li>
                        <li className="title">{content.title}</li>
                        <li className="text">{content.text}</li>
                </ul>
            </div>
        </Col>
    )
}
